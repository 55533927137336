import {
  type ICustomerProductDetail,
  type ILanguageType,
} from '@mahawi/eshop-common';
import { createAction } from '@reduxjs/toolkit';

import { SEARCH_ACTION } from './types';

export const search = createAction(
  SEARCH_ACTION.SEARCH_QUERY,
  (query: string, languageType: ILanguageType) => ({
    payload: {
      query,
      languageType,
    },
  }),
);

export const searchResponse = createAction(
  SEARCH_ACTION.SEARCH_QUERY_RESPONSE,
  (query: string, products: ICustomerProductDetail[]) => ({
    payload: {
      query,
      products,
    },
  }),
);
