import { type ICountry } from '@mahawi/eshop-common';
import { createAction } from '@reduxjs/toolkit';

import { COUNTRIES_ACTION } from './types';

export const countriesLoadAll = createAction(COUNTRIES_ACTION.LOAD_ALL);

export const countriesLoadAllResponse = createAction(
  COUNTRIES_ACTION.LOAD_ALL_RESPONSE,
  (countries: ICountry[]) => ({
    payload: { countries },
  }),
);
