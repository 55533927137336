import { green, red, yellow } from '@ant-design/colors';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { type RootState } from 'admin/react/reducers';
import { Button, Col, Collapse, Form, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { categoriesLoadAll } from '../../reducers/categories/actions';
import { saveCategories } from '../../reducers/product/actions';
import { type IProductState } from '../../reducers/product/types';
import SelectCategoryOptions from '../input/select-category-options';

interface IForm {
  categoryUUIDs: string[];
}

const { Panel } = Collapse;

const onFinish = (
  { categoryUUIDs }: IForm,
  Product: IProductState,
  dispatch: Dispatch,
): void => {
  dispatch(saveCategories(Product.uuid, categoryUUIDs));
};

function CategoriesComponent({
  Product,
  dispatch,
}: {
  Product: IProductState;
  dispatch: Dispatch;
}): JSX.Element {
  const [form] = Form.useForm<IForm>();

  const [statusIcon, setStatusIcon] = useState<JSX.Element>(
    <CloseCircleOutlined style={{ color: yellow.primary }} />,
  );

  useEffect(() => {
    form.setFieldsValue({
      categoryUUIDs: Product.categories.uuids,
    });

    const statusIconUE = Product.categories.uuids.length ? (
      <CheckCircleOutlined style={{ color: green.primary }} />
    ) : (
      <CloseCircleOutlined style={{ color: red.primary }} />
    );

    setStatusIcon(statusIconUE);
  }, [form, Product.categories.uuids]);

  return (
    <Collapse
      onChange={(keys) => {
        if (keys.includes('categories')) {
          dispatch(categoriesLoadAll());
        }
      }}
    >
      <Panel
        header={
          <Space size={8} align="start" direction="horizontal">
            <strong>Categories ({Product.categories.uuids.length})</strong>
            {statusIcon}
          </Space>
        }
        key="categories"
      >
        <Form
          form={form}
          onFinish={(values) => onFinish(values, Product, dispatch)}
          layout="vertical"
          initialValues={{
            categoryUUIDs: [],
          }}
        >
          <Row>
            <Col span={8}>
              <Form.Item
                name="categoryUUIDs"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <SelectCategoryOptions
                  form={form}
                  fieldName="categoryUUIDs"
                  isDisabled={Product.inProcess}
                  mode="multiple"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={Product.inProcess}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ Product }: RootState) => ({
  Product,
});

export default connect(mapStateToProps)(CategoriesComponent);
