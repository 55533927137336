import { type ITag } from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum TAGS_ACTION {
  ADD = 'TAGS_ADD',
  ADD_RESPONSE = 'TAGS_ADD_RESPONSE',

  LOAD_ALL = 'TAGS_LOAD_ALL',
  LOAD_ALL_RESPONSE = 'TAGS_LOAD_ALL_RESPONSE',
}

export interface IAdd extends UnknownAction {
  type: TAGS_ACTION.ADD;
  payload: {
    name: string;
    color: string;
  };
}

export interface IAddResponse extends UnknownAction {
  type: TAGS_ACTION.ADD_RESPONSE;
  payload: {
    tag: ITag | null;
  };
}

export interface IAddCallback {
  (arg0: IAddResponse): void;
}

export interface ILoadAll extends UnknownAction {
  type: TAGS_ACTION.LOAD_ALL;
}

export interface ILoadAllResponse extends UnknownAction {
  type: TAGS_ACTION.LOAD_ALL_RESPONSE;
  payload: {
    tags: ITag[];
  };
}

export interface ILoadAllCallback {
  (arg0: ILoadAllResponse): void;
}

export interface ITagsState {
  tags?: ITag[] | null;
  inProcess: boolean;
  updatedAt?: number;
}
