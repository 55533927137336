import {
  configureStore,
  type EnhancedStore,
  type StoreEnhancer,
  type ThunkDispatch,
  type ThunkMiddleware,
  type Tuple,
  type UnknownAction,
} from '@reduxjs/toolkit';
import { App } from 'antd';
import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import createSagaMiddleware, { type SagaMiddleware } from 'redux-saga';

import LayoutContainer from './containers/layout-container';
import Notification from './notification';
import rootReducer, { type RootState } from './reducers';
import { getSocket, mySaga } from './saga';
import Socket from './socket';

const sagaMiddleware: SagaMiddleware<object> = createSagaMiddleware();

const preloadedState: string | undefined = window.__PRELOADED_STATE__;
delete window.__PRELOADED_STATE__;

const store: EnhancedStore<
  RootState,
  UnknownAction,
  Tuple<
    [
      StoreEnhancer<{
        dispatch: ThunkDispatch<RootState, undefined, UnknownAction>;
      }>,
      StoreEnhancer,
    ]
  >
> = configureStore({
  reducer: rootReducer,
  preloadedState,
  middleware: (
    getDefaultMiddleware,
  ): Tuple<
    [ThunkMiddleware<RootState, UnknownAction>, SagaMiddleware<object>]
  > => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(mySaga);

const socket = new Socket(getSocket(), store);
socket.requests();
socket.subscribe();

const element: HTMLElement | null = document.getElementById('admin');

if (element) {
  hydrateRoot(
    element,
    <BrowserRouter>
      <Provider store={store}>
        <App>
          <Notification socket={socket}>
            <LayoutContainer />
          </Notification>
        </App>
      </Provider>
    </BrowserRouter>,
  );
}
