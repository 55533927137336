import { createReducer } from '@reduxjs/toolkit';

import { login, loginResponse, logout } from './actions';
import { type IUserState } from './types';

export const preloadedState: IUserState = {
  isLoggedIn: false,
  inProcess: false,
  email: undefined,
  uuid: undefined,
  updatedAt: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(login, (state) => {
    state.inProcess = true;
    state.isLoggedIn = false;
  });

  builder.addCase(loginResponse, (state, action) => {
    state.email = action.payload.email;
    state.uuid = action.payload.uuid;
    state.inProcess = false;
    state.isLoggedIn = action.payload.uuid !== undefined;
    state.updatedAt = Date.now();
  });

  builder.addCase(logout, (state) => {
    state.email = undefined;
    state.uuid = undefined;
    state.inProcess = false;
    state.isLoggedIn = false;
    state.updatedAt = Date.now();
  });
});
