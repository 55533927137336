import { DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { type IDiscountType } from '@mahawi/eshop-common/dist/src/types';
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  List,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import Link from '../components/link';
import { type RootState } from '../reducers';
import {
  discountDelete,
  discountSave,
  discountsLoad,
  discountTypesLoad,
} from '../reducers/discount/actions';
import { type IDiscountState } from '../reducers/discount/types';

const { Title } = Typography;
const { Option } = Select;

const onFinish = (
  { discountType, value, description, validTo },
  dispatch: Dispatch,
) =>
  dispatch(
    discountSave(
      discountType,
      value,
      description,
      validTo.format('YYYY-MM-DD'),
    ),
  );

const onChangeSelect = (discountType: string, form: FormInstance): void =>
  form.setFieldsValue({ discountType: { uuid: discountType } });

const getDiscountType = (
  discountTypeUUID: string,
  discountTypes: IDiscountType[],
): string => {
  const discount = discountTypes.find((dt) => dt.uuid === discountTypeUUID);

  return discount?.code || '---';
};

function DiscountContainer({
  dispatch,
  Discount,
}: {
  dispatch: Dispatch;
  Discount: IDiscountState;
}): JSX.Element {
  const [form] = Form.useForm();

  return (
    <Space size={16} direction="vertical" style={{ width: '100%' }}>
      <Typography>
        <Title level={2}>Add discount</Title>
      </Typography>

      <Space size={16} align="center" split={<Divider type="vertical" />}>
        <Button
          type={
            !Discount.discountTypes || !Discount.discounts
              ? 'primary'
              : 'default'
          }
          onClick={(): void => {
            dispatch(discountTypesLoad());
            dispatch(discountsLoad());
          }}
          disabled={Discount.inProcess}
        >
          Reload all <ReloadOutlined />
        </Button>

        <Typography>
          {Discount.updatedAt &&
            `Updated at ${dayjs(Discount.updatedAt).fromNow()}`}
        </Typography>
      </Space>

      <Form
        form={form}
        onFinish={(values) => onFinish(values, dispatch)}
        layout="inline"
        initialValues={{
          value: 3,
          validTo: dayjs().add(2, 'months'),
        }}
      >
        <Form.Item
          name={['discountType', 'uuid']}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Select type of discount"
            onChange={(value) => onChangeSelect(value, form)}
            disabled={Discount.inProcess}
          >
            {Discount.discountTypes?.map((discountType) => (
              <Option value={discountType.uuid} key={discountType.uuid}>
                {discountType.code}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="value"
          rules={[
            {
              required: true,
            },
            {
              type: 'number',
            },
          ]}
        >
          <InputNumber placeholder="Value" disabled={Discount.inProcess} />
        </Form.Item>

        <Form.Item
          name="description"
          rules={[
            {
              required: true,
            },
            {
              type: 'string',
              min: 3,
            },
          ]}
        >
          <Input placeholder="Description" disabled={Discount.inProcess} />
        </Form.Item>

        <Form.Item
          name="validTo"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <DatePicker disabled={Discount.inProcess} />
        </Form.Item>

        <Button
          type="primary"
          shape="round"
          htmlType="submit"
          disabled={Discount.inProcess}
        >
          Create
        </Button>
      </Form>

      <List
        itemLayout="vertical"
        size="small"
        header={
          <Typography>
            <Title level={2}>Existing discounts</Title>
          </Typography>
        }
        dataSource={Discount.discounts}
        renderItem={(discount) => (
          <List.Item
            key={discount.uuid}
            actions={[
              discount.order.uuid ? null : (
                <Button onClick={() => dispatch(discountDelete(discount.uuid))}>
                  <DeleteOutlined />
                </Button>
              ),
            ]}
            title={discount.description}
          >
            <List.Item.Meta
              title={discount.description}
              description={
                <>
                  <Row>
                    <Col span={12}>
                      {`Valid to ${dayjs(discount.validTo).format(
                        'DD.MM.YYYY',
                      )}`}
                    </Col>
                    <Col span={12}>
                      {Discount.discountTypes
                        ? `Discount type: ${getDiscountType(
                            discount.discountType.uuid,
                            Discount.discountTypes,
                          )}`
                        : 'Discount type: ---'}
                    </Col>
                  </Row>
                  <Row>
                    {discount.order.uuid ? (
                      <Col span={12}>
                        <Space size={16} align="start">
                          Order UUID
                          <Link
                            to={`/order/${discount.order.uuid}`}
                            label={discount.order.uuid}
                          />
                        </Space>
                      </Col>
                    ) : (
                      <Col span={12}>Order UUID: none</Col>
                    )}
                    <Col span={12}>{`Discount UUID: ${discount.uuid}`}</Col>
                    <Col span={12}>{`Value: ${discount.value}`}</Col>
                  </Row>
                </>
              }
            />
            Code: {discount.code}
          </List.Item>
        )}
      />
    </Space>
  );
}

const mapStateToProps = ({ Discount }: RootState) => ({
  Discount,
});

export default connect(mapStateToProps)(DiscountContainer);
