import { createAction } from '@reduxjs/toolkit';

import { DISCOUNT_ACTION } from './types';

export const discountTypesLoad = createAction(DISCOUNT_ACTION.LOAD_TYPES);

export const discountTypesLoadResponse = createAction(
  DISCOUNT_ACTION.LOAD_TYPES_RESPONSE,
  (discountTypes) => ({
    payload: { discountTypes },
  }),
);

export const discountsLoad = createAction(DISCOUNT_ACTION.LOAD_ALL);

export const discountsLoadResponse = createAction(
  DISCOUNT_ACTION.LOAD_ALL_RESPONSE,
  (discounts) => ({
    payload: { discounts },
  }),
);

export const discountSave = createAction(
  DISCOUNT_ACTION.SAVE,
  (discountType, value, description, validTo) => ({
    payload: { discountType, value, description, validTo },
  }),
);

export const discountDelete = createAction(DISCOUNT_ACTION.DELETE, (uuid) => ({
  payload: { uuid },
}));
