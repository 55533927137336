import Cookies from 'js-cookie';
import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { Socket } from 'socket.io-client';

import { sendRaw } from '../send-request';
import {
  type ILogin,
  type ILoginResponse,
  type ILogout,
  type ILogoutResponse,
} from './types';

export default class User {
  static *login(
    socket: Socket,
    action: ILogin,
  ): Generator<CallEffect | PutEffect, void, ILoginResponse> {
    const response: ILoginResponse = yield call(sendRaw, socket, action);

    if (response.payload.token) {
      Cookies.set('ropere_token', response.payload.token, { expires: 30 });

      window.location.reload();
    }

    yield put(response);
  }

  static *logout(
    socket: Socket,
    action: ILogout,
  ): Generator<CallEffect | PutEffect, void, ILogoutResponse> {
    Cookies.remove('ropere_token');

    window.location.reload();

    yield call(sendRaw, socket, action);
  }
}
