import {
  type IProductProperty,
  type IPropertyType,
} from '@mahawi/eshop-common';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum PROPERTIES_ACTION {
  ADD = 'PROPERTIES_ACTION_ADD',
  ADD_RESPONSE = 'PROPERTIES_ACTION_ADD_RESPONSE',
  LOAD = 'PROPERTIES_ACTION_LOAD',
  LOAD_RESPONSE = 'PROPERTIES_ACTION_LOAD_RESPONSE',
  LOAD_ALL_TYPES = 'PROPERTY_ACTION_LOAD_ALL_TYPES',
  LOAD_ALL_TYPES_RESPONSE = 'PROPERTY_ACTION_LOAD_ALL_TYPES_RESPONSE',
  LOAD_PRODUCT_PROPERTIES = 'PROPERTY_ACTION_LOAD_PRODUCT_PROPERTIES',
  LOAD_PRODUCT_PROPERTIES_RESPONSE = 'PROPERTY_ACTION_LOAD_PRODUCT_PROPERTIES_RESPONSE',
}

export interface IAdd extends UnknownAction {
  type: PROPERTIES_ACTION.ADD;
  payload: {
    code: string;
  };
}

export interface IAddResponse extends UnknownAction {
  type: PROPERTIES_ACTION.ADD_RESPONSE;
  payload: {
    property: {
      code: string;
    };
  };
}

export interface IAddCallback {
  (arg0: IAddResponse): void;
}

export interface ILoad extends UnknownAction {
  type: PROPERTIES_ACTION.LOAD;
}

export interface ILoadResponse extends UnknownAction {
  type: PROPERTIES_ACTION.LOAD_RESPONSE;
  payload: {
    property: {
      codes: string[];
    };
  };
}

export interface ILoadCallback {
  (arg0: ILoadResponse): void;
}

export interface ILoadAllTypes extends UnknownAction {
  type: PROPERTIES_ACTION.LOAD_ALL_TYPES;
}

export interface ILoadAllTypesResponse extends UnknownAction {
  type: PROPERTIES_ACTION.LOAD_ALL_TYPES_RESPONSE;
  payload: {
    types: IPropertyType[];
  };
}

export interface ILoadAllTypesCallback {
  (arg0: ILoadAllTypesResponse): void;
}

export interface ILoadProductProperties extends UnknownAction {
  type: PROPERTIES_ACTION.LOAD_PRODUCT_PROPERTIES;
  payload: {
    product: {
      uuid: string;
    };
  };
}

export interface ILoadProductPropertiesResponse extends UnknownAction {
  type: PROPERTIES_ACTION.LOAD_PRODUCT_PROPERTIES_RESPONSE;
  payload: {
    properties: IProductProperty[];
  };
}

export interface ILoadProductPropertiesCallback {
  (arg0: ILoadProductPropertiesResponse): void;
}

export interface IPropertiesState {
  inProcess: boolean;
  codes: string[];
  types: IPropertyType[];
  product: {
    properties?: IProductProperty[];
  };
  property: {
    code?: string;
  };
}
