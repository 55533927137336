import { type ISession } from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum DASHBOARD_ACTION {
  LOAD_SESSIONS = 'DASHBOARD_LOAD_SESSIONS',
  LOAD_SESSIONS_RESPONSE = 'DASHBOARD_LOAD_SESSIONS_RESPONSE',
}

export interface ILoadSessions extends UnknownAction {
  type: DASHBOARD_ACTION.LOAD_SESSIONS;
}

export interface ILoadSessionsResponse extends UnknownAction {
  type: DASHBOARD_ACTION.LOAD_SESSIONS_RESPONSE;
  payload: {
    sessions: ISession[];
  };
}

export interface ILoadSessionsCallback {
  (arg0: ILoadSessionsResponse): void;
}

export interface IDashboardState {
  inProcess: boolean;
  sessions?: ISession[];
  updatedAt?: number;
}
