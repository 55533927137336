import { ReloadOutlined } from '@ant-design/icons';
import { Button, Divider, Space, Tooltip, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import WarehousePropertiesTable from '../components/downloader/warehouse-properties-table';
import { type RootState } from '../reducers';
import { downloaderLoadWarehouseProperties } from '../reducers/downloader/actions';
import { type IDownloaderState } from '../reducers/downloader/types';

function DownloaderWarehouseProducts({
  dispatch,
  Downloader,
}: {
  dispatch: Dispatch;
  Downloader: IDownloaderState;
}): JSX.Element {
  let pageHeaderText: string = 'Downloader warehouse properties';

  if (Downloader.warehouse.properties?.length === 0) {
    pageHeaderText = `Downloader warehouse properties (${Downloader.warehouse.properties.length} items)`;
  }

  return (
    <>
      <Typography.Title level={2}>{pageHeaderText}</Typography.Title>

      <Divider />

      <Space size={16} align="start">
        <Tooltip title="Reload all properties assigned to property">
          <Button
            color="primary"
            onClick={() => {
              dispatch(downloaderLoadWarehouseProperties(true));
            }}
            disabled={Downloader.inProcess}
          >
            All assigned to property <ReloadOutlined />
          </Button>
        </Tooltip>

        <Tooltip title="Reload all properties not assigned to property">
          <Button
            color="primary"
            onClick={() => {
              dispatch(downloaderLoadWarehouseProperties(false));
            }}
            disabled={Downloader.inProcess}
          >
            All not assigned to property <ReloadOutlined />
          </Button>
        </Tooltip>
      </Space>

      <Divider />

      <WarehousePropertiesTable />
    </>
  );
}

const mapStateToProps = ({
  Downloader,
}: RootState): { Downloader: IDownloaderState } => ({ Downloader });

export default connect(mapStateToProps)(DownloaderWarehouseProducts);
