import { createReducer } from '@reduxjs/toolkit';

import { countriesLoadAll, countriesLoadAllResponse } from './actions';
import { type ICountriesState } from './types';

export const preloadedState: ICountriesState = {
  inProcess: false,
  countries: undefined,
  updatedAt: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(countriesLoadAll, (state) => {
    state.inProcess = true;
    state.countries = undefined;
    state.updatedAt = undefined;
  });

  builder.addCase(countriesLoadAllResponse, (state, action) => {
    state.inProcess = false;
    state.countries = action.payload.countries;
    state.updatedAt = Date.now();
  });
});
