import {
  type IComparisonPortal,
  type IComparisonPortalCategory,
} from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum COMPARISON_PORTAL_ACTION {
  ASSIGN_COMPARISON_PORTAL_CATEGORY = 'COMPARISON_PORTAL_ASSIGN_COMPARISON_PORTAL_CATEGORY',
  ASSIGN_COMPARISON_PORTAL_CATEGORY_RESPONSE = 'COMPARISON_PORTAL_ASSIGN_COMPARISON_PORTAL_CATEGORY_RESPONSE',
  LOAD_CATEGORIES_ALL = 'COMPARISON_PORTAL_LOAD_CATEGORIES_ALL',
  LOAD_CATEGORIES_ALL_RESPONSE = 'COMPARISON_PORTAL_LOAD_CATEGORIES_ALL_RESPONSE',
  LOAD_COMPARISON_PORTALS_ALL = 'COMPARISON_PORTAL_LOAD_COMPARISON_PORTALS_ALL',
  LOAD_COMPARISON_PORTALS_ALL_RESPONSE = 'COMPARISON_PORTAL_LOAD_COMPARISON_PORTALS_ALL_RESPONSE',
}

export interface IAssignComparisonPortalCategory extends UnknownAction {
  type: COMPARISON_PORTAL_ACTION.ASSIGN_COMPARISON_PORTAL_CATEGORY;
  payload: {
    categoryUUID: string;
    comparisonPortalCategoryUUID: string;
  };
}

export interface IAssignComparisonPortalCategoryResponse extends UnknownAction {
  type: COMPARISON_PORTAL_ACTION.ASSIGN_COMPARISON_PORTAL_CATEGORY_RESPONSE;
  payload: {
    saved: boolean;
  };
}

export interface IAssignComparisonPortalCategoryCallback {
  (arg0: IAssignComparisonPortalCategoryResponse): void;
}

export interface ILoadComparisonPortalCategoriesAll extends UnknownAction {
  type: COMPARISON_PORTAL_ACTION.LOAD_CATEGORIES_ALL;
}

export interface ILoadComparisonPortalCategoriesAllResponse
  extends UnknownAction {
  type: COMPARISON_PORTAL_ACTION.LOAD_CATEGORIES_ALL_RESPONSE;
  payload: {
    categories: IComparisonPortalCategory[];
  };
}

export interface ILoadComparisonPortalCategoriesAllCallback {
  (arg0: ILoadComparisonPortalCategoriesAllResponse): void;
}

export interface ILoadComparisonPortalAll extends UnknownAction {
  type: COMPARISON_PORTAL_ACTION.LOAD_COMPARISON_PORTALS_ALL;
}

export interface ILoadComparisonPortalAllResponse extends UnknownAction {
  type: COMPARISON_PORTAL_ACTION.LOAD_COMPARISON_PORTALS_ALL_RESPONSE;
  payload: {
    portals: IComparisonPortal[];
  };
}

export interface ILoadComparisonPortalAllCallback {
  (arg0: ILoadComparisonPortalAllResponse): void;
}

export interface IComparisonPortalState {
  inProcess: boolean;
  portals?: IComparisonPortal[];
  categories?: IComparisonPortalCategory[];
}
