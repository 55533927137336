import {
  EOrderStatus,
  EReviewStatusType,
  type IGetOrderResponse,
} from '@mahawi/eshop-common';
import { createAction } from '@reduxjs/toolkit';

import { ORDER_ACTION } from './types';

export const orderLoad = createAction(
  ORDER_ACTION.LOAD,
  (orderUUID: string) => ({
    payload: {
      order: {
        uuid: orderUUID,
      },
    },
  }),
);

export const orderLoadResponse = createAction(
  ORDER_ACTION.LOAD_RESPONSE,
  (order: IGetOrderResponse | null) => ({
    payload: {
      order,
    },
  }),
);

export const orderSetOrderStatusCode = createAction(
  ORDER_ACTION.SET_ORDER_STATUS_CODE,
  (orderUUID: string, statusCode: EOrderStatus) => ({
    payload: {
      order: {
        uuid: orderUUID,
        orderStatusCode: statusCode,
      },
    },
  }),
);

export const reviewSetStatusCode = createAction(
  ORDER_ACTION.SET_REVIEW_STATUS_CODE,
  (reviewUUID: string, statusCode: EReviewStatusType) => ({
    payload: {
      review: {
        uuid: reviewUUID,
        reviewStatusCode: statusCode,
      },
    },
  }),
);

export const reviewSentToCustomer = createAction(
  ORDER_ACTION.SENT_REVIEW_TO_CUSTOMER,
  (orderUUID: string) => ({
    payload: {
      order: {
        uuid: orderUUID,
      },
    },
  }),
);

export const saveOrderTags = createAction(
  ORDER_ACTION.SAVE_ORDER_TAGS,
  (orderUUID: string, tags: string[]) => ({
    payload: {
      order: {
        uuid: orderUUID,
      },
      tags,
    },
  }),
);
