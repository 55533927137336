import { Col } from 'antd';
import React, { type ReactNode } from 'react';

type BubbleComponentProps = {
  children: ReactNode;
  span?: number;
};

function BubbleComponent({
  children,
  span = undefined,
}: BubbleComponentProps): JSX.Element {
  return (
    <Col span={span}>
      <div
        style={{
          border: '1px solid #ccc',
          padding: '.5rem',
          backgroundColor: '#fafafa',
          borderRadius: '6px',
        }}
      >
        {children}
      </div>
    </Col>
  );
}

export default BubbleComponent;
