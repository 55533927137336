import { createReducer } from '@reduxjs/toolkit';

import {
  countryLoad,
  countryLoadResponse,
  countrySaveDeliveryDescription,
  countrySaveDeliveryTime,
  countrySaveDescription,
} from './actions';
import { type ICountryState } from './types';

export const preloadedState: ICountryState = {
  inProcess: false,
  country: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(countryLoad, (state) => {
    state.inProcess = true;
    state.country = undefined;
  });

  builder.addCase(countryLoadResponse, (state, action) => {
    state.inProcess = false;
    state.country = action.payload.country;
  });

  builder.addCase(countrySaveDeliveryDescription, (state) => {
    state.inProcess = true;
  });

  builder.addCase(countrySaveDeliveryTime, (state) => {
    state.inProcess = false;
  });

  builder.addCase(countrySaveDescription, (state) => {
    state.inProcess = false;
  });
});
