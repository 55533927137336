import { type ITag } from '@mahawi/eshop-common/dist/src/types';
import { createAction } from '@reduxjs/toolkit';

import { TAGS_ACTION } from './types';

export const tagsAdd = createAction(
  TAGS_ACTION.ADD,
  (name: string, color: string) => ({
    payload: {
      name,
      color,
    },
  }),
);

export const tagsAddResponse = createAction(
  TAGS_ACTION.ADD_RESPONSE,
  (tag: ITag | null) => ({
    payload: {
      tag,
    },
  }),
);

export const tagsLoadAll = createAction(TAGS_ACTION.LOAD_ALL);

export const tagsLoadAllResponse = createAction(
  TAGS_ACTION.LOAD_ALL_RESPONSE,
  (tags: ITag[]) => ({
    payload: {
      tags,
    },
  }),
);
