import { type ILanguageType } from '@mahawi/eshop-common/dist/src/types';
import { createAction } from '@reduxjs/toolkit';

import { LANGUAGE_ACTION } from './types';

export const setLanguage = createAction(
  LANGUAGE_ACTION.SET,
  (languageType: ILanguageType) => ({
    payload: {
      languageType,
    },
  }),
);

export const nextLanguage = createAction(LANGUAGE_ACTION.NEXT);

export const previousLanguage = createAction(LANGUAGE_ACTION.PREVIOUS);
