import {
  EPropertyType,
  type IProperty,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum PROPERTY_ACTION {
  LOAD = 'PROPERTY_ACTION_LOAD',
  LOAD_RESPONSE = 'PROPERTY_ACTION_LOAD_RESPONSE',
  NAME_LIVE = 'PROPERTY_ACTION_NAME_LIVE',
  PROPERTY_VALUE_NAME_LIVE = 'PROPERTY_ACTION_PROPERTY_VALUE_NAME_LIVE',
  SAVE_CATEGORIES_UUIDS = 'PROPERTY_ACTION_SAVE_CATEGORIES_UUIDS',
  SAVE_CATEGORIES_UUIDS_RESPONSE = 'PROPERTY_ACTION_SAVE_CATEGORIES_UUIDS_RESPONSE',
  SAVE_WAREHOUSE_PROPERTIES = 'PROPERTY_ACTION_SAVE_WAREHOUSE_PROPERTIES',
  UPDATE_NAME = 'PROPERTY_ACTION_UPDATE_NAME',
  UPDATE_NAME_RESPONSE = 'PROPERTY_ACTION_UPDATE_NAME_RESPONSE',
  UPDATE_NAMES = 'PROPERTY_ACTION_UPDATE_NAMES',
  UPDATE_NAMES_RESPONSE = 'PROPERTY_ACTION_UPDATE_NAMES_RESPONSE',
  UPDATE_TYPE = 'PROPERTY_ACTION_UPDATE_TYPE',
  UPDATE_TYPE_RESPONSE = 'PROPERTY_ACTION_UPDATE_TYPE_RESPONSE',
  UPDATE_UNIT = 'PROPERTY_ACTION_UPDATE_UNIT',
  UPDATE_UNIT_RESPONSE = 'PROPERTY_ACTION_UPDATE_UNIT_RESPONSE',
  UPDATE_VALUE_NAME = 'PROPERTY_ACTION_UPDATE_VALUE_NAME',
  UPDATE_VALUE_NAME_RESPONSE = 'PROPERTY_ACTION_UPDATE_VALUE_NAME_RESPONSE',
  UPDATE_VALUE_NAMES = 'PROPERTY_ACTION_UPDATE_VALUE_NAMES',
  UPDATE_VALUE_NAMES_RESPONSE = 'PROPERTY_ACTION_UPDATE_VALUE_NAMES_RESPONSE',
}

export interface ILoad extends UnknownAction {
  type: PROPERTY_ACTION.LOAD;
  payload: {
    property: {
      code: string;
    };
  };
}

export interface ILoadResponse extends UnknownAction {
  type: PROPERTY_ACTION.LOAD_RESPONSE;
  payload: {
    property: IProperty | null;
  };
}

export interface ILoadCallback {
  (arg0: ILoadResponse): void;
}

export interface INameUpdate extends UnknownAction {
  type: PROPERTY_ACTION.UPDATE_NAME;
  payload: {
    property: {
      code: string;
      name: string;
    };
    languageType: {
      code: string;
    };
  };
}

export interface INameUpdateResponse extends UnknownAction {
  type: PROPERTY_ACTION.UPDATE_NAME_RESPONSE;
  payload: {
    property: IProperty | null;
  };
}

export interface INamesUpdate extends UnknownAction {
  type: PROPERTY_ACTION.UPDATE_NAMES;
  payload: {
    property: {
      code: string;
    };
    names: ITranslation[];
  };
}

export interface INamesUpdateResponse extends UnknownAction {
  type: PROPERTY_ACTION.UPDATE_NAMES_RESPONSE;
  payload: {
    updated: boolean;
  };
}

export interface INamesUpdateCallback {
  (arg0: INamesUpdateResponse): void;
}

export interface INameLive extends UnknownAction {
  type: PROPERTY_ACTION.NAME_LIVE;
  payload: {
    property: {
      code: string;
      name: string;
    };
    languageType: {
      code: string;
    };
  };
}

export interface IPropertyValueNameLive extends UnknownAction {
  type: PROPERTY_ACTION.PROPERTY_VALUE_NAME_LIVE;
  payload: {
    propertyValue: {
      code: string;
      name: string;
    };
    languageType: {
      code: string;
    };
  };
}

export interface ISaveCategoriesUUIDs extends UnknownAction {
  type: PROPERTY_ACTION.SAVE_CATEGORIES_UUIDS;
  payload: {
    property: {
      code: string;
    };
    categoriesUUIDs: string[];
  };
}

export interface ISaveCategoriesUUIDsResponse extends UnknownAction {
  type: PROPERTY_ACTION.SAVE_CATEGORIES_UUIDS_RESPONSE;
  payload: {
    property: IProperty | null;
  };
}

export interface ISaveCategoriesUUIDsCallback {
  (arg0: ISaveCategoriesUUIDsResponse): void;
}

export interface IUpdateUnit extends UnknownAction {
  type: PROPERTY_ACTION.UPDATE_UNIT;
  payload: {
    property: {
      code: string;
      unit: string;
    };
  };
}

export interface IUpdateUnitResponse extends UnknownAction {
  type: PROPERTY_ACTION.UPDATE_UNIT_RESPONSE;
  payload: {
    property: IProperty | null;
  };
}

export interface IUpdateType extends UnknownAction {
  type: PROPERTY_ACTION.UPDATE_TYPE;
  payload: {
    property: {
      code: string;
      type: EPropertyType;
    };
  };
}

export interface IUpdateTypeResponse extends UnknownAction {
  type: PROPERTY_ACTION.UPDATE_TYPE_RESPONSE;
  payload: {
    property: IProperty | null;
  };
}

export interface ISaveWarehouseProperties extends UnknownAction {
  type: PROPERTY_ACTION.SAVE_WAREHOUSE_PROPERTIES;
  payload: {
    property: {
      code: string;
    };
    warehouse: {
      properties: string[];
    };
  };
}

export interface IValueNameUpdate extends UnknownAction {
  type: PROPERTY_ACTION.UPDATE_VALUE_NAME;
  payload: {
    propertyValue: {
      code: string;
      name: string;
    };
    languageType: {
      code: string;
    };
  };
}

export interface IValueNameUpdateResponse extends UnknownAction {
  type: PROPERTY_ACTION.UPDATE_VALUE_NAME_RESPONSE;
  payload: {
    saved: boolean;
  };
}

export interface IValueNameUpdateCallback {
  (arg0: IValueNameUpdateResponse): void;
}

export interface IValueNamesUpdate extends UnknownAction {
  type: PROPERTY_ACTION.UPDATE_VALUE_NAMES;
  payload: {
    propertyValue: {
      code: string;
    };
    names: ITranslation[];
  };
}

export interface IValueNamesUpdateResponse extends UnknownAction {
  type: PROPERTY_ACTION.UPDATE_VALUE_NAMES_RESPONSE;
  payload: {
    updated: boolean;
  };
}

export interface IValueNamesUpdateCallback {
  (arg0: IValueNamesUpdateResponse): void;
}

export interface IPropertyState {
  inProcess: boolean;
  property?: IProperty | null;
}
