import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { type IAdminCategory } from '@mahawi/eshop-common';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import { type RootState } from 'admin/react/reducers';
import { type ICategoryState } from 'admin/react/reducers/category/types';
import {
  Button,
  Col,
  Collapse,
  Form,
  List,
  Modal,
  Row,
  Select,
  Skeleton,
} from 'antd';
import { type DefaultOptionType } from 'antd/es/select';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { categoriesLoadAll } from '../../reducers/categories/actions';
import { type ICategoriesState } from '../../reducers/categories/types';
import {
  categorySavechildCategories,
  categorySavePosition,
} from '../../reducers/category/actions';
import { type ILanguageState } from '../../reducers/language/types';
import Link from '../link';
import { type ISortableListItem } from '../sortable-list';
import { SortableList } from '../sortable-list';

const { Panel } = Collapse;
const { Option } = Select;

interface CategoryLocalized {
  uuid: string;
  name: string;
}

interface IForm {
  childCategoriesUUIDs: string[];
}

function ItemTemplate({ record }: { record: ISortableListItem }): JSX.Element {
  return <List.Item>{record.name}</List.Item>;
}

function ChildCategoriesComponent({
  dispatch,
  Categories,
  Language,
  Category,
}: {
  dispatch: Dispatch;
  Categories: ICategoriesState;
  Category: ICategoryState;
  Language: ILanguageState;
}): JSX.Element {
  const [form] = Form.useForm<IForm>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [categoriesLocalized, setCategoriesLocalized] = useState<
    CategoryLocalized[]
  >([]);
  const [header, setHeader] = useState<JSX.Element | null>(null);
  const [dataSourceSortableList, setDataSourceSortableList] = useState<
    ISortableListItem[]
  >([]);
  const [childCategories, setChildCategories] = useState<
    JSX.Element[] | undefined
  >(undefined);

  useEffect((): void => {
    if (!Categories.categories) {
      return;
    }

    const categoriesFiltered: IAdminCategory[] = Categories.categories
      .filter((category: IAdminCategory): boolean => {
        if (
          !Category.category ||
          Category.category.uuid === category.uuid ||
          Category.category.childCategoriesUUIDs.includes(category.uuid)
        ) {
          return false;
        }

        return true;
      })
      .sort(
        (a: IAdminCategory, b: IAdminCategory): number =>
          a.position - b.position,
      );

    const categoriesLocalizedUE = categoriesFiltered.map((category) => {
      const categoryNameLocalized: string = getTranslation(
        category.names,
        Language.languageType,
      );

      return {
        uuid: category.uuid,
        name: categoryNameLocalized,
      };
    });

    setCategoriesLocalized(categoriesLocalizedUE);
  }, [Categories, Category.category, Language.languageType]);

  useEffect((): void => {
    const childCategoriesLength: number =
      Category.category?.childCategoriesUUIDs.length || 0;
    const headerName = `Child categories (${childCategoriesLength})`;

    const headerUE: JSX.Element = (
      <>
        <strong>{headerName}</strong>{' '}
        {childCategoriesLength ? (
          <CheckCircleOutlined style={{ color: 'green' }} />
        ) : (
          <CloseCircleOutlined style={{ color: 'red' }} />
        )}
      </>
    );

    setHeader(headerUE);
    setChildCategories(undefined);
    setDataSourceSortableList([]);

    if (Category.category?.childCategoriesUUIDs.length) {
      const sc: IAdminCategory[] = Category.category.childCategoriesUUIDs
        .map((categoryUUID: string): IAdminCategory | undefined =>
          Categories.categories?.find(
            (c: IAdminCategory): boolean => c.uuid === categoryUUID,
          ),
        )
        .filter(notEmpty)
        .sort(
          (a: IAdminCategory, b: IAdminCategory): number =>
            a.position - b.position,
        );

      const childCategoriesUE: JSX.Element[] = sc
        .map((category: IAdminCategory): JSX.Element => {
          const categoryNameLocalized: string = getTranslation(
            category.names,
            Language.languageType,
          );

          return (
            <List.Item key={category.uuid}>
              <Link
                to={`/category/${category.uuid}`}
                label={categoryNameLocalized}
                openInNewTab
              />
            </List.Item>
          );
        })
        .filter(notEmpty);

      setChildCategories(childCategoriesUE);

      const dataSourceSortableListUE = sc.map((category: IAdminCategory) => {
        const categoryNameLocalized: string = getTranslation(
          category.names,
          Language.languageType,
        );

        return {
          id: category.uuid,
          uuid: category.uuid,
          name: categoryNameLocalized,
          position: category.position,
        };
      });

      setDataSourceSortableList(dataSourceSortableListUE);
    }
  }, [
    Category.category?.uuid,
    Category.category?.childCategoriesUUIDs,
    Categories.categories,
    Language.languageType,
    Category.updatedAt,
  ]);

  useEffect((): void => {
    form.resetFields();
  }, [form, Category.category?.uuid]);

  const onFinish = useCallback(
    ({ childCategoriesUUIDs }: IForm): void => {
      if (!Category.category) {
        return;
      }

      dispatch(
        categorySavechildCategories(
          Category.category.uuid,
          childCategoriesUUIDs,
        ),
      );
    },
    [Category, dispatch],
  );

  const showModal = (): void => {
    setIsModalOpen(true);
  };

  const handleOk = (): void => {
    setIsModalOpen(false);
  };

  const handleCancel = (): void => {
    setIsModalOpen(false);
  };

  return (
    <Collapse
      onChange={(keys: string | string[]): void => {
        if (keys.includes('child-categories')) {
          dispatch(categoriesLoadAll());
        }
      }}
    >
      <Panel header={header} key="child-categories">
        <Row>
          <Col span={8}>
            <Form form={form} onFinish={onFinish} layout="vertical">
              <Form.Item
                name="childCategoriesUUIDs"
                rules={[
                  {
                    required: true,
                    message: 'Please select child categories',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select child categories"
                  disabled={Category.inProcess}
                  autoClearSearchValue={false}
                  filterOption={(
                    input: string,
                    option: DefaultOptionType | undefined,
                  ): boolean => {
                    const childrenText: string = String(
                      option ? option.children : '',
                    ).toLowerCase();
                    return childrenText.includes(input.toLowerCase()) ?? false;
                  }}
                  filterSort={(
                    optionA: DefaultOptionType,
                    optionB: DefaultOptionType,
                  ): number => {
                    const childrenAText: string = String(
                      optionA ? optionA.children : '',
                    ).toLowerCase();

                    const childrenBText: string = String(
                      optionB ? optionB.children : '',
                    ).toLowerCase();

                    return childrenAText.localeCompare(childrenBText);
                  }}
                >
                  {categoriesLocalized.map(
                    (category: CategoryLocalized): JSX.Element => {
                      return (
                        <Option value={category.uuid} key={category.uuid}>
                          {category.name}
                        </Option>
                      );
                    },
                  )}
                </Select>
              </Form.Item>

              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form>
          </Col>

          <Col span={15} offset={1}>
            {childCategories ? (
              <List>
                {childCategories.map(
                  (childCategory: JSX.Element): JSX.Element => childCategory,
                )}
              </List>
            ) : (
              <Skeleton active />
            )}

            {dataSourceSortableList.length > 0 && (
              <>
                <Button type="primary" onClick={showModal}>
                  Change categories order
                </Button>

                <Modal
                  title="Change categories order"
                  open={isModalOpen}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <SortableList
                    records={dataSourceSortableList}
                    id="categories-sortable-list"
                    ItemTemplate={ItemTemplate}
                    saveCallback={(records: ISortableListItem[]): void => {
                      records.forEach((record: ISortableListItem): void => {
                        dispatch(
                          categorySavePosition(record.uuid, record.position),
                        );
                      });
                    }}
                  />
                </Modal>
              </>
            )}
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ Category, Categories, Language }: RootState) => ({
  Category,
  Categories,
  Language,
});

export default connect(mapStateToProps)(ChildCategoriesComponent);
