import { type FormInstance } from 'antd';
import { type AnyAction } from 'redux';

export type CallbackAction = (text: string) => AnyAction | void;

export const FormTextHandler = async (
  form: FormInstance,
  event: KeyboardEvent,
  cb: CallbackAction,
): Promise<void> => {
  if (!['ė', '∂'].includes(event.key)) {
    return;
  }

  if (event.key === '∂') {
    form.submit();

    return;
  }

  const text: string = await navigator.clipboard.readText();

  if (event.key === 'ė') {
    if (cb && text) {
      cb(text.trim());
    }
  }
};

export const AddFocusEventListener = (
  form: FormInstance,
  cb: CallbackAction,
): void => {
  window.addEventListener(
    'focus',
    async (): Promise<void> => {
      const text: string = await navigator.clipboard.readText();
      const tt: string = text.trim();

      if (cb && tt) {
        cb(tt);
      }

      form.submit();
    },
    { once: true },
  );
};
