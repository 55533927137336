import { blue, green, orange, purple, yellow } from '@ant-design/colors';
import { AlertOutlined } from '@ant-design/icons';
import { type RootState } from 'admin/react/reducers';
import { List, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { type CSSProperties, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { setActiveLead } from '../../reducers/messenger/actions';
import {
  type IMessengerState,
  type LeadMessenger,
} from '../../reducers/messenger/types';

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

const styleListItem: CSSProperties = {
  cursor: 'pointer',
  padding: '.75rem',
};

const { 1: blue1 } = blue;
const { 3: green3 } = green;
const { 3: orange3 } = orange;
const { 3: purple3 } = purple;
const { 3: yellow3 } = yellow;

function MessengerList({
  dispatch,
  Messenger,
}: {
  dispatch: Dispatch;
  Messenger: IMessengerState;
}): JSX.Element {
  const [leads, setLeads] = useState<LeadMessenger[]>([]);

  useEffect((): void => {
    if (!Messenger.leads) {
      setLeads([]);
      return;
    }

    const leadsUE: LeadMessenger[] = [];

    Messenger.leads.forEach((lead: LeadMessenger): void => {
      leadsUE.push(lead);
    });

    setLeads(leadsUE);
  }, [Messenger.leads, Messenger.updatedAt]);

  return (
    <List
      dataSource={leads}
      renderItem={(lead: LeadMessenger): JSX.Element => {
        const styleListItemActivity: CSSProperties = {
          backgroundColor: blue1, // inactive lead
        };

        if (Messenger.lead.uuid === lead.uuid) {
          // lead is selected
          styleListItemActivity.backgroundColor = green3;
        } else if (lead.unread && lead.unread > 0 && lead.isActive) {
          // lead is on page and has unread messages
          styleListItemActivity.backgroundColor = purple3;
        } else if (lead.unread) {
          // unread messages
          styleListItemActivity.backgroundColor = yellow3;
        } else if (lead.isActive) {
          // lead is on page
          styleListItemActivity.backgroundColor = orange3;
        }

        return (
          <List.Item
            onClick={(): void => {
              dispatch(setActiveLead(lead.uuid));
            }}
            style={{ ...styleListItem, ...styleListItemActivity }}
          >
            <List.Item.Meta
              title={lead.uuid}
              description={
                <Typography.Text type="secondary">
                  <Space size={16} align="start">
                    {lead.unread || 0}
                    {dayjs.unix(lead.lastActivity || 0).format(DATE_FORMAT)}
                    {lead.isActive ? <AlertOutlined /> : null}
                  </Space>
                </Typography.Text>
              }
            />
          </List.Item>
        );
      }}
    />
  );
}

const mapStateToProps = ({ Messenger }: RootState) => ({ Messenger });

export default connect(mapStateToProps)(MessengerList);
