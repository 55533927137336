import {
  type ICategoryEbay,
  type IEbayAspect,
  type IEbayFulfillment,
  type IEbayMarketplace,
  type IEbayPayment,
  type IEbayReturn,
  type IProductEbayDetail,
} from '@mahawi/eshop-common/dist/src/types';
import {
  type IEbayBotGetInventoryItemResponse,
  type IEbayBotGetOffersResponse,
  type IEbayBotPostOfferResponse,
  type IEbayBotPublishOfferResponse,
  type IEbayBotPutInventoryItemResponse,
  type IEbayBotPutOfferResponse,
  type IEbayCategoryNode,
} from '@mahawi/eshop-rabbit';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum EBAY_ACTION {
  CATEGORY_LOAD = 'EBAY_ACTION_CATEGORY_LOAD',
  CATEGORY_LOAD_ASPECTS = 'EBAY_ACTION_CATEGORY_LOAD_ASPECTS',
  CATEGORY_LOAD_ASPECTS_RESPONSE = 'EBAY_ACTION_CATEGORY_LOAD_ASPECTS_RESPONSE',
  CATEGORY_LOAD_RESPONSE = 'EBAY_ACTION_CATEGORY_LOAD_RESPONSE',
  CATEGORY_SAVE = 'EBAY_ACTION_CATEGORY_SAVE',
  CATEGORY_SAVE_ASPECTS = 'EBAY_ACTION_CATEGORY_SAVE_ASPECTS',
  CATEGORY_SAVE_ASPECTS_RESPONSE = 'EBAY_ACTION_CATEGORY_SAVE_ASPECTS_RESPONSE',
  CATEGORY_SUGGESTIONS = 'EBAY_ACTION_CATEGORY_SUGGESTIONS',
  CATEGORY_SUGGESTIONS_RESPONSE = 'EBAY_ACTION_CATEGORY_SUGGESTIONS_RESPONSE',
  INVENTORY_ITEM_DELETE = 'EBAY_ACTION_INVENTORY_ITEM_DELETE',
  INVENTORY_ITEM_DELETE_RESPONSE = 'EBAY_ACTION_INVENTORY_ITEM_DELETE_RESPONSE',
  INVENTORY_ITEM_LOAD = 'EBAY_ACTION_INVENTORY_ITEM_LOAD',
  INVENTORY_ITEM_LOAD_RESPONSE = 'EBAY_ACTION_INVENTORY_ITEM_LOAD_RESPONSE',
  INVENTORY_ITEM_PUT = 'EBAY_ACTION_INVENTORY_ITEM_PUT',
  INVENTORY_ITEM_PUT_RESPONSE = 'EBAY_ACTION_INVENTORY_ITEM_PUT_RESPONSE',
  INVENTORY_ITEMS_CLEAR_ALL_RESPONSES = 'EBAY_ACTION_INVENTORY_ITEMS_CLEAR_ALL_RESPONSES',
  MARKETPLACE_LOAD = 'EBAY_ACTION_MARKETPLACE_LOAD',
  MARKETPLACE_LOAD_FULFILLMENT_POLICIES = 'EBAY_ACTION_MARKETPLACE_LOAD_FULFILLMENT_POLICIES',
  MARKETPLACE_LOAD_FULFILLMENT_POLICIES_RESPONSE = 'EBAY_ACTION_MARKETPLACE_LOAD_FULFILLMENT_POLICIES_RESPONSE',
  MARKETPLACE_LOAD_PAYMENT_POLICIES = 'EBAY_ACTION_MARKETPLACE_LOAD_PAYMENT_POLICIES',
  MARKETPLACE_LOAD_PAYMENT_POLICIES_RESPONSE = 'EBAY_ACTION_MARKETPLACE_LOAD_PAYMENT_POLICIES_RESPONSE',
  MARKETPLACE_LOAD_PRODUCT = 'EBAY_ACTION_MARKETPLACE_LOAD_PRODUCT',
  MARKETPLACE_LOAD_RESPONSE = 'EBAY_ACTION_MARKETPLACE_LOAD_RESPONSE',
  MARKETPLACE_LOAD_RETURN_POLICIES = 'EBAY_ACTION_MARKETPLACE_LOAD_RETURN_POLICIES',
  MARKETPLACE_LOAD_RETURN_POLICIES_RESPONSE = 'EBAY_ACTION_MARKETPLACE_LOAD_RETURN_POLICIES_RESPONSE',
  MARKETPLACE_RELOAD_CATEGORIES = 'EBAY_ACTION_MARKETPLACE_RELOAD_CATEGORIES',
  MARKETPLACE_RELOAD_DEFAULT_CATEGORY_ID = 'EBAY_ACTION_MARKETPLACE_RELOAD_DEFAULT_CATEGORY_ID',
  MARKETPLACE_RELOAD_FULFILLMENT_POLICIES = 'EBAY_ACTION_MARKETPLACE_RELOAD_FULFILLMENT_POLICIES',
  MARKETPLACE_RELOAD_PAYMENT_POLICIES = 'EBAY_ACTION_MARKETPLACE_RELOAD_PAYMENT_POLICIES',
  MARKETPLACE_RELOAD_RETURN_POLICIES = 'EBAY_ACTION_MARKETPLACE_RELOAD_RETURN_POLICIES',
  MARKETPLACE_SET_CURRENCY = 'EBAY_ACTION_MARKETPLACE_SET_CURRENCY',
  MARKETPLACE_SET_CURRENCY_RESPONSE = 'EBAY_ACTION_MARKETPLACE_SET_CURRENCY_RESPONSE',
  MARKETPLACE_SET_IS_ACTIVE = 'EBAY_ACTION_MARKETPLACE_SET_IS_ACTIVE',
  MARKETPLACE_SET_IS_ACTIVE_RESPONSE = 'EBAY_ACTION_MARKETPLACE_SET_IS_ACTIVE_RESPONSE',
  MARKETPLACE_SET_LANGUAGE = 'EBAY_ACTION_MARKETPLACE_SET_LANGUAGE',
  MARKETPLACE_SET_LANGUAGE_RESPONSE = 'EBAY_ACTION_MARKETPLACE_SET_LANGUAGE_RESPONSE',
  MARKETPLACES_LOAD = 'EBAY_ACTION_MARKETPLACES_LOAD',
  MARKETPLACES_LOAD_RESPONSE = 'EBAY_ACTION_MARKETPLACES_LOAD_RESPONSE',
  OFFER_CLEAR_ALL_RESPONSES = 'EBAY_OFFER_ACTION_CLEAR_ALL_RESPONSES',
  OFFER_DELETE = 'EBAY_ACTION_OFFER_DELETE',
  OFFER_DELETE_RESPONSE = 'EBAY_ACTION_OFFER_DELETE_RESPONSE',
  OFFER_POST = 'EBAY_ACTION_OFFER_POST',
  OFFER_POST_RESPONSE = 'EBAY_ACTION_OFFER_POST_RESPONSE',
  OFFER_PUBLISH = 'EBAY_ACTION_OFFER_PUBLISH',
  OFFER_PUBLISH_RESPONSE = 'EBAY_ACTION_OFFER_PUBLISH_RESPONSE',
  OFFER_PUT = 'EBAY_ACTION_OFFER_PUT',
  OFFER_PUT_RESPONSE = 'EBAY_ACTION_OFFER_PUT_RESPONSE',
  OFFERS_LOAD = 'EBAY_ACTION_OFFERS_LOAD',
  OFFERS_LOAD_RESPONSE = 'EBAY_ACTION_OFFERS_LOAD_RESPONSE',
  PRODUCT_LOAD = 'EBAY_ACTION_PRODUCT_LOAD',
  PRODUCT_LOAD_RESPONSE = 'EBAY_ACTION_PRODUCT_LOAD_RESPONSE',
  PRODUCT_SAVE = 'EBAY_ACTION_PRODUCT_SAVE',
  PRODUCT_SAVE_RESPONSE = 'EBAY_ACTION_PRODUCT_SAVE_RESPONSE',
  USER_CODE = 'EBAY_ACTION_USER_CODE',
  USER_URL = 'EBAY_ACTION_USER_URL',
  USER_URL_RESPONSE = 'EBAY_ACTION_USER_URL_RESPONSE',
}

export type TEbayAspectsValues = Record<
  string,
  Record<string, string | undefined>
>;

export interface MarketplacesLoad extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACES_LOAD;
}

export interface MarketplacesLoadResponse extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACES_LOAD_RESPONSE;
  marketplaces: IEbayMarketplace[];
}

export interface MarketplacesLoadCallback {
  (arg0: MarketplacesLoadResponse): void;
}

export interface MarketplaceLoad extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_LOAD;
  ebayMarketplace: {
    code: string;
  };
}

export interface MarketplaceLoadResponse extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_LOAD_RESPONSE;
  marketplace: IEbayMarketplace | null;
}

export interface MarketplaceLoadCallback {
  (arg0: MarketplaceLoadResponse): void;
}

export interface MarketplaceLoadFulfillmentPolicies extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_LOAD_FULFILLMENT_POLICIES;
  ebayMarketplace: {
    code: string;
  };
}

export interface MarketplaceLoadFulfillmentPoliciesResponse
  extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_LOAD_FULFILLMENT_POLICIES_RESPONSE;
  ebayMarketplace: {
    code: string;
  };
  fulfillmentPolicies?: IEbayFulfillment[];
}

export interface MarketplaceLoadFulfillmentPoliciesCallback {
  (arg0: MarketplaceLoadFulfillmentPoliciesResponse): void;
}

export interface MarketplaceLoadPaymentPolicies extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_LOAD_PAYMENT_POLICIES;
  ebayMarketplace: {
    code: string;
  };
}

export interface MarketplaceLoadPaymentPoliciesResponse extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_LOAD_PAYMENT_POLICIES_RESPONSE;
  ebayMarketplace: {
    code: string;
  };
  paymentPolicies: IEbayPayment[];
}

export interface MarketplaceLoadPaymentPoliciesCallback {
  (arg0: MarketplaceLoadPaymentPoliciesResponse): void;
}

export interface MarketplaceLoadReturnPolicies extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_LOAD_RETURN_POLICIES;
  ebayMarketplace: {
    code: string;
  };
}

export interface MarketplaceLoadReturnPoliciesResponse extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_LOAD_RETURN_POLICIES_RESPONSE;
  ebayMarketplace: {
    code: string;
  };
  returnPolicies: IEbayReturn[];
}

export interface MarketplaceLoadReturnPoliciesCallback {
  (arg0: MarketplaceLoadReturnPoliciesResponse): void;
}

export interface MarketplaceSetIsActive extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_SET_IS_ACTIVE;
  ebayMarketplace: {
    code: string;
  };
  isActive: boolean;
}

export interface MarketplaceSetIsActiveResponse extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_SET_IS_ACTIVE_RESPONSE;
  marketplace: IEbayMarketplace | null;
}

export interface MarketplaceSetIsActiveCallback {
  (arg0: MarketplaceSetIsActiveResponse): void;
}

export interface MarketplaceReloadDefaultCategoryId extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_RELOAD_DEFAULT_CATEGORY_ID;
  ebayMarketplace: {
    code: string;
  };
}

export interface MarketplaceReloadFulfillmentPolicies extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_RELOAD_FULFILLMENT_POLICIES;
  ebayMarketplace: {
    code: string;
  };
}

export interface MarketplaceReloadPaymentPolicies extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_RELOAD_PAYMENT_POLICIES;
  ebayMarketplace: {
    code: string;
  };
}

export interface MarketplaceReloadReturnPolicies extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_RELOAD_RETURN_POLICIES;
  ebayMarketplace: {
    code: string;
  };
}

export interface MarketplaceReloadCategories extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_RELOAD_CATEGORIES;
  ebayMarketplace: {
    code: string;
  };
  categoryId: string;
}

export interface MarketplaceSetLanguage extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_SET_LANGUAGE;
  ebayMarketplace: {
    code: string;
  };
  languageType: {
    code: string;
  };
}

export interface MarketplaceSetLanguageResponse extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_SET_LANGUAGE_RESPONSE;
  marketplace: IEbayMarketplace | null;
}

export interface MarketplaceSetLanguageCallback {
  (arg0: MarketplaceSetLanguageResponse): void;
}

export interface MarketplaceSetCurrency extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_SET_CURRENCY;
  ebayMarketplace: {
    code: string;
  };
  currencyType: {
    isoCode: string;
  };
}

export interface MarketplaceSetCurrencyResponse extends UnknownAction {
  type: typeof EBAY_ACTION.MARKETPLACE_SET_CURRENCY_RESPONSE;
  marketplace: IEbayMarketplace | null;
}

export interface MarketplaceSetCurrencyCallback {
  (arg0: MarketplaceSetCurrencyResponse): void;
}

export interface CategorySuggestions extends UnknownAction {
  type: typeof EBAY_ACTION.CATEGORY_SUGGESTIONS;
  ebayMarketplace: {
    code: string;
  };
  query: string;
}

export interface CategorySuggestionsResponse extends UnknownAction {
  type: typeof EBAY_ACTION.CATEGORY_SUGGESTIONS_RESPONSE;
  ebayMarketplace: {
    code: string;
  };
  categorySuggestions?: IEbayCategoryNode[] | null;
}

export interface InventoryItemsClearAllResponses extends UnknownAction {
  type: typeof EBAY_ACTION.INVENTORY_ITEMS_CLEAR_ALL_RESPONSES;
}

export interface CategorySuggestionsCallback {
  (arg0: CategorySuggestionsResponse): void;
}

export interface CategoryLoad extends UnknownAction {
  type: typeof EBAY_ACTION.CATEGORY_LOAD;
  category: {
    uuid: string;
  };
  ebayMarketplace: {
    code: string;
  };
}

export interface CategoryLoadResponse extends UnknownAction {
  type: typeof EBAY_ACTION.CATEGORY_LOAD_RESPONSE;
  category: {
    uuid: string;
  };
  ebayMarketplace: {
    code: string;
  };
  categories?: ICategoryEbay[] | null;
}

export interface CategoryLoadCallback {
  (arg0: CategoryLoadResponse): void;
}

export interface CategorySave extends UnknownAction {
  type: typeof EBAY_ACTION.CATEGORY_SAVE;
  category: {
    uuid: string;
  };
  ebayMarketplace: {
    code: string;
  };
  categoryId: string;
}

export interface UserUrl extends UnknownAction {
  type: typeof EBAY_ACTION.USER_URL;
}

export interface UserUrlResponse extends UnknownAction {
  type: typeof EBAY_ACTION.USER_URL_RESPONSE;
  url: string;
}

export interface UserUrlCallback {
  (arg0: UserUrlResponse): void;
}

export interface UserCode extends UnknownAction {
  type: typeof EBAY_ACTION.USER_CODE;
  code: string;
}

export interface ProductSave extends UnknownAction {
  type: typeof EBAY_ACTION.PRODUCT_SAVE;
  product: {
    uuid: string;
  };
  ebayMarketplace: {
    code: string;
  };
  paymentPolicy: {
    id: string;
  };
  fulfillmentPolicy: {
    id: string;
  };
  returnPolicy: {
    id: string;
  };
  inStock: number;
  priceIncreaseRate: number;
}

export interface ProductSaveResponse extends UnknownAction {
  type: typeof EBAY_ACTION.PRODUCT_SAVE_RESPONSE;
  productEbay: IProductEbayDetail | null;
  ebayMarketplace: {
    code: string;
  };
}

export interface ProductSaveCallback {
  (arg0: ProductSaveResponse): void;
}

export interface OffersLoad extends UnknownAction {
  type: typeof EBAY_ACTION.OFFERS_LOAD;
  product: {
    mpn: string;
    uuid: string;
  };
  ebayMarketplace: {
    code: string;
  };
}

export interface OffersLoadResponse extends UnknownAction {
  type: typeof EBAY_ACTION.OFFERS_LOAD_RESPONSE;
  ebayMarketplace: {
    code: string;
  };
  response?: IEbayBotGetOffersResponse | null;
}

export interface OfferClearAllResponses extends UnknownAction {
  type: typeof EBAY_ACTION.OFFER_CLEAR_ALL_RESPONSES;
}

export interface OffersLoadCallback {
  (arg0: OffersLoadResponse): void;
}

export interface OfferDelete extends UnknownAction {
  type: typeof EBAY_ACTION.OFFER_DELETE;
  offerId: string;
  ebayMarketplace: {
    code: string;
  };
}

export interface OfferDeleteResponse extends UnknownAction {
  type: typeof EBAY_ACTION.OFFER_DELETE_RESPONSE;
  ebayMarketplace: {
    code: string;
  };
  offerId: string;
  response: boolean;
}

export interface OfferDeleteCallback {
  (arg0: OfferDeleteResponse): void;
}

export interface OfferPost extends UnknownAction {
  type: typeof EBAY_ACTION.OFFER_POST;
  product: {
    uuid: string;
  };
  ebayMarketplace: {
    code: string;
  };
}

export interface OfferPostResponse extends UnknownAction {
  type: typeof EBAY_ACTION.OFFER_POST_RESPONSE;
  ebayMarketplace: {
    code: string;
  };
  response: IEbayBotPostOfferResponse | null;
}

export interface OfferPostCallback {
  (arg0: OfferPostResponse): void;
}

export interface OfferPublish extends UnknownAction {
  type: typeof EBAY_ACTION.OFFER_PUBLISH;
  product: {
    mpn: string;
  };
  ebayMarketplace: {
    code: string;
  };
}

export interface OfferPublishResponse extends UnknownAction {
  type: typeof EBAY_ACTION.OFFER_PUBLISH_RESPONSE;
  ebayMarketplace: {
    code: string;
  };
  response: IEbayBotPublishOfferResponse | null;
}

export interface OfferPublishCallback {
  (arg0: OfferPublishResponse): void;
}

export interface OfferPut extends UnknownAction {
  type: typeof EBAY_ACTION.OFFER_PUT;
  product: {
    mpn: string;
  };
  ebayMarketplace: {
    code: string;
  };
}

export interface OfferPutResponse extends UnknownAction {
  type: typeof EBAY_ACTION.OFFER_PUT_RESPONSE;
  ebayMarketplace: {
    code: string;
  };
  response: IEbayBotPutOfferResponse | null;
}

export interface OfferPutCallback {
  (arg0: OfferPutResponse): void;
}

export interface ProductLoad extends UnknownAction {
  type: typeof EBAY_ACTION.PRODUCT_LOAD;
  product: {
    uuid: string;
  };
  ebayMarketplace: {
    code: string;
  };
}

export interface ProductLoadResponse extends UnknownAction {
  type: typeof EBAY_ACTION.PRODUCT_LOAD_RESPONSE;
  productEbay?: IProductEbayDetail;
  ebayMarketplace: {
    code: string;
  };
}

export interface ProductLoadCallback {
  (arg0: ProductLoadResponse): void;
}

export interface InventoryItemDelete extends UnknownAction {
  type: typeof EBAY_ACTION.INVENTORY_ITEM_DELETE;
  product: {
    mpn: string;
  };
  ebayMarketplace: {
    code: string;
  };
}

export interface InventoryItemDeleteResponse extends UnknownAction {
  type: typeof EBAY_ACTION.INVENTORY_ITEM_DELETE_RESPONSE;
  ebayMarketplace: {
    code: string;
  };
  product: {
    mpn: string;
  };
  response?: boolean | null;
}

export interface InventoryItemDeleteCallback {
  (arg0: InventoryItemDeleteResponse): void;
}

export interface InventoryItemLoad extends UnknownAction {
  type: typeof EBAY_ACTION.INVENTORY_ITEM_LOAD;
  product: {
    mpn: string;
  };
  ebayMarketplace: {
    code: string;
  };
}

export interface InventoryItemLoadResponse extends UnknownAction {
  type: typeof EBAY_ACTION.INVENTORY_ITEM_LOAD_RESPONSE;
  ebayMarketplace: {
    code: string;
  };
  response?: IEbayBotGetInventoryItemResponse;
}

export interface InventoryItemLoadCallback {
  (arg0: InventoryItemLoadResponse): void;
}

export interface InventoryItemPut extends UnknownAction {
  type: typeof EBAY_ACTION.INVENTORY_ITEM_PUT;
  product: {
    uuid: string;
  };
  ebayMarketplace: {
    code: string;
  };
}

export interface InventoryItemPutResponse extends UnknownAction {
  type: typeof EBAY_ACTION.INVENTORY_ITEM_PUT_RESPONSE;
  ebayMarketplace: {
    code: string;
  };
  response?: IEbayBotPutInventoryItemResponse | null;
}

export interface InventoryItemPutCallback {
  (arg0: InventoryItemPutResponse): void;
}

export interface CategoryAspectsLoad extends UnknownAction {
  type: typeof EBAY_ACTION.CATEGORY_LOAD_ASPECTS;
  product: {
    uuid: string;
  };
  ebayMarketplace: {
    code: string;
  };
}

export interface CategoryAspectsLoadResponse extends UnknownAction {
  type: typeof EBAY_ACTION.CATEGORY_LOAD_ASPECTS_RESPONSE;
  ebayMarketplace: {
    code: string;
  };
  ebayAspects: IEbayAspect[];
}

export interface CategoryAspectsLoadCallback {
  (arg0: CategoryAspectsLoadResponse): void;
}

export interface CategoryAspectsSave extends UnknownAction {
  type: typeof EBAY_ACTION.CATEGORY_SAVE_ASPECTS;
  product: {
    uuid: string;
  };
  ebayMarketplace: {
    code: string;
  };
  values: TEbayAspectsValues;
}

export interface CategoryAspectsSaveResponse extends UnknownAction {
  type: typeof EBAY_ACTION.CATEGORY_SAVE_ASPECTS_RESPONSE;
}

export interface CategoryAspectsSaveCallback extends UnknownAction {
  (arg0: CategoryAspectsSaveResponse): void;
}

export interface IEbayState {
  aspects: Record<string, IEbayAspect[]>;
  categories: Record<string, Record<string, ICategoryEbay[]>>;
  categorySuggestions: Record<string, IEbayCategoryNode[]>;
  fulfillmentPolicies: Record<string, IEbayFulfillment[]>;
  inProcess: boolean;
  inventoryItems: Record<
    string,
    IEbayBotGetInventoryItemResponse | IEbayBotPutInventoryItemResponse
  >;
  marketplace?: IEbayMarketplace | null;
  marketplaces: IEbayMarketplace[];
  marketplacesTimestamp: number;
  offers: Record<
    string,
    | IEbayBotGetOffersResponse
    | IEbayBotPostOfferResponse
    | IEbayBotPutOfferResponse
    | IEbayBotPublishOfferResponse
  >;
  paymentPolicies: Record<string, IEbayPayment[]>;
  productEbay: Record<string, IProductEbayDetail | undefined | null>;
  returnPolicies: Record<string, IEbayReturn[]>;
  userUrl: string | undefined;
}

export type TEbayActionTypes =
  | CategoryAspectsLoad
  | CategoryAspectsLoadResponse
  | CategoryAspectsSave
  | CategoryAspectsSaveResponse
  | CategoryLoad
  | CategoryLoadResponse
  | CategorySave
  | CategorySuggestions
  | CategorySuggestionsResponse
  | InventoryItemLoad
  | InventoryItemLoadResponse
  | InventoryItemPut
  | InventoryItemPutResponse
  | InventoryItemsClearAllResponses
  | MarketplaceLoad
  | MarketplaceLoadFulfillmentPoliciesResponse
  | MarketplaceLoadPaymentPoliciesResponse
  | MarketplaceLoadResponse
  | MarketplaceLoadReturnPoliciesResponse
  | MarketplaceSetCurrency
  | MarketplaceSetCurrencyResponse
  | MarketplaceSetIsActive
  | MarketplaceSetIsActiveResponse
  | MarketplaceSetLanguage
  | MarketplaceSetLanguageResponse
  | MarketplacesLoad
  | MarketplacesLoadResponse
  | OfferClearAllResponses
  | OfferPost
  | OfferPostResponse
  | OfferPublish
  | OfferPublishResponse
  | OfferPut
  | OfferPutResponse
  | OffersLoad
  | OffersLoadResponse
  | ProductLoad
  | ProductLoadResponse
  | ProductSave
  | ProductSaveResponse
  | UserCode
  | UserUrl
  | UserUrlResponse;
