import { type IWarehouseProperty } from '@mahawi/eshop-common';
import {
  Alert,
  Button,
  Col,
  Divider,
  Form,
  List,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
} from 'antd';
import { type DefaultOptionType } from 'antd/es/select';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { type RootState } from '../../reducers';
import { downloaderLoadWarehouseProperties } from '../../reducers/downloader/actions';
import { type IDownloaderState } from '../../reducers/downloader/types';
import { propertySaveWarehouseProperties } from '../../reducers/property/actions';
import { type IPropertyState } from '../../reducers/property/types';
import BubbleComponent from '../bubble';
import Link from '../link';

interface IForm {
  warehousePropertiesRawNames: string[];
}

function WarehouseProperty({
  dispatch,
  Property,
  Downloader,
}: {
  dispatch: Dispatch;
  Property: IPropertyState;
  Downloader: IDownloaderState;
}): JSX.Element {
  const [form] = Form.useForm<IForm>();

  const [warehousePropertiesOptions, setWarehousePropertiesOptions] = useState<
    JSX.Element[]
  >([]);
  const [warehousePropertiesNames, setWarehousePropertiesNames] = useState<
    Map<string, string>
  >(new Map<string, string>());

  const onFinish = useCallback(
    ({ warehousePropertiesRawNames }: IForm): void => {
      if (!Property.property) {
        return;
      }

      dispatch(
        propertySaveWarehouseProperties(
          Property.property?.code,
          warehousePropertiesRawNames,
        ),
      );
    },
    [dispatch, Property.property],
  );

  useEffect((): void => {
    dispatch(downloaderLoadWarehouseProperties());
  }, [dispatch]);

  useEffect((): void => {
    form.setFieldsValue({
      warehousePropertiesRawNames:
        Property.property?.warehouseProperty?.assignedRawNames,
    });
  }, [Property.property?.warehouseProperty?.assignedRawNames, form]);

  useEffect((): void => {
    if (!Downloader.warehouse.properties) {
      return;
    }

    const warehousePropertiesOptionsUE: JSX.Element[] =
      Downloader.warehouse.properties.map(
        (warehouseProperty: IWarehouseProperty): JSX.Element => {
          let { name } = warehouseProperty;

          if (warehouseProperty.unit) {
            name += ` [${warehouseProperty.unit}]`;
          }

          return (
            <Select.Option
              key={warehouseProperty.rawName}
              value={warehouseProperty.rawName}
            >
              {name}
            </Select.Option>
          );
        },
      );

    setWarehousePropertiesOptions(warehousePropertiesOptionsUE);
  }, [Downloader.warehouse.properties]);

  useEffect(() => {
    if (
      !Downloader.warehouse.properties ||
      !Property.property?.warehouseProperty?.assignedRawNames
    ) {
      return;
    }

    const warehousePropertiesNamesUE = new Map<string, string>();

    Property.property.warehouseProperty.assignedRawNames.forEach(
      (rawName: string): void => {
        const warehouseProperty: IWarehouseProperty | undefined =
          Downloader.warehouse.properties?.find(
            (property: IWarehouseProperty): boolean =>
              property.rawName === rawName,
          );

        if (warehouseProperty) {
          let { name } = warehouseProperty;

          if (warehouseProperty.unit) {
            name += ` [${warehouseProperty.unit}]`;
          }

          warehousePropertiesNamesUE.set(rawName, name);
        }
      },
    );

    setWarehousePropertiesNames(warehousePropertiesNamesUE);
  }, [
    Property.property?.warehouseProperty?.assignedRawNames,
    Property.property?.warehouseProperty?.assignedRawNames.length,
    Downloader.warehouse.properties,
    Downloader.warehouse.properties?.length,
  ]);

  if (Downloader.warehouse.properties === undefined) {
    return <Skeleton active />;
  }

  if (Downloader.warehouse.properties === null) {
    return (
      <Alert message="Warehouse properties not found" type="error" showIcon />
    );
  }

  return (
    <BubbleComponent span={8}>
      <Space size={16} align="start" direction="horizontal">
        <strong>Warehouse property</strong>
      </Space>

      <Form form={form} onFinish={(values: IForm): void => onFinish(values)}>
        <Row>
          <Col span={8}>
            <Space size={16} align="start">
              <Button
                type="primary"
                htmlType="submit"
                disabled={Property.inProcess}
              >
                Submit
              </Button>
              <Spin size="small" spinning={Property.inProcess} />
            </Space>
          </Col>
        </Row>

        <Divider />

        <Row>
          <Col span={24}>
            <Form.Item name="warehousePropertiesRawNames">
              <Select
                mode="multiple"
                allowClear
                placeholder="Select warehouse properties"
                disabled={Property.inProcess}
                autoClearSearchValue={false}
                filterOption={(
                  input: string,
                  option: DefaultOptionType | undefined,
                ): boolean => {
                  const childrenText: string = String(
                    option ? option.children : '',
                  ).toLowerCase();
                  return childrenText.includes(input.toLowerCase()) ?? false;
                }}
                filterSort={(
                  optionA: DefaultOptionType,
                  optionB: DefaultOptionType,
                ): number => {
                  const childrenAText: string = String(
                    optionA ? optionA.children : '',
                  ).toLowerCase();

                  const childrenBText: string = String(
                    optionB ? optionB.children : '',
                  ).toLowerCase();

                  return childrenAText.localeCompare(childrenBText);
                }}
              >
                {warehousePropertiesOptions}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <List
              size="small"
              header={<strong>Assigned warehouse properties</strong>}
              dataSource={[...warehousePropertiesNames]}
              renderItem={(item: [string, string]): JSX.Element => {
                const rawNameEncoded: string = encodeURIComponent(item[0]);

                return (
                  <List.Item>
                    <Link
                      to={`/downloader/warehouse-property/${rawNameEncoded}`}
                      label={item[1]}
                      openInNewTab
                    />
                  </List.Item>
                );
              }}
            />
          </Col>
        </Row>
      </Form>
    </BubbleComponent>
  );
}

const mapStateToProps = ({
  Property,
  Downloader,
}: RootState): {
  Property: IPropertyState;
  Downloader: IDownloaderState;
} => ({
  Property,
  Downloader,
});

export default connect(mapStateToProps)(WarehouseProperty);
