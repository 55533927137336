import { type IPropertyValue, type ITranslation } from '@mahawi/eshop-common';
import { createReducer } from '@reduxjs/toolkit';

import {
  nameUpdate,
  nameUpdateResponse,
  nameValueUpdate,
  nameValueUpdateResponse,
  propertyLoad,
  propertyLoadResponse,
  propertyValueNameLive,
} from './actions';
import { nameLive } from './actions';
import { type IPropertyState } from './types';

export const preloadedState: IPropertyState = {
  inProcess: false,
  property: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(propertyLoad, (state) => {
    state.inProcess = true;
  });

  builder.addCase(propertyLoadResponse, (state, action) => {
    state.inProcess = false;
    state.property = action.payload.property;
  });

  builder.addCase(nameUpdate, (state) => {
    state.inProcess = true;
  });

  builder.addCase(nameUpdateResponse, (state) => {
    state.inProcess = false;
  });

  builder.addCase(nameValueUpdate, (state) => {
    state.inProcess = true;
  });

  builder.addCase(nameValueUpdateResponse, (state) => {
    state.inProcess = false;
  });

  builder.addCase(nameLive, (state, { payload }) => {
    const name: ITranslation | undefined = state.property?.names.find(
      ({ code }: ITranslation): boolean => code === payload.languageType.code,
    );

    if (name) {
      name.value = payload.property.name;
    } else {
      state.property?.names.push({
        code: payload.languageType.code,
        value: payload.property.name,
      });
    }
  });

  builder.addCase(propertyValueNameLive, (state, { payload }) => {
    const propertyValue: IPropertyValue | undefined =
      state.property?.values.find(
        (p: IPropertyValue): boolean => p.code === payload.propertyValue.code,
      );

    if (propertyValue) {
      const name: ITranslation | undefined = propertyValue.names.find(
        ({ code }: ITranslation): boolean => code === payload.languageType.code,
      );

      if (name) {
        name.value = payload.propertyValue.name;
      } else {
        propertyValue.names.push({
          code: payload.languageType.code,
          value: payload.propertyValue.name,
        });
      }
    }
  });
});
