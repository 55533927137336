import { green, red } from '@ant-design/colors';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { type RootState } from 'admin/react/reducers';
import { Button, Col, Collapse, Form, InputNumber, Row, Space } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { dimensionsUpdate } from '../../reducers/product/actions';
import { type IProductState } from '../../reducers/product/types';

interface IForm {
  weight: number;
  length: number;
  width: number;
  height: number;
}

const { Panel } = Collapse;

function Dimensions({
  dispatch,
  Product,
}: {
  dispatch: Dispatch;
  Product: IProductState;
}): JSX.Element {
  const [form] = Form.useForm<IForm>();

  const onFinish = (values: IForm) => {
    dispatch(
      dimensionsUpdate(
        Product.uuid,
        values.weight,
        values.width,
        values.height,
        values.length,
      ),
    );
  };

  const statusIcon = Product.dimensions.weight ? (
    <CheckCircleOutlined style={{ color: green.primary }} />
  ) : (
    <CloseCircleOutlined style={{ color: red.primary }} />
  );

  return (
    <Collapse>
      <Panel
        header={
          <Space size={8} align="start" direction="horizontal">
            <strong>Dimensions</strong>
            {statusIcon}
          </Space>
        }
        key="dimensions"
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            weight: Product.dimensions.weight,
            length: Product.dimensions.length,
            width: Product.dimensions.width,
            height: Product.dimensions.height,
          }}
        >
          <Row>
            <Col span={24}>
              <Form.Item
                name="weight"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'number',
                  },
                ]}
              >
                <InputNumber
                  placeholder="weight"
                  addonAfter="Kg"
                  disabled={Product.inProcess}
                />
              </Form.Item>

              <Form.Item
                name="length"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'number',
                  },
                ]}
              >
                <InputNumber
                  placeholder="length"
                  addonAfter="m"
                  disabled={Product.inProcess}
                />
              </Form.Item>

              <Form.Item
                name="width"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'number',
                  },
                ]}
              >
                <InputNumber
                  placeholder="width"
                  addonAfter="m"
                  disabled={Product.inProcess}
                />
              </Form.Item>

              <Form.Item
                name="height"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: 'number',
                  },
                ]}
              >
                <InputNumber
                  placeholder="height"
                  addonAfter="m"
                  disabled={Product.inProcess}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={Product.inProcess}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ Product }: RootState) => ({
  Product,
});

export default connect(mapStateToProps)(Dimensions);
