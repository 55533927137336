import { createAction } from '@reduxjs/toolkit';

import { COUNTRY_ACTION } from './types';

export const countryLoad = createAction(COUNTRY_ACTION.LOAD, (isoCode) => ({
  payload: { isoCode },
}));

export const countryLoadResponse = createAction(
  COUNTRY_ACTION.LOAD_RESPONSE,
  (country) => ({
    payload: { country },
  }),
);

export const countrySaveDescription = createAction(
  COUNTRY_ACTION.SAVE_DESCRIPTION,
  (isoCode, description) => ({
    payload: { isoCode, description },
  }),
);

export const countrySaveDeliveryDescription = createAction(
  COUNTRY_ACTION.SAVE_DELIVERY_DESCRIPTION,
  (isoCode, deliveryDescription) => ({
    payload: { isoCode, deliveryDescription },
  }),
);

export const countrySaveDeliveryTime = createAction(
  COUNTRY_ACTION.SAVE_DELIVERY_TIME,
  (isoCode, deliveryTime) => ({
    payload: { isoCode, deliveryTime },
  }),
);
