import { createAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

import { USER_ACTION } from './types';

export const login = createAction(
  USER_ACTION.LOGIN,
  (email: string, password: string) => ({
    payload: { email, password },
  }),
);

export const loginResponse = createAction(
  USER_ACTION.LOGIN_RESPONSE,
  (email: string, uuid?: string, token?: string) => ({
    payload: { email, uuid, token },
  }),
);

export const logout = createAction(USER_ACTION.LOGOUT, () => ({
  payload: { token: Cookies.get('ropere_token') || '' },
}));
