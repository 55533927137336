import {
  type IDiscount,
  type IDiscountType,
} from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum DISCOUNT_ACTION {
  DELETE = 'DISCOUNT_DELETE',
  DELETE_RESPONSE = 'DISCOUNT_DELETE_RESPONSE',
  LOAD_ALL = 'DISCOUNT_LOAD_ALL',
  LOAD_ALL_RESPONSE = 'DISCOUNT_LOAD_ALL_RESPONSE',
  LOAD_TYPES = 'DISCOUNT_LOAD_TYPES',
  LOAD_TYPES_RESPONSE = 'DISCOUNT_LOAD_TYPES_RESPONSE',
  SAVE = 'DISCOUNT_SAVE',
  SAVE_RESPONSE = 'DISCOUNT_SAVE_RESPONSE',
}

export interface IDelete extends UnknownAction {
  type: DISCOUNT_ACTION.DELETE;
  payload: {
    discount: {
      uuid: string;
    };
  };
}

export interface ILoadAll extends UnknownAction {
  type: DISCOUNT_ACTION.LOAD_ALL;
}

export interface ILoadAllResponse extends UnknownAction {
  type: DISCOUNT_ACTION.LOAD_ALL_RESPONSE;
  payload: {
    discounts: IDiscount[];
  };
}

export interface ILoadAllCallback {
  (arg0: ILoadAllResponse): void;
}

export interface ILoadTypes extends UnknownAction {
  type: DISCOUNT_ACTION.LOAD_TYPES;
}

export interface ILoadTypesResponse extends UnknownAction {
  type: DISCOUNT_ACTION.LOAD_TYPES_RESPONSE;
  payload: {
    discountTypes: IDiscountType[];
  };
}
export interface ILoadTypesCallback {
  (arg0: ILoadTypesResponse): void;
}

export interface ISave extends UnknownAction {
  type: DISCOUNT_ACTION.SAVE;
  payload: {
    discountType: {
      uuid: string;
    };
    value: number;
    description: string;
    validTo: Date;
  };
}

export interface IDiscountState {
  inProcess: boolean;
  discountTypes?: IDiscountType[];
  discounts?: IDiscount[];
  updatedAt?: number;
}
