import { createReducer } from '@reduxjs/toolkit';

import { search, searchResponse } from './actions';
import { type ISearchState } from './types';

export const preloadedState: ISearchState = {
  inProcess: false,
  query: undefined,
  products: undefined,
  updatedAt: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(search, (state, action) => {
    state.inProcess = true;
    state.products = undefined;
    state.query = action.payload.query;
  });

  builder.addCase(searchResponse, (state, action) => {
    if (action.payload.query !== state.query) {
      return;
    }

    state.inProcess = false;
    state.products = action.payload.products;
    state.updatedAt = new Date().getTime();
  });
});
