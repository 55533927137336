import {
  type IBasketProduct,
  type ILead,
  type ILeadBasketProducts,
  type ILeadSession,
  type ILeadSessions,
  type IMessage,
  type IMessengerLead,
} from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum MESSENGER_ACTION {
  ADMIN_CONNECTED = 'MESSENGER_ADMIN_CONNECTED',
  ADMIN_CONNECTED_RESPONSE = 'MESSENGER_ADMIN_CONNECTED_RESPONSE',
  ADMIN_DISCONNECTED = 'MESSENGER_ADMIN_DISCONNECTED',
  ADMIN_DISCONNECTED_RESPONSE = 'MESSENGER_ADMIN_DISCONNECTED_RESPONSE',
  CUSTOMER_SEND_MESSAGE = 'MESSENGER_CUSTOMER_SEND_MESSAGE',
  CUSTOMER_TICK = 'MESSENGER_CUSTOMER_TICK',
  LEAD_BASKET_PRODUCTS = 'MESSENGER_LEAD_BASKET_PRODUCTS',
  LEAD_SESSIONS = 'MESSENGER_LEAD_SESSIONS',
  LOAD_LEADS = 'MESSENGER_LOAD_LEADS',
  LOAD_LEADS_RESPONSE = 'MESSENGER_LOAD_LEADS_RESPONSE',
  SEND_MESSAGE = 'MESSENGER_SEND_MESSAGE',
  SEND_MESSAGE_RESPONSE = 'MESSENGER_SEND_MESSAGE_RESPONSE',
  SET_ACTIVE_LEAD = 'MESSENGER_SET_ACTIVE_LEAD',
  SET_ACTIVE_LEAD_RESPONSE = 'MESSENGER_SET_ACTIVE_LEAD_RESPONSE',
}

export interface LeadMessenger extends IMessengerLead {
  unread?: number;
  isActive?: boolean;
}

export interface ISetActiveLead extends UnknownAction {
  type: MESSENGER_ACTION.SET_ACTIVE_LEAD;
  payload: {
    lead: ILead;
  };
}

export interface ISetActiveLeadResponse extends UnknownAction {
  type: MESSENGER_ACTION.SET_ACTIVE_LEAD_RESPONSE;
  payload: {
    lead: ILead;
    messages: IMessage[];
  };
}

export interface ISetActiveLeadCallback {
  (arg0: ISetActiveLeadResponse): void;
}

export interface ISendMessage extends UnknownAction {
  type: MESSENGER_ACTION.SEND_MESSAGE;
  payload: {
    message: string;
    lead: ILead;
  };
}

export interface ISendMessageResponse extends UnknownAction {
  type: MESSENGER_ACTION.SEND_MESSAGE_RESPONSE;
  payload: {
    saved: boolean;
    message?: IMessage;
  };
}

export interface ISendMessageCallback {
  (arg0: ISendMessageResponse): void;
}

export interface ICustomerSendMessage extends UnknownAction {
  type: MESSENGER_ACTION.CUSTOMER_SEND_MESSAGE;
  paylaod: {
    lead: ILead;
    message: IMessage;
  };
}

export interface ILoadLeads extends UnknownAction {
  type: MESSENGER_ACTION.LOAD_LEADS;
  payload: {
    hours: number;
  };
}

export interface ILoadLeadsResponse extends UnknownAction {
  type: MESSENGER_ACTION.LOAD_LEADS_RESPONSE;
  payload: {
    leads: LeadMessenger[];
  };
}

export interface ILoadLeadCallback {
  (arg0: ILoadLeadsResponse): void;
}

export interface ICustomerTickMessage extends UnknownAction {
  type: MESSENGER_ACTION.CUSTOMER_TICK;
  payload: {
    lead: ILead;
    timestamp: number;
    event: {
      code: string;
      type: string;
    };
  };
}

export interface ILoadLeadSessions extends ILeadSessions, UnknownAction {
  type: MESSENGER_ACTION.LEAD_SESSIONS;
}

export interface ILoadLeadBasketProducts
  extends ILeadBasketProducts,
    UnknownAction {
  type: MESSENGER_ACTION.LEAD_BASKET_PRODUCTS;
}

export interface IAdminConnected extends UnknownAction {
  type: MESSENGER_ACTION.ADMIN_CONNECTED;
  payload: {
    admin: {
      uuid: string;
    };
  };
}

export interface IAdminConnectedResponse extends UnknownAction {
  type: MESSENGER_ACTION.ADMIN_CONNECTED_RESPONSE;
  payload: {
    set: boolean;
  };
}

export interface IAdminConnectedCallback {
  (arg0: IAdminConnectedResponse): void;
}

export interface IAdminDisconnected extends UnknownAction {
  type: MESSENGER_ACTION.ADMIN_DISCONNECTED;
  payload: {
    admin: {
      uuid: string;
    };
  };
}

export interface IAdminDisconnectedResponse extends UnknownAction {
  type: MESSENGER_ACTION.ADMIN_DISCONNECTED_RESPONSE;
  payload: {
    set: boolean;
  };
}

export interface IAdminDisconnectedCallback {
  (arg0: IAdminDisconnectedResponse): void;
}

export interface IMessengerState {
  inProcess: boolean;
  leads?: LeadMessenger[];
  lead: {
    uuid?: string;
  };
  messages: IMessage[];
  sessions?: ILeadSession[];
  basketProducts?: IBasketProduct[];
  message: string;
  lastMessage: {
    uuid: string;
  };
  updatedAt?: number;
}
