import { Row } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { Key, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import BubbleComponent from '../components/bubble';
import Link from '../components/link';
import PropertyAdd from '../components/property/add';
import Table from '../components/table';
import { type RootState } from '../reducers';
import { propertiesLoad } from '../reducers/properties/actions';
import { type IPropertiesState } from '../reducers/properties/types';

interface DataType {
  key: string;
  code: string;
}

interface IFilterElement {
  text: string;
  value: string;
}

function PropertiesContainer({
  dispatch,
  Properties,
}: {
  dispatch: Dispatch;
  Properties: IPropertiesState;
}): JSX.Element {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);

  useEffect((): void => {
    dispatch(propertiesLoad());
  }, [dispatch]);

  useEffect((): void => {
    const dataSourceUE: DataType[] = Properties.codes.map(
      (code: string): DataType => ({
        key: code,
        code,
      }),
    );

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Code',
        dataIndex: 'code',
        sorter: (a: DataType, b: DataType): number =>
          a.code.localeCompare(b.code),
        filterSearch: true,
        filters: [
          ...new Set(dataSourceUE.map(({ code }: DataType): string => code)),
        ]
          .map(
            (code: string): IFilterElement => ({
              text: code,
              value: code,
            }),
          )
          .sort((a: IFilterElement, b: IFilterElement): number =>
            a.text.localeCompare(b.text),
          ),
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.code === value,
        render: (text: string, record: DataType): JSX.Element => (
          <Link to={`/property/${record.code}`} label={text} openInNewTab />
        ),
      },
    ];

    setDataSource(dataSourceUE);
    setColumns(columnsUE);
  }, [Properties.codes, Properties.codes.length]);

  return (
    <>
      <Row>
        <BubbleComponent span={8}>
          <PropertyAdd />
        </BubbleComponent>
      </Row>

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Properties.codes.length === 0}
      />
    </>
  );
}

const mapStateToProps = ({
  Properties,
}: RootState): { Properties: IPropertiesState } => ({ Properties });

export default connect(mapStateToProps)(PropertiesContainer);
