import {
  type IComparisonPortal,
  type IComparisonPortalCategory,
} from '@mahawi/eshop-common';
import { createAction } from '@reduxjs/toolkit';

import { COMPARISON_PORTAL_ACTION } from './types';

export const loadComparisonPortalAll = createAction(
  COMPARISON_PORTAL_ACTION.LOAD_COMPARISON_PORTALS_ALL,
);

export const loadComparisonPortalAllResponse = createAction(
  COMPARISON_PORTAL_ACTION.LOAD_COMPARISON_PORTALS_ALL_RESPONSE,
  (portals: IComparisonPortal[]) => ({
    payload: { portals },
  }),
);

export const loadComparisonPortalCategoriesAll = createAction(
  COMPARISON_PORTAL_ACTION.LOAD_CATEGORIES_ALL,
);

export const loadComparisonPortalCategoriesAllResponse = createAction(
  COMPARISON_PORTAL_ACTION.LOAD_CATEGORIES_ALL_RESPONSE,
  (categories: IComparisonPortalCategory[]) => ({
    payload: { categories },
  }),
);

export const saveComparisonPortalCategory = createAction(
  COMPARISON_PORTAL_ACTION.ASSIGN_COMPARISON_PORTAL_CATEGORY,
  (categoryUUID: string, comparisonPortalCategoryUUID: string) => ({
    payload: { categoryUUID, comparisonPortalCategoryUUID },
  }),
);

export const saveComparisonPortalCategoryResponse = createAction(
  COMPARISON_PORTAL_ACTION.ASSIGN_COMPARISON_PORTAL_CATEGORY_RESPONSE,
  (saved: boolean) => ({
    payload: { saved },
  }),
);
