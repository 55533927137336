import {
  EDimensionUnit,
  EWeightUnit,
  IAdminProductDetail,
} from '@mahawi/eshop-common/dist/src/types';
import {
  type IAdminWarehouse,
  type IBrand,
  type IPhoto,
  type ITranslation,
  type TPropertiesProductSave,
} from '@mahawi/eshop-common/dist/src/types';
import { createAction } from '@reduxjs/toolkit';

import {
  type ICurrencyPrices,
  PRODUCT_ACTION,
  ProductPhotoPositionChange,
} from './types';

export const load = createAction(PRODUCT_ACTION.LOAD, (uuid: string) => ({
  payload: { product: { uuid } },
}));

export const loadResponse = createAction(
  PRODUCT_ACTION.LOAD_ADMIN_RESPONSE,
  (product: IAdminProductDetail | null) => ({
    payload: { product },
  }),
);

export const saveCategories = createAction(
  PRODUCT_ACTION.SAVE_CATEGORIES,
  (uuid: string, uuids: string[]) => ({
    payload: { product: { uuid }, categories: { uuids } },
  }),
);

export const saveCategoriesResponse = createAction(
  PRODUCT_ACTION.SAVE_CATEGORIES_RESPONSE,
  (saved: boolean) => ({
    payload: { saved },
  }),
);

export const photoDelete = createAction(
  PRODUCT_ACTION.PHOTO_DELETE,
  (productUUID: string, photoUUID: string) => ({
    payload: { product: { uuid: productUUID }, photo: { uuid: photoUUID } },
  }),
);

export const photoDeleteResponse = createAction(
  PRODUCT_ACTION.PHOTO_DELETE_RESPONSE,
  (photoUUID: string) => ({
    payload: { photo: { uuid: photoUUID } },
  }),
);

export const photoPositionChange = createAction(
  PRODUCT_ACTION.PHOTO_POSITION_CHANGE,
  (photoUUID: string, direction: ProductPhotoPositionChange) => ({
    payload: { photo: { uuid: photoUUID }, direction },
  }),
);

export const photoPositionSave = createAction(
  PRODUCT_ACTION.PHOTO_POSITION_SAVE,
  (productUUID: string, photos: IPhoto[]) => ({
    payload: { product: { uuid: productUUID }, photos },
  }),
);

export const photoPositionSaveResponse = createAction(
  PRODUCT_ACTION.PHOTO_POSITION_SAVE_RESPONSE,
  (saved: boolean) => ({
    payload: { saved },
  }),
);

export const nameLive = createAction(
  PRODUCT_ACTION.NAME_LIVE,
  (name: string, languageCode: string) => ({
    payload: { name, languageType: { code: languageCode } },
  }),
);

export const descriptionLive = createAction(
  PRODUCT_ACTION.DESCRIPTION_LIVE,
  (description: string, languageCode: string) => ({
    payload: { description, languageType: { code: languageCode } },
  }),
);

export const slugLive = createAction(
  PRODUCT_ACTION.SLUG_LIVE,
  (slug: string, languageCode: string) => ({
    payload: { slug, languageType: { code: languageCode } },
  }),
);

export const namesDescriptionsSlugsUpdate = createAction(
  PRODUCT_ACTION.NAMES_DESCRIPTIONS_SLUGS_UPDATE,
  (
    uuid: string,
    names: ITranslation[],
    descriptions: ITranslation[],
    slugs: ITranslation[],
  ) => ({
    payload: { product: { uuid }, names, descriptions, slugs },
  }),
);

export const namesDescriptionsSlugsUpdateResponse = createAction(
  PRODUCT_ACTION.NAMES_DESCRIPTIONS_SLUGS_UPDATE_RESPONSE,
  (updated: boolean) => ({
    payload: { updated },
  }),
);

export const copyDescriptions = createAction(
  PRODUCT_ACTION.COPY_DESCRIPTIONS,
  (productSourceUUID: string, productDestinationUUID: string) => ({
    payload: {
      product: { uuid: productDestinationUUID, sourceUUID: productSourceUUID },
    },
  }),
);

export const copyDescriptionsResponse = createAction(
  PRODUCT_ACTION.COPY_DESCRIPTIONS_RESPONSE,
  (product: IAdminProductDetail | null) => ({
    payload: { product },
  }),
);

export const dimensionsUpdate = createAction(
  PRODUCT_ACTION.DIMENSIONS_UPDATE,
  (
    uuid: string,
    weight: number,
    width: number,
    height: number,
    length: number,
  ) => ({
    payload: {
      product: { uuid },
      dimensions: {
        weight,
        width,
        height,
        length,
        dimensionUnit: EDimensionUnit.M,
        weightUnit: EWeightUnit.KG,
      },
    },
  }),
);

export const dimensionsUpdateResponse = createAction(
  PRODUCT_ACTION.DIMENSIONS_UPDATE_RESPONSE,
  (updated: boolean) => ({
    payload: { updated },
  }),
);

export const stockUpdate = createAction(
  PRODUCT_ACTION.STOCK_UPDATE,
  (
    productUUID: string,
    productMPN: string | undefined,
    nextDeliveryDate: Date | undefined,
    warehouses: IAdminWarehouse[],
  ) => ({
    payload: {
      product: { uuid: productUUID, mpn: productMPN },
      nextDeliveryDate,
      warehouses,
    },
  }),
);

export const stockUpdateResponse = createAction(
  PRODUCT_ACTION.STOCK_UPDATE_RESPONSE,
  (updated: boolean) => ({
    payload: { updated },
  }),
);

export const currenciesPricesUpdate = createAction(
  PRODUCT_ACTION.CURRENCIES_PRICES_UPDATE,
  (uuid: string, currencyPrices: ICurrencyPrices[]) => ({
    payload: { product: { uuid }, currencyPrices },
  }),
);

export const currenciesPricesUpdateResponse = createAction(
  PRODUCT_ACTION.CURRENCIES_PRICES_UPDATE_RESPONSE,
  (updated: boolean) => ({
    payload: { updated },
  }),
);

export const brandSet = createAction(
  PRODUCT_ACTION.BRAND_SET,
  (uuid: string, brand: IBrand, mpn: string, gtin: string) => ({
    payload: { product: { uuid }, brand, mpn, gtin },
  }),
);

export const brandSetResponse = createAction(
  PRODUCT_ACTION.BRAND_SET_RESPONSE,
  (updated: boolean) => ({
    payload: { updated },
  }),
);

export const productRecalculaterPrice = createAction(
  PRODUCT_ACTION.RECALCULATED_PRICE,
  (isoCode: string, price: number) => ({
    payload: { currencyType: { isoCode }, price },
  }),
);

export const productPropertiesSave = createAction(
  PRODUCT_ACTION.PROPERTIES_SAVE,
  (productUUID: string, productProperties: TPropertiesProductSave) => ({
    payload: { product: { uuid: productUUID }, productProperties },
  }),
);

export const productPropertiesSaveResponse = createAction(
  PRODUCT_ACTION.PROPERTIES_SAVE_RESPONSE,
  (saved: boolean) => ({
    payload: { saved },
  }),
);

export const productAssignRelatedProducts = createAction(
  PRODUCT_ACTION.ASSIGN_RELATED_PRODUCTS,
  (productUUID: string, relatedUUIDs: string[]) => ({
    payload: { product: { uuid: productUUID, relatedUUIDs } },
  }),
);

export const productAssignRelatedProductsResponse = createAction(
  PRODUCT_ACTION.ASSIGN_RELATED_PRODUCTS_RESPONSE,
  (saved: boolean) => ({
    payload: { saved },
  }),
);

export const photoDownloadFromUrlsAction = createAction(
  PRODUCT_ACTION.PHOTO_DOWNLOAD_FROM_URLS,
  (productUUID: string, urls: string[]) => ({
    payload: { product: { uuid: productUUID }, urls },
  }),
);

export const photoDownloadFromUrlsResponse = createAction(
  PRODUCT_ACTION.PHOTO_DOWNLOAD_FROM_URLS_RESPONSE,
  (saved: boolean) => ({
    payload: { saved },
  }),
);

export const productsFindInElasticSearch = createAction(
  PRODUCT_ACTION.PRODUCTS_FIND_IN_ELASTIC_SEARCH,
  (languageCode: string, query: string) => ({
    payload: { languageType: { code: languageCode }, query },
  }),
);

export const productsFindInElasticSearchResponse = createAction(
  PRODUCT_ACTION.PRODUCTS_FIND_IN_ELASTIC_SEARCH_RESPONSE,
  (productUUIDs: string[]) => ({
    payload: { productUUIDs },
  }),
);

export const productSetActive = createAction(
  PRODUCT_ACTION.SET_ACTIVE,
  (productUUID: string, isActive: boolean) => ({
    payload: { product: { uuid: productUUID }, isActive },
  }),
);
