import { createReducer } from '@reduxjs/toolkit';

import { type ICurrencyState } from './types';

export const preloadedState: ICurrencyState = {
  currencies: [],
  exchangeRates: [],
};

export default createReducer(preloadedState, () => {});
