import { ReloadOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Col,
  Descriptions,
  Divider,
  Form,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { type Dispatch } from 'redux';

import WarehouseProductsTable from '../components/downloader/warehouse-products-table';
import SelectCategoryOptions from '../components/input/select-category-options';
import { type RootState } from '../reducers';
import { categoriesLoadAll } from '../reducers/categories/actions';
import {
  downloaderLoadWarehouseCategory,
  downloaderLoadWarehouseProductsByWarehouseCategory,
  downloaderPairWarehouseCategory,
} from '../reducers/downloader/actions';
import { type IDownloaderState } from '../reducers/downloader/types';

interface IForm {
  categoryUUID: string;
}

const onFinishCategory = (values: IForm, uuid: string, dispatch: Dispatch) => {
  dispatch(downloaderPairWarehouseCategory(uuid, values.categoryUUID));
};

const reloadAll = (uuid: string | undefined, dispatch: Dispatch) => {
  if (uuid) {
    dispatch(downloaderLoadWarehouseCategory(uuid));
    dispatch(downloaderLoadWarehouseProductsByWarehouseCategory(uuid));
  }

  dispatch(categoriesLoadAll());
};

function DownloaderWarehouseCategory({
  dispatch,
  Downloader,
}: {
  dispatch: Dispatch;
  Downloader: IDownloaderState;
}): JSX.Element {
  const categoryJson = JSON.stringify(Downloader.warehouse.category);

  const { uuid } = useParams();
  const [formCategory] = Form.useForm<IForm>();

  useEffect(() => {
    reloadAll(uuid, dispatch);
  }, [uuid, dispatch]);

  useEffect(() => {
    formCategory.setFieldValue(
      'categoryUUID',
      Downloader.warehouse.category?.category?.uuid,
    );
  }, [Downloader.warehouse.category, categoryJson, formCategory]);

  if (Downloader.warehouse.category === undefined || !uuid) {
    return <Skeleton active />;
  }

  if (Downloader.warehouse.category === null) {
    return (
      <Alert
        message="Warehouse category not found"
        description={`Warehouse category with uuid ${uuid} not found.`}
        type="error"
        showIcon
      />
    );
  }

  return (
    <>
      <Typography.Title level={2}>Warehouse category</Typography.Title>

      <Divider />

      <Space size={16} align="start">
        <Tooltip title="Reload all infos">
          <Button
            color="primary"
            onClick={() => {
              reloadAll(uuid, dispatch);
            }}
            disabled={Downloader.inProcess}
          >
            All <ReloadOutlined />
          </Button>
        </Tooltip>
      </Space>

      <Divider />

      <Descriptions
        bordered
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="Raw Name">
          {Downloader.warehouse.category.rawName}
        </Descriptions.Item>

        <Descriptions.Item label="Name">
          {Downloader.warehouse.category.name}
        </Descriptions.Item>

        <Descriptions.Item label="Category">
          <Form
            form={formCategory}
            onFinish={(values) => onFinishCategory(values, uuid, dispatch)}
          >
            <Row>
              <Col span={8}>
                <Form.Item name="categoryUUID">
                  <SelectCategoryOptions
                    form={formCategory}
                    fieldName="categoryUUID"
                    isDisabled={Downloader.inProcess}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={Downloader.inProcess}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      <WarehouseProductsTable />
    </>
  );
}

const mapStateToProps = ({ Downloader }: RootState) => ({ Downloader });

export default connect(mapStateToProps)(DownloaderWarehouseCategory);
