import {
  EDimensionUnit,
  EWeightUnit,
  type IPhoto,
  IProductPrices,
  ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';

import {
  brandSet,
  brandSetResponse,
  copyDescriptions,
  copyDescriptionsResponse,
  currenciesPricesUpdate,
  currenciesPricesUpdateResponse,
  descriptionLive,
  dimensionsUpdate,
  dimensionsUpdateResponse,
  load,
  loadResponse,
  nameLive,
  namesDescriptionsSlugsUpdate,
  namesDescriptionsSlugsUpdateResponse,
  photoDelete,
  photoDeleteResponse,
  photoPositionChange,
  photoPositionSave,
  photoPositionSaveResponse,
  productAssignRelatedProducts,
  productAssignRelatedProductsResponse,
  productPropertiesSave,
  productPropertiesSaveResponse,
  productRecalculaterPrice,
  productSetActive,
  productsFindInElasticSearch,
  productsFindInElasticSearchResponse,
  saveCategories,
  saveCategoriesResponse,
  slugLive,
  stockUpdate,
  stockUpdateResponse,
} from './actions';
import { type IProductState, ProductPhotoPositionChange } from './types';

export const preloadedState: IProductState = {
  brand: {
    uuid: '',
    name: '',
  },
  mpn: '',
  names: [],
  uuid: '',
  inProcess: false,
  descriptions: [],
  slugs: [],
  categories: { uuids: [] },
  photos: [],
  nextDeliveryDate: '',
  prices: [],
  dimensions: {
    weight: 0,
    width: 0,
    height: 0,
    length: 0,
    weightUnit: EWeightUnit.KG,
    dimensionUnit: EDimensionUnit.M,
  },
  warehouses: [],
  gtin: '',
  relatedUUIDs: [],
  relatedProductsFromElasticSearch: undefined,
  isActive: true,
  updatedAt: undefined,
};

const photoChangePosition = (
  photoUUID: string,
  direction: ProductPhotoPositionChange,
  photos: IPhoto[],
): IPhoto[] => {
  const photo: IPhoto | undefined = photos.find(
    ({ uuid }: IPhoto): boolean => uuid === photoUUID,
  );

  if (!photo) {
    return photos;
  }

  const newPosition: number = photo.position + direction;
  const prevPhoto: IPhoto | undefined = photos.find(
    ({ position }: IPhoto): boolean => position === newPosition,
  );

  if (prevPhoto) {
    prevPhoto.position -= direction;
  }

  photo.position = newPosition;

  photos.sort((a: IPhoto, b: IPhoto): number => a.position - b.position);

  return photos;
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(load, (state) => {
    state.inProcess = true;
  });

  builder.addCase(loadResponse, (state, action) => {
    if (action.payload.product) {
      state.uuid = action.payload.product.uuid;
      state.brand = action.payload.product.brand;
      state.mpn = action.payload.product.mpn;
      state.gtin = action.payload.product.gtin;
      state.categories = action.payload.product.categories;
      state.descriptions = action.payload.product.descriptions;
      state.dimensions = action.payload.product.dimensions;
      state.names = action.payload.product.names;
      state.nextDeliveryDate = action.payload.product.nextDeliveryDate;
      state.photos = action.payload.product.photos.sort(
        (a: IPhoto, b: IPhoto): number => a.position - b.position,
      );
      state.prices = action.payload.product.prices;
      state.relatedUUIDs = action.payload.product.relatedUUIDs;
      state.slugs = action.payload.product.slugs;
      state.warehouses = action.payload.product.warehouses;
      state.isActive = action.payload.product.isActive;
    }

    state.inProcess = false;
    state.updatedAt = Date.now();
  });

  builder.addCase(copyDescriptions, (state) => {
    state.inProcess = true;
  });

  builder.addCase(copyDescriptionsResponse, (state, action) => {
    if (action.payload.product) {
      state.uuid = action.payload.product.uuid;
      state.brand = action.payload.product.brand;
      state.mpn = action.payload.product.mpn;
      state.gtin = action.payload.product.gtin;
      state.categories = action.payload.product.categories;
      state.descriptions = action.payload.product.descriptions;
      state.dimensions = action.payload.product.dimensions;
      state.names = action.payload.product.names;
      state.nextDeliveryDate = action.payload.product.nextDeliveryDate;
      state.photos = action.payload.product.photos.sort(
        (a: IPhoto, b: IPhoto): number => a.position - b.position,
      );
      state.prices = action.payload.product.prices;
      state.relatedUUIDs = action.payload.product.relatedUUIDs;
      state.slugs = action.payload.product.slugs;
      state.warehouses = action.payload.product.warehouses;
      state.isActive = action.payload.product.isActive;
    }

    state.inProcess = false;
    state.updatedAt = Date.now();
  });

  builder.addCase(photoPositionSave, (state) => {
    state.inProcess = true;
  });

  builder.addCase(photoPositionSaveResponse, (state) => {
    state.inProcess = false;
    state.updatedAt = Date.now();
  });

  builder.addCase(saveCategories, (state, action) => {
    state.inProcess = true;
    state.categories = { uuids: action.payload.categories.uuids };
  });

  builder.addCase(saveCategoriesResponse, (state) => {
    state.inProcess = false;
    state.updatedAt = Date.now();
  });

  builder.addCase(photoDelete, (state) => {
    state.inProcess = true;
  });

  builder.addCase(photoDeleteResponse, (state, action) => {
    state.photos = state.photos.filter(
      (p: IPhoto): boolean => p.uuid !== action.payload.photo.uuid,
    );
    state.inProcess = false;
    state.updatedAt = Date.now();
  });

  builder.addCase(photoPositionChange, (state, action) => {
    state.photos = photoChangePosition(
      action.payload.photo.uuid,
      action.payload.direction,
      state.photos,
    );
    state.updatedAt = Date.now();
  });

  builder.addCase(nameLive, (state, action) => {
    const name: ITranslation | undefined = state.names.find(
      ({ code }: ITranslation): boolean =>
        code === action.payload.languageType.code,
    );

    if (name) {
      name.value = action.payload.name;
    } else {
      state.names.push({
        code: action.payload.languageType.code,
        value: action.payload.name,
      });
    }

    state.updatedAt = Date.now();
  });

  builder.addCase(descriptionLive, (state, action) => {
    const desc: ITranslation | undefined = state.descriptions.find(
      ({ code }: ITranslation): boolean =>
        code === action.payload.languageType.code,
    );

    if (desc) {
      desc.value = action.payload.description;
    } else {
      state.descriptions.push({
        code: action.payload.languageType.code,
        value: action.payload.description,
      });
    }

    state.updatedAt = Date.now();
  });

  builder.addCase(slugLive, (state, action) => {
    const slug: ITranslation | undefined = state.slugs.find(
      ({ code }: ITranslation): boolean =>
        code === action.payload.languageType.code,
    );

    if (slug) {
      slug.value = action.payload.slug;
    } else {
      state.slugs.push({
        code: action.payload.languageType.code,
        value: action.payload.slug,
      });
    }

    state.updatedAt = Date.now();
  });

  builder.addCase(brandSet, (state, action) => {
    state.brand = action.payload.brand;
    state.mpn = action.payload.mpn;
    state.gtin = action.payload.gtin;
    state.inProcess = true;
  });

  builder.addCase(brandSetResponse, (state) => {
    state.updatedAt = Date.now();
    state.inProcess = false;
  });

  builder.addCase(currenciesPricesUpdate, (state) => {
    state.inProcess = true;
  });

  builder.addCase(currenciesPricesUpdateResponse, (state) => {
    state.updatedAt = Date.now();
    state.inProcess = false;
  });

  builder.addCase(namesDescriptionsSlugsUpdate, (state) => {
    state.inProcess = true;
  });

  builder.addCase(namesDescriptionsSlugsUpdateResponse, (state) => {
    state.updatedAt = Date.now();
    state.inProcess = false;
  });

  builder.addCase(stockUpdate, (state) => {
    state.inProcess = true;
  });

  builder.addCase(stockUpdateResponse, (state) => {
    state.updatedAt = Date.now();
    state.inProcess = false;
  });

  builder.addCase(productRecalculaterPrice, (state, action) => {
    const prices: IProductPrices[] = _.filter(
      state.prices,
      ({ currencyType }: IProductPrices): boolean =>
        currencyType.isoCode !== action.payload.currencyType.isoCode,
    );

    prices.push({
      currencyType: action.payload.currencyType,
      price: action.payload.price,
    });

    state.prices = prices;
    state.updatedAt = Date.now();
  });

  builder.addCase(productSetActive, (state, action) => {
    state.isActive = action.payload.isActive;
    state.inProcess = true;
  });

  builder.addCase(productsFindInElasticSearch, (state) => {
    state.relatedProductsFromElasticSearch = undefined;
    state.inProcess = true;
  });

  builder.addCase(productsFindInElasticSearchResponse, (state, action) => {
    state.relatedProductsFromElasticSearch = action.payload.productUUIDs;
    state.inProcess = false;
    state.updatedAt = Date.now();
  });

  builder.addCase(dimensionsUpdate, (state) => {
    state.inProcess = true;
  });

  builder.addCase(dimensionsUpdateResponse, (state) => {
    state.updatedAt = Date.now();
    state.inProcess = false;
  });

  builder.addCase(productPropertiesSave, (state) => {
    state.inProcess = true;
  });

  builder.addCase(productPropertiesSaveResponse, (state) => {
    state.updatedAt = Date.now();
    state.inProcess = false;
  });

  builder.addCase(productAssignRelatedProducts, (state) => {
    state.inProcess = true;
  });

  builder.addCase(productAssignRelatedProductsResponse, (state) => {
    state.updatedAt = Date.now();
    state.inProcess = false;
  });
});
