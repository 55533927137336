import { GlobalOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  getLowerCaseLanguageCode,
  getTranslation,
} from '@mahawi/eshop-common/dist/src/translation';
import {
  Alert,
  Button,
  Divider,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { type Dispatch } from 'redux';

import ChildCategories from '../components/category/child-categories';
import ComparisonPortalsCategoriesComponent from '../components/category/comparison-portals-categories';
import EbayCategoriesComponent from '../components/category/ebay-categories';
import Name from '../components/category/name';
import ProductsComponent from '../components/category/products';
import PropertiesComponent from '../components/category/properties';
import CopyToClipboard from '../components/copy-to-clipboard';
import LanguageButtons from '../components/language-buttons';
import Link from '../components/link';
import { type RootState } from '../reducers';
import { categoryLoad } from '../reducers/category/actions';
import { type ICategoryState } from '../reducers/category/types';
import { type IConfigState } from '../reducers/config/types';
import { type ILanguageState } from '../reducers/language/types';

function CategoryContainer({
  Category,
  Config,
  Language,
  dispatch,
}: {
  Category: ICategoryState;
  Config: IConfigState;
  Language: ILanguageState;
  dispatch: Dispatch;
}): JSX.Element {
  const { categoryUUID } = useParams();

  const [categoryNameLocalized, setCategoryNameLocalized] =
    useState<string>('** NOT YET SET **');

  useEffect((): void => {
    if (categoryUUID) {
      dispatch(categoryLoad(categoryUUID));
    }
  }, [dispatch, categoryUUID]);

  useEffect((): void => {
    if (!Category.category) {
      return;
    }

    const categoryNameLocalizedUE: string = getTranslation(
      Category.category.names,
      Language.languageType,
    );

    setCategoryNameLocalized(categoryNameLocalizedUE);
  }, [Category, Language.languageType]);

  if (Category.category === undefined || !categoryUUID) {
    return <Skeleton active />;
  }

  if (Category.category === null) {
    return (
      <Alert
        type="error"
        message="Category not found"
        description={`Category UUID: ${categoryUUID}`}
        showIcon
      />
    );
  }

  return (
    <>
      <Typography.Title level={2}>{categoryNameLocalized}</Typography.Title>

      <Space size={16} align="start">
        <Tooltip title="Reload category">
          <Button
            size="large"
            color="primary"
            onClick={(): void => {
              dispatch(categoryLoad(categoryUUID));
            }}
            disabled={Category.inProcess}
          >
            <ReloadOutlined />
          </Button>
        </Tooltip>

        <Tooltip title="Open category in Eshop">
          <a
            href={`${
              Config.url
            }/${getLowerCaseLanguageCode(Language.languageType)}/category/${categoryUUID}`}
            target="_blank"
            rel="noreferrer noopener nofollow"
            aria-label="Open category in new tab"
          >
            <Button size="large" color="primary">
              <GlobalOutlined />
            </Button>
          </a>
        </Tooltip>

        {Category.category.parentCategoryUUID && (
          <Tooltip title="Open parent category on admin">
            <Link
              to={`/category/${Category.category.parentCategoryUUID}`}
              openInNewTab
            />
          </Tooltip>
        )}

        <CopyToClipboard
          text={categoryUUID}
          title="Copy category UUID"
          size="large"
        />
      </Space>

      <Divider />

      <LanguageButtons />

      <Divider />

      <Name />

      <Divider />

      <PropertiesComponent />

      <Divider />

      <ChildCategories />

      <Divider />

      <EbayCategoriesComponent />

      <Divider />

      <ComparisonPortalsCategoriesComponent />

      <Divider />

      <ProductsComponent />
    </>
  );
}

const mapStateToProps = ({ Language, Category, Config, Ebay }: RootState) => ({
  Config,
  Language,
  Category,
  Ebay,
});

export default connect(mapStateToProps)(CategoryContainer);
