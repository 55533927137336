import { Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { type RootState } from '../reducers';
import { logout } from '../reducers/user/actions';
import { type IUserState } from '../reducers/user/types';

const { Title } = Typography;

function LogoutContainer({
  dispatch,
  User,
}: {
  dispatch: Dispatch;
  User: IUserState;
}): JSX.Element {
  useEffect(() => {
    if (User.isLoggedIn) {
      dispatch(logout());
    }
  }, [dispatch, User.isLoggedIn]);

  return (
    <Space size={16} align="start" direction="vertical">
      <Typography>
        <Title level={2}>Logged out.</Title>
      </Typography>
    </Space>
  );
}

const mapStateToProps = ({ User }: RootState): { User: IUserState } => ({
  User,
});

export default connect(mapStateToProps)(LogoutContainer);
