import {
  type IComparisonPortal,
  type IComparisonPortalCategory,
  type IComparisonPortalTypeCategory,
} from '@mahawi/eshop-common';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import { type RootState } from 'admin/react/reducers';
import { type ICategoryState } from 'admin/react/reducers/category/types';
import { type IComparisonPortalState } from 'admin/react/reducers/comparison-portal/types';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Space,
} from 'antd';
import { type DefaultOptionType } from 'antd/es/select';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import {
  loadComparisonPortalAll,
  loadComparisonPortalCategoriesAll,
  saveComparisonPortalCategory,
} from '../../reducers/comparison-portal/actions';

interface IForm {
  comparisonPortalCategoryUUID: string;
  categoryUUID: string;
}

function ComparisonPortalsCategoriesComponent({
  dispatch,
  ComparisonPortal,
  Category,
}: {
  dispatch: Dispatch;
  ComparisonPortal: IComparisonPortalState;
  Category: ICategoryState;
}): JSX.Element {
  const [comparisonPortalElements, setComparisonPortalElements] = useState<
    JSX.Element[]
  >([]);

  const [form] = Form.useForm<IForm>();

  useEffect((): void => {
    if (
      !ComparisonPortal.portals ||
      !Category.category ||
      !ComparisonPortal.categories
    ) {
      return;
    }

    const comparisonPortalElementsUE: JSX.Element[] =
      ComparisonPortal.portals.map(
        (comparisonPortal: IComparisonPortal): JSX.Element => {
          const selectChildren: JSX.Element[] | undefined =
            ComparisonPortal.categories
              ?.map(
                (category: IComparisonPortalCategory): JSX.Element | null => {
                  if (
                    category.comparisonPortalType.uuid !== comparisonPortal.uuid
                  ) {
                    return null;
                  }

                  return (
                    <Select.Option key={category.uuid} value={category.uuid}>
                      {category.name}
                    </Select.Option>
                  );
                },
              )
              .filter(notEmpty);

          const initialValues = {
            categoryUUID: Category.category?.uuid,
            comparisonPortalCategoryUUID:
              Category.category?.comparisonPortalTypes.find(
                (
                  comparisonPortalType: IComparisonPortalTypeCategory,
                ): boolean =>
                  comparisonPortalType.uuid === comparisonPortal.uuid,
              )?.category.uuid,
          };

          form.setFieldsValue(initialValues);

          return (
            <Form
              onFinish={(values: IForm): void => {
                dispatch(
                  saveComparisonPortalCategory(
                    values.categoryUUID,
                    values.comparisonPortalCategoryUUID,
                  ),
                );
              }}
              key={comparisonPortal.code}
              form={form}
            >
              <Row gutter={16}>
                <Col span={24}>
                  <Space size={16} align="start">
                    {comparisonPortal.name}
                  </Space>
                </Col>

                <Col span={21}>
                  <Form.Item name="categoryUUID" hidden>
                    <Input type="hidden" />
                  </Form.Item>

                  <Form.Item
                    name="comparisonPortalCategoryUUID"
                    rules={[
                      {
                        required: true,
                        message: 'Please select a comparison portal category',
                      },
                    ]}
                  >
                    <Select
                      size="large"
                      placeholder="Select a comparison portal category"
                      autoClearSearchValue={false}
                      showSearch
                      filterOption={(
                        input: string,
                        option: DefaultOptionType | undefined,
                      ): boolean => {
                        const childrenText: string = String(
                          option ? option.children : '',
                        ).toLowerCase();

                        return (
                          childrenText.includes(input.toLowerCase()) ?? false
                        );
                      }}
                      filterSort={(
                        optionA: DefaultOptionType,
                        optionB: DefaultOptionType,
                      ): number => {
                        const childrenAText: string = String(
                          optionA ? optionA.children : '',
                        ).toLowerCase();

                        const childrenBText: string = String(
                          optionB ? optionB.children : '',
                        ).toLowerCase();

                        return childrenAText.localeCompare(childrenBText);
                      }}
                    >
                      {selectChildren}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Button
                    type="primary"
                    size="large"
                    color="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        },
      );

    setComparisonPortalElements(comparisonPortalElementsUE);
  }, [
    ComparisonPortal.categories,
    ComparisonPortal.portals,
    Category.category,
    dispatch,
    form,
  ]);

  return (
    <Collapse
      onChange={(keys: string | string[]): void => {
        if (keys.includes('comparison-portals-categories')) {
          dispatch(loadComparisonPortalAll());
          dispatch(loadComparisonPortalCategoriesAll());
        }
      }}
    >
      <Collapse.Panel
        header="Comparison Portals Categories"
        key="comparison-portals-categories"
      >
        {ComparisonPortal.portals ? (
          comparisonPortalElements
        ) : (
          <Skeleton active />
        )}
      </Collapse.Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ ComparisonPortal, Category }: RootState) => ({
  ComparisonPortal,
  Category,
});

export default connect(mapStateToProps)(ComparisonPortalsCategoriesComponent);
