import { green, red } from '@ant-design/colors';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import {
  type ILanguageType,
  type IPropertyValue,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import { Button, Col, Divider, Form, Input, Row, Space, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { AddFocusEventListener } from '../../fragments/form-events';
import { type RootState } from '../../reducers';
import { type ILanguageState } from '../../reducers/language/types';
import {
  nameValueUpdate,
  propertyValueNameLive,
} from '../../reducers/property/actions';
import { type IPropertyState } from '../../reducers/property/types';
import CopyToClipboard from '../copy-to-clipboard';
import GoogleTranslate from '../google-translate';
import GoogleTranslateAutomat, {
  type ITextTranslate,
} from '../google-translate-automat';

interface IForm {
  name: string;
}

function ValueName({
  dispatch,
  Language,
  propertyValue,
  Property,
}: {
  dispatch: Dispatch;
  Language: ILanguageState;
  propertyValue: IPropertyValue;
  Property: IPropertyState;
}): JSX.Element {
  const [form] = Form.useForm<IForm>();

  const [invalidLanguageTranslations, setInvalidLanguageTranslations] =
    useState<Set<string>>(new Set());
  const [valueNameEN, setValueNameEN] = useState<string>();
  const [gtLinkName, setGtLinkName] = useState<string>(
    'GT value name EN → ---',
  );
  const [gtLinkNameReversed, setGtLinkNameReversed] = useState<string>(
    'GT value name --- → EN',
  );
  const [valueName, setValueName] = useState<string>('');

  const propertyValueNamesJson: string = JSON.stringify(propertyValue.names);

  const translateName = useCallback(
    (languageTypeCode: string): void => {
      AddFocusEventListener(form, (text: string): undefined => {
        form.setFieldsValue({
          name: text,
        });
        dispatch(
          propertyValueNameLive(propertyValue.code, text, languageTypeCode),
        );
      });
    },
    [dispatch, form, propertyValue.code],
  );

  const onFinish: (values: IForm) => void = useCallback(
    ({ name }: IForm): void => {
      if (propertyValue.code && Language.languageType) {
        dispatch(
          nameValueUpdate(propertyValue.code, name, Language.languageType.code),
        );
        dispatch(
          propertyValueNameLive(
            propertyValue.code,
            name,
            Language.languageType.code,
          ),
        );
      }
    },
    [dispatch, Language.languageType?.code, propertyValue.code],
  );

  useEffect((): void => {
    const valueNameUEEN: string = getTranslation(propertyValue.names);

    setValueNameEN(valueNameUEEN);

    const valueNameUE: string = getTranslation(
      propertyValue.names,
      Language.languageType,
    );

    setValueName(valueNameUE ? valueNameUE : '');

    const valueNameTranslatedUE: string = valueNameUE ? valueNameUE : '';

    form.setFieldsValue({
      name: valueNameTranslatedUE,
    });

    setGtLinkName(`GT value name EN → ${Language.languageType?.code}`);
    setGtLinkNameReversed(`GT value name ${Language.languageType?.code} → EN`);
  }, [
    Language.languageType?.code,
    form,
    propertyValue,
    propertyValue.names,
    propertyValueNamesJson,
  ]);

  useEffect((): void => {
    const invalidLanguageTranslationsUE = new Set<string>();

    Language.languages?.forEach((language: ILanguageType): void => {
      const cn: ITranslation | undefined = propertyValue.names.find(
        (p: ITranslation): boolean => p.code === language.code,
      );

      if (!cn || cn.value.length < 1) {
        invalidLanguageTranslationsUE.add(language.code);
      }
    });

    setInvalidLanguageTranslations(invalidLanguageTranslationsUE);
  }, [
    Language.languages,
    Language.languageType?.code,
    propertyValue.names,
    propertyValueNamesJson,
  ]);

  const valueNameTranslated: string =
    valueNameEN || `** ${propertyValue.code} **`;

  const texts: ITextTranslate[] = [
    {
      text: valueNameTranslated,
      callbacks: [
        (text: string): void => {
          form.setFieldValue('name', text);
        },
      ],
    },
  ];

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Space size={16} align="start" direction="horizontal">
            <strong>{propertyValue.code}</strong>
            {invalidLanguageTranslations.size === 0 ? (
              <CheckCircleOutlined style={{ color: green.primary }} />
            ) : (
              <>
                <CloseCircleOutlined style={{ color: red.primary }} />
                <small>
                  {[...invalidLanguageTranslations]
                    .map((code: string): string => code)
                    .join(', ')}
                </small>
              </>
            )}
          </Space>
        </Col>

        <Col span={24}>
          <Form
            form={form}
            onFinish={(values: IForm): void => onFinish(values)}
          >
            <Row>
              <Col span={12}>
                <Space size={16} align="start">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={Property.inProcess}
                  >
                    Submit
                  </Button>
                  <Spin size="small" spinning={Property.inProcess} />
                </Space>
              </Col>

              <Col span={4}>
                <GoogleTranslateAutomat
                  texts={texts}
                  submit={form.submit}
                  isSaving={Property.inProcess}
                />
              </Col>

              <Col span={4}>
                <GoogleTranslate
                  fromLanguageCode="EN"
                  toLanguageCode={Language.languageType?.code}
                  name={gtLinkName}
                  text={valueNameTranslated}
                  onClick={(): void => {
                    if (!Language.languageType) {
                      return;
                    }

                    translateName(Language.languageType.code);
                  }}
                />
              </Col>

              <Col span={4}>
                <GoogleTranslate
                  fromLanguageCode={Language.languageType?.code}
                  toLanguageCode="EN"
                  name={gtLinkNameReversed}
                  text={valueName}
                  onClick={(): void => translateName('EN')}
                />
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      type: 'string',
                      min: 2,
                    },
                  ]}
                  label="Name"
                >
                  <Input
                    placeholder="Name"
                    disabled={Property.inProcess}
                    addonAfter={
                      <CopyToClipboard
                        text={form.getFieldValue('name') || ''}
                        size="small"
                        type="link"
                      />
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Divider />
    </>
  );
}

const mapStateToProps = ({
  Language,
  Property,
}: RootState): {
  Language: ILanguageState;
  Property: IPropertyState;
} => ({
  Language,
  Property,
});

export default connect(mapStateToProps)(ValueName);
