import { blue } from '@ant-design/colors';
import { CopyTwoTone, GlobalOutlined } from '@ant-design/icons';
import { Alert, Col, Descriptions, message, Row, Skeleton, Space } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { type Dispatch } from 'redux';

import CopyToClipboard from '../components/copy-to-clipboard';
import Link from '../components/link';
import ProductAdd from '../components/product/product-add';
import { type RootState } from '../reducers';
import { downloaderLoadProduct } from '../reducers/downloader/actions';
import { type IDownloaderState } from '../reducers/downloader/types';
import { type IProductsState } from '../reducers/products/types';
import Socket from '../socket';

function DownloaderProductContainer({
  dispatch,
  Downloader,
  Products,
  socket = undefined,
}: {
  dispatch: Dispatch;
  Downloader: IDownloaderState;
  Products: IProductsState;
  socket?: Socket;
}): JSX.Element {
  const didMountRef = useRef(false);

  const { productDownloadedUUID } = useParams();

  useEffect((): void => {
    if (productDownloadedUUID) {
      dispatch(downloaderLoadProduct(productDownloadedUUID));
    }
  }, [dispatch, productDownloadedUUID]);

  useEffect((): (() => void) => {
    if (socket) {
      socket.subscribeEventUniversalDownloaderProductResponse();
    }

    return () => {
      if (socket) {
        socket.unsubscribeEventUniversalDownloaderProductResponse();
      }
    };
  }, [socket]);

  useEffect((): void => {
    if (didMountRef.current) {
      message.success(
        <>
          Product updated!
          <br />
          price: {Downloader.product?.price}
          <br />
          stock: {Downloader.product?.stock}
        </>,
      );
    } else {
      didMountRef.current = true;
    }
  }, [Downloader.product?.price, Downloader.product?.stock]);

  const { product } = Downloader;

  if (product === undefined) {
    return <Skeleton active />;
  }

  if (product === null) {
    return (
      <Alert
        type="error"
        message="Product not found"
        description={`Product UUID: ${productDownloadedUUID}`}
        showIcon
      />
    );
  }

  const { newProduct } = Products;

  const url = new URL(product.url);
  const productNameNormalized: string = url.pathname
    .replaceAll('/', '')
    .replaceAll('-', ' ')
    .replace(/([p\d]{6,})/gim, '')
    .trim();

  return (
    <>
      <Descriptions
        title="Downloader product"
        bordered
        column={{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 2, xs: 2 }}
      >
        <Descriptions.Item label="UUID" span={1}>
          <strong>{product.uuid}</strong>
        </Descriptions.Item>

        <Descriptions.Item label="Domain" span={1}>
          <strong>{product.domain}</strong>
        </Descriptions.Item>

        <Descriptions.Item label="Category" span={1}>
          <strong>{product.category}</strong>
        </Descriptions.Item>

        <Descriptions.Item label="Updated" span={1}>
          <strong>
            {dayjs(product.updatedAt).format('DD.MM.YYYY HH:mm:ss')}
          </strong>
        </Descriptions.Item>

        <Descriptions.Item label="URL" span={4}>
          <Space size={16} align="start">
            <strong>{product.url}</strong>
            <a
              href={product.url}
              target="_blank"
              rel="noreferrer noopener nofollow"
              aria-label="Open product in new tab"
            >
              <GlobalOutlined />
            </a>

            <CopyToClipboard text={product.url} type="link" />
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Name" span={4}>
          <Space size={16} align="start">
            <strong>{productNameNormalized}</strong>

            <CopyToClipboard text={productNameNormalized}>
              <CopyTwoTone twoToneColor={blue.primary} />
            </CopyToClipboard>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Product" span={4}>
          {product.product?.uuid ? (
            <Link
              to={`/product/${product.product.uuid}`}
              label={product.product.uuid}
              openInNewTab
            />
          ) : (
            'Not yet created'
          )}
        </Descriptions.Item>

        <Descriptions.Item label="Price" span={1}>
          <strong>{product.price}</strong>
        </Descriptions.Item>

        <Descriptions.Item label="Stock" span={1}>
          <strong>{product.stock}</strong>
        </Descriptions.Item>

        <Descriptions.Item label="Raw" span={2}>
          <small>
            <pre>{JSON.stringify(JSON.parse(product.raw), null, 2)}</pre>
          </small>
        </Descriptions.Item>
      </Descriptions>

      <br />
      <br />
      {!product.product.uuid && (
        <ProductAdd productDownloadedUUID={productDownloadedUUID} />
      )}

      {newProduct === null && (
        <Alert
          type="error"
          message="Product not created"
          description={`Product UUID: ${productDownloadedUUID}`}
          showIcon
        />
      )}

      {newProduct && (
        <Row>
          <Col span={10} offset={8}>
            <Link
              to={`/product/${newProduct.uuid}`}
              openInNewTab
              label={`Created new product with UUID ${newProduct.uuid}`}
            />
          </Col>
        </Row>
      )}
    </>
  );
}

const mapStateToProps = ({ Downloader, Products }: RootState) => ({
  Downloader,
  Products,
});

export default connect(mapStateToProps)(DownloaderProductContainer);
