import { type ICountry } from '@mahawi/eshop-common';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum COUNTRY_ACTION {
  LOAD = 'COUNTRY_LOAD',
  LOAD_RESPONSE = 'COUNTRY_LOAD_RESPONSE',
  SAVE_DELIVERY_DESCRIPTION = 'COUNTRY_SAVE_DELIVERY_DESCRIPTION',
  SAVE_DELIVERY_TIME = 'COUNTRY_SAVE_DELIVERY_TIME',
  SAVE_DESCRIPTION = 'COUNTRY_SAVE_DESCRIPTION',
}

export interface ILoad extends UnknownAction {
  type: COUNTRY_ACTION.LOAD;
  payload: {
    isoCode: string;
  };
}

export interface ILoadResponse extends UnknownAction {
  type: COUNTRY_ACTION.LOAD_RESPONSE;
  payload: {
    country: ICountry | null;
  };
}

export interface ILoadCallback {
  (arg0: ILoadResponse): void;
}

export interface ISaveDescription extends UnknownAction {
  type: COUNTRY_ACTION.SAVE_DESCRIPTION;
  payload: {
    isoCode: string;
    description: string;
  };
}

export interface ISaveDeliveryDescription extends UnknownAction {
  type: COUNTRY_ACTION.SAVE_DELIVERY_DESCRIPTION;
  payload: {
    isoCode: string;
    deliveryDescription: string;
  };
}

export interface ISaveDeliveryTime extends UnknownAction {
  type: COUNTRY_ACTION.SAVE_DELIVERY_TIME;
  payload: {
    isoCode: string;
    deliveryTime: number;
  };
}

export interface ICountryState {
  inProcess: boolean;
  country?: ICountry | null;
}
