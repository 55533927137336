import { type ISession } from '@mahawi/eshop-common';
import { createAction } from '@reduxjs/toolkit';

import { DASHBOARD_ACTION } from './types';

export const loadSessions = createAction(DASHBOARD_ACTION.LOAD_SESSIONS);

export const loadSessionsResponse = createAction(
  DASHBOARD_ACTION.LOAD_SESSIONS_RESPONSE,
  (sessions: ISession[]) => ({
    payload: { sessions },
  }),
);
