import { Button, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { type RootState } from '../reducers';
import { nextLanguage } from '../reducers/language/actions';
import { ILanguageState } from '../reducers/language/types';

const LANGUAGE_CODE_EN = 'EN';

export interface ITextTranslate {
  text: string;
  callbacks: ((translatedText: string, languageCode: string) => void)[];
}

const insertTranslation = async (
  textTranslate: ITextTranslate,
  languageCode: string,
): Promise<void> => {
  const text: string = await navigator.clipboard.readText();
  const tt: string = text.trim();

  textTranslate.callbacks.forEach((callback) => callback(tt, languageCode));

  await navigator.clipboard.writeText('');
};

const startAutomat = async (
  texts: ITextTranslate[],
  fromLanguageCode: string,
  toLanguageCode: string,
  setRunning: React.Dispatch<React.SetStateAction<boolean>>,
  submit: () => void,
  dispatch: Dispatch,
): Promise<void> => {
  setRunning(true);

  for (const textTranslate of texts) {
    await new Promise<void>((resolve) => {
      window.addEventListener(
        'focus',
        async (): Promise<void> => {
          await insertTranslation(textTranslate, toLanguageCode);

          setTimeout((): void => {
            submit();
          }, 300);

          setTimeout((): void => {
            dispatch(nextLanguage());
            setRunning(false);
            resolve();
          }, 500);
        },
        { once: true },
      );

      const gtLink = new URL('https://translate.google.com/');
      gtLink.searchParams.append('sl', fromLanguageCode.toLowerCase());
      gtLink.searchParams.append('tl', toLanguageCode.toLowerCase());
      gtLink.searchParams.append('text', textTranslate.text.trim());
      gtLink.searchParams.append('op', 'translate');

      window.open(gtLink.toString(), '_blank');
    });
  }
};

function GoogleTranslateAutomat({
  texts,
  submit,
  Language,
  dispatch,
  isSaving,
}: {
  texts: ITextTranslate[];
  submit: () => void;
  Language: ILanguageState;
  dispatch: Dispatch;
  isSaving: boolean;
}): JSX.Element {
  const [isRunning, setRunning] = useState(false);
  const [runAgain, setRunAgain] = useState(false);
  const [actualLanguageCode, setActualLanguageCode] = useState<string | null>();

  useEffect((): void => {
    if (
      !Language.languageType ||
      !runAgain ||
      isRunning ||
      isSaving ||
      texts.length === 0 ||
      actualLanguageCode === Language.languageType?.code
    ) {
      return;
    }

    if (Language.languageType?.code === LANGUAGE_CODE_EN) {
      setRunAgain(false);
      return;
    }

    setActualLanguageCode(Language.languageType?.code);

    startAutomat(
      texts,
      LANGUAGE_CODE_EN,
      Language.languageType.code,
      setRunning,
      submit,
      dispatch,
    );
  }, [
    runAgain,
    isRunning,
    isSaving,
    Language.languageType?.code,
    texts,
    submit,
    dispatch,
    actualLanguageCode,
  ]);

  if (
    isSaving ||
    texts.length === 0 ||
    LANGUAGE_CODE_EN === Language.languageType?.code
  ) {
    return (
      <Space size={16} align="start">
        <Button type="primary" disabled>
          GT Automat
        </Button>
      </Space>
    );
  }

  return (
    <Space size={16} align="start">
      <Button
        type="primary"
        disabled={isRunning}
        onClick={(): void => {
          if (!Language.languageType) {
            return;
          }

          startAutomat(
            texts,
            LANGUAGE_CODE_EN,
            Language.languageType.code,
            setRunning,
            submit,
            dispatch,
          );

          setRunAgain(true);
        }}
      >
        GT Automat
      </Button>
      <Spin size="small" spinning={isRunning} />
    </Space>
  );
}

const mapStateToProps = ({ Language }: RootState) => ({ Language });

export default connect(mapStateToProps)(GoogleTranslateAutomat);
