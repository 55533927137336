import { type UnknownAction } from '@reduxjs/toolkit';

export enum USER_ACTION {
  LOGIN_CHECK = 'LOGIN_CHECK',
  LOGOUT = 'LOGOUT',
  LOGOUT_RESPONSE = 'LOGOUT_RESPONSE',
  LOGIN = 'LOGIN',
  // LOGIN_IN_PROCESS = 'LOGIN_IN_PROCESS',
  LOGIN_RESPONSE = 'LOGIN_RESPONSE',
}

export interface ILogin extends UnknownAction {
  type: USER_ACTION.LOGIN;
  payload: {
    email: string;
    password: string;
  };
}

export interface ILoginResponse extends UnknownAction {
  type: USER_ACTION.LOGIN_RESPONSE;
  payload: {
    email: string;
    uuid?: string;
    token?: string;
  };
}

export interface ILoginCallback {
  (arg0: ILoginResponse): void;
}

// export interface ILoginInProcess extends UnknownAction {
//   type: USER_ACTION.LOGIN_IN_PROCESS;
//   payload: {
//     loginInProcess: boolean;
//   };
// }

export interface ILogout extends UnknownAction {
  type: USER_ACTION.LOGOUT;
  payload: {
    token: string;
  };
}

export interface ILogoutResponse extends UnknownAction {
  type: USER_ACTION.LOGOUT_RESPONSE;
}

export interface ILogoutCallback {
  (arg0: ILogoutResponse): void;
}

export interface IUserState {
  isLoggedIn: boolean;
  inProcess: boolean;
  email?: string;
  uuid?: string;
  updatedAt?: number;
}
