import { type IAdminCategory, type ITranslation } from '@mahawi/eshop-common';
import { createReducer } from '@reduxjs/toolkit';

import {
  categoryLoad,
  categoryLoadResponse,
  categorySavechildCategories,
  categorySavechildCategoriesResponse,
  nameLive,
  nameUpdate,
  nameUpdateResponse,
} from './actions';
import { type ICategoryState } from './types';

export const preloadedState: ICategoryState = {
  inProcess: false,
  category: undefined,
  updatedAt: undefined,
};

const setCategoryResponse = (
  state: ICategoryState,
  category: IAdminCategory | undefined,
): void => {
  category?.property.codes.sort((a: string, b: string): number =>
    a.localeCompare(b),
  );

  state.category = category;
  state.inProcess = false;
  state.updatedAt = Date.now();
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(categoryLoad, (state) => {
    state.inProcess = true;
    state.category = undefined;
  });

  builder.addCase(nameUpdate, (state) => {
    state.inProcess = true;
  });

  builder.addCase(categoryLoadResponse, (state, action) =>
    setCategoryResponse(state, action.payload.category),
  );
  builder.addCase(nameUpdateResponse, (state, action) =>
    setCategoryResponse(state, action.payload.category),
  );

  builder.addCase(nameLive, (state, action) => {
    const name: ITranslation | undefined = state.category?.names.find(
      ({ code }: ITranslation): boolean =>
        code === action.payload.languageType.code,
    );

    if (name) {
      name.value = action.payload.name;
    } else {
      state.category?.names.push({
        code: action.payload.languageType.code,
        value: action.payload.name,
      });
    }
  });

  builder.addCase(categorySavechildCategories, (state) => {
    state.inProcess = true;
  });

  builder.addCase(categorySavechildCategoriesResponse, (state, action) => {
    state.category = action.payload.category;
    state.inProcess = false;
  });
});
