import { createReducer } from '@reduxjs/toolkit';

import {
  orderLoad,
  orderLoadResponse,
  orderSetOrderStatusCode,
  reviewSentToCustomer,
  reviewSetStatusCode,
  saveOrderTags,
} from './actions';
import { type IOrderState } from './types';

export const preloadedState: IOrderState = {
  inProcess: false,
  order: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(orderLoad, (state) => {
    state.inProcess = true;
    state.order = undefined;
  });

  builder.addCase(orderLoadResponse, (state, action) => {
    state.inProcess = false;
    state.order = action.payload.order;
    state.order?.review?.products.sort((a, b) =>
      a.product.uuid.localeCompare(b.product.uuid),
    );
  });

  builder.addCase(orderSetOrderStatusCode, (state) => {
    state.inProcess = true;
  });

  builder.addCase(reviewSetStatusCode, (state) => {
    state.inProcess = true;
  });

  builder.addCase(reviewSentToCustomer, (state) => {
    state.inProcess = true;
  });

  builder.addCase(saveOrderTags, (state) => {
    state.inProcess = true;
  });
});
