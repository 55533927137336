import { EditOutlined } from '@ant-design/icons';
import { type RootState } from 'admin/react/reducers';
import { Col, Form, Input, Row } from 'antd';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { downloaderAddWarehouseProduct } from '../../reducers/downloader/actions';
import { IDownloaderState } from '../../reducers/downloader/types';

interface IForm {
  name: string;
}

function DownloaderAddWarehouseProduct({
  dispatch,
  Downloader,
}: {
  dispatch: Dispatch;
  Downloader: IDownloaderState;
}): JSX.Element {
  const [form] = Form.useForm<IForm>();

  const onFinish = useCallback(
    (values: IForm): void => {
      if (!Downloader.warehouse.product?.uuid) {
        return;
      }

      dispatch(
        downloaderAddWarehouseProduct(
          Downloader.warehouse.product.uuid,
          values.name,
        ),
      );
    },
    [Downloader.warehouse.product?.uuid, dispatch],
  );

  if (!Downloader.warehouse.product) {
    return <>---</>;
  }

  return (
    <Form form={form} onFinish={(values) => onFinish(values)}>
      <Row gutter={[0, 24]}>
        <Col span={8} offset={8} className="gutter-row">
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
              },
              {
                type: 'string',
                min: 3,
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Create new product with name"
              prefix={<EditOutlined />}
              type="string"
              disabled={Downloader.inProcess}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

const mapStateToProps = ({ Downloader }: RootState) => ({ Downloader });

export default connect(mapStateToProps)(DownloaderAddWarehouseProduct);
