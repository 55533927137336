import { createReducer } from '@reduxjs/toolkit';

import { type IConfigState } from './types';

export const preloadedState: IConfigState = {
  cdn: '',
  url: '',
};

export default createReducer(preloadedState, () => {});
