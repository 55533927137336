import {
  type IAdminProductDetail,
  type IAdminWarehouse,
  type IBrand,
  type IDimensions,
  type IPhoto,
  type ITranslation,
  type TPropertiesProductSave,
} from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum PRODUCT_ACTION {
  ASSIGN_RELATED_PRODUCTS = 'PRODUCT_ASSIGN_RELATED_PRODUCTS',
  ASSIGN_RELATED_PRODUCTS_RESPONSE = 'PRODUCT_ASSIGN_RELATED_PRODUCTS_RESPONSE',
  BRAND_SET = 'PRODUCT_BRAND_SET',
  BRAND_SET_RESPONSE = 'PRODUCT_BRAND_SET_RESPONSE',
  COPY_DESCRIPTIONS = 'PRODUCT_COPY_DESCRIPTIONS',
  COPY_DESCRIPTIONS_RESPONSE = 'PRODUCT_COPY_DESCRIPTIONS_RESPONSE',
  CURRENCIES_PRICES_UPDATE = 'PRODUCT_CURRENCIES_PRICES_UPDATE',
  CURRENCIES_PRICES_UPDATE_RESPONSE = 'PRODUCT_CURRENCIES_PRICES_UPDATE_RESPONSE',
  DESCRIPTION_LIVE = 'PRODUCT_DESCRIPTION_LIVE',
  DIMENSIONS_UPDATE = 'PRODUCT_DIMENSIONS_UPDATE',
  DIMENSIONS_UPDATE_RESPONSE = 'PRODUCT_DIMENSIONS_UPDATE_RESPONSE',
  LOAD = 'PRODUCT_LOAD',
  LOAD_ADMIN_RESPONSE = 'PRODUCT_LOAD_ADMIN_RESPONSE',
  NAME_LIVE = 'PRODUCT_NAME_LIVE',
  NAMES_DESCRIPTIONS_SLUGS_UPDATE = 'PRODUCT_NAMES_DESCRIPTIONS_SLUGS_UPDATE',
  NAMES_DESCRIPTIONS_SLUGS_UPDATE_RESPONSE = 'PRODUCT_NAMES_DESCRIPTIONS_SLUGS_UPDATE_RESPONSE',
  PHOTO_DELETE = 'PRODUCT_PHOTO_DELETE',
  PHOTO_DELETE_RESPONSE = 'PRODUCT_PHOTO_DELETE_RESPONSE',
  PHOTO_DOWNLOAD_FROM_URLS = 'PRODUCT_PHOTO_DOWNLOAD_FROM_URLS',
  PHOTO_DOWNLOAD_FROM_URLS_RESPONSE = 'PRODUCT_PHOTO_DOWNLOAD_FROM_URLS_RESPONSE',
  PHOTO_POSITION_CHANGE = 'PRODUCT_PHOTO_POSITION_CHANGE',
  PHOTO_POSITION_SAVE = 'PRODUCT_PHOTO_POSITION_SAVE',
  PHOTO_POSITION_SAVE_RESPONSE = 'PRODUCT_PHOTO_POSITION_SAVE_RESPONSE',
  PRODUCTS_FIND_IN_ELASTIC_SEARCH = 'PRODUCT_PRODUCTS_FIND_IN_ELASTIC_SEARCH',
  PRODUCTS_FIND_IN_ELASTIC_SEARCH_RESPONSE = 'PRODUCT_PRODUCTS_FIND_IN_ELASTIC_SEARCH_RESPONSE',
  PROPERTIES_SAVE = 'PRODUCT_PROPERTIES_SAVE',
  PROPERTIES_SAVE_RESPONSE = 'PRODUCT_PROPERTIES_SAVE_RESPONSE',
  RECALCULATED_PRICE = 'PRODUCT_RECALCULATED_PRICE',
  SAVE_CATEGORIES = 'PRODUCT_SAVE_CATEGORIES',
  SAVE_CATEGORIES_RESPONSE = 'PRODUCT_SAVE_CATEGORIES_RESPONSE',
  SLUG_LIVE = 'PRODUCT_SLUG_LIVE',
  STOCK_UPDATE = 'PRODUCT_STOCK_UPDATE',
  STOCK_UPDATE_RESPONSE = 'PRODUCT_STOCK_UPDATE_RESPONSE',
  SET_ACTIVE = 'PRODUCT_SET_ACTIVE',
}

export enum ProductPhotoPositionChange {
  DOWN = 1,
  UP = -1,
}

export interface ICurrency {
  name: string;
  symbol: string;
  uuid?: string;
  price: number;
  isoCode: string;
  isSymbolBeforePrice: boolean;
}

export interface ICurrencyPrices {
  isoCode: string;
  price: number;
}

export interface IWarehouse {
  uuid: string;
  url: string;
  count: number;
  preOrderDays: number;
  isSyncActive: boolean;
}

export interface IBrandSet extends UnknownAction {
  type: PRODUCT_ACTION.BRAND_SET;
  payload: {
    product: {
      uuid: string;
    };
    brand: IBrand;
    mpn: string;
    gtin: string;
  };
}

export interface IBrandSetResponse extends UnknownAction {
  type: PRODUCT_ACTION.BRAND_SET_RESPONSE;
  payload: {
    updated: boolean;
  };
}

export interface IBrandSetCallback {
  (arg0: IBrandSetResponse): void;
}

export interface ICopyDescriptions extends UnknownAction {
  type: PRODUCT_ACTION.COPY_DESCRIPTIONS;
  payload: {
    product: {
      uuid: string;
      sourceUUID: string;
    };
  };
}

export interface ICopyDescriptionsResponse extends UnknownAction {
  type: PRODUCT_ACTION.COPY_DESCRIPTIONS_RESPONSE;
  payload: {
    product: IAdminProductDetail | null;
  };
}

export interface ICopyDescriptionsCallback {
  (arg0: ICopyDescriptionsResponse): void;
}

export interface ICurrenciesPricesUpdate extends UnknownAction {
  type: PRODUCT_ACTION.CURRENCIES_PRICES_UPDATE;
  payload: {
    product: {
      uuid: string;
    };
    currencyPrices: ICurrencyPrices[];
  };
}

export interface ICurrenciesPricesUpdateResponse extends UnknownAction {
  type: PRODUCT_ACTION.CURRENCIES_PRICES_UPDATE_RESPONSE;
  payload: {
    updated: boolean;
  };
}

export interface ICurrenciesPricesUpdateCallback {
  (arg0: ICurrenciesPricesUpdateResponse): void;
}

export interface IDimensionsUpdate extends UnknownAction {
  type: PRODUCT_ACTION.DIMENSIONS_UPDATE;
  payload: {
    product: {
      uuid: string;
    };
    dimensions: IDimensions;
  };
}

export interface IDimensionsUpdateResponse extends UnknownAction {
  type: PRODUCT_ACTION.DIMENSIONS_UPDATE_RESPONSE;
  payload: {
    updated: boolean;
  };
}

export interface IDimensionsUpdateCallback {
  (arg0: IDimensionsUpdateResponse): void;
}

export interface ILoad extends UnknownAction {
  type: PRODUCT_ACTION.LOAD;
  payload: {
    product: {
      uuid: string;
    };
  };
}

export interface ILoadAdminResponse extends UnknownAction {
  type: PRODUCT_ACTION.LOAD_ADMIN_RESPONSE;
  payload: {
    product: IAdminProductDetail | null;
  };
}

export interface ILoadAdminCallback {
  (arg0: ILoadAdminResponse): void;
}

export interface IProductLanguageUpdate extends UnknownAction {
  type: PRODUCT_ACTION.NAMES_DESCRIPTIONS_SLUGS_UPDATE;
  payload: {
    names: ITranslation[];
    descriptions: ITranslation[];
    slugs: ITranslation[];
    product: {
      uuid: string;
    };
  };
}

export interface IProductLanguageUpdateResponse extends UnknownAction {
  type: PRODUCT_ACTION.NAMES_DESCRIPTIONS_SLUGS_UPDATE_RESPONSE;
  payload: {
    updated: boolean;
  };
}

export interface IProductLanguageUpdateCallback {
  (arg0: IProductLanguageUpdateResponse): void;
}

export interface IPhotoDelete extends UnknownAction {
  type: PRODUCT_ACTION.PHOTO_DELETE;
  payload: {
    product: { uuid: string };
    photo: { uuid: string };
  };
}

export interface IPhotoDeleteResponse extends UnknownAction {
  type: PRODUCT_ACTION.PHOTO_DELETE_RESPONSE;
  payload: {
    photo: { uuid: string };
  };
}

export interface IPhotoDeleteCallback {
  (arg0: IPhotoDeleteResponse): void;
}

export interface IPhotoDownloadFromUrls extends UnknownAction {
  type: PRODUCT_ACTION.PHOTO_DOWNLOAD_FROM_URLS;
  payload: {
    product: {
      uuid: string;
    };
    urls: string[];
  };
}

export interface IPhotoDownloadFromUrlsResponse extends UnknownAction {
  type: PRODUCT_ACTION.PHOTO_DOWNLOAD_FROM_URLS_RESPONSE;
  payload: {
    saved: boolean;
  };
}

export interface IPhotoDownloadFromUrlsCallback {
  (arg0: IPhotoDownloadFromUrlsResponse): void;
}

export interface IPhotoPositionChange extends UnknownAction {
  type: PRODUCT_ACTION.PHOTO_POSITION_CHANGE;
  payload: {
    photo: {
      uuid: string;
    };
    direction: ProductPhotoPositionChange;
  };
}

export interface IPhotoPositionSave extends UnknownAction {
  type: PRODUCT_ACTION.PHOTO_POSITION_SAVE;
  payload: {
    product: { uuid: string };
    photos: IPhoto[];
  };
}

export interface IPhotoPositionSaveResponse extends UnknownAction {
  type: PRODUCT_ACTION.PHOTO_POSITION_SAVE_RESPONSE;
  payload: {
    saved: boolean;
  };
}

export interface IPhotoPositionSaveCallback {
  (arg0: IPhotoPositionSaveResponse): void;
}

export interface ISaveCategories extends UnknownAction {
  type: PRODUCT_ACTION.SAVE_CATEGORIES;
  payload: {
    product: {
      uuid: string;
    };
    categories: {
      uuids: string[];
    };
  };
}

export interface ISaveCategoriesResponse extends UnknownAction {
  type: PRODUCT_ACTION.SAVE_CATEGORIES_RESPONSE;
  payload: {
    saved: boolean;
  };
}

export interface ISaveCategoriesCallback {
  (arg0: ISaveCategoriesResponse): void;
}

export interface IStockUpdate extends UnknownAction {
  type: PRODUCT_ACTION.STOCK_UPDATE;
  payload: {
    product: {
      uuid: string;
      mpn?: string;
    };
    nextDeliveryDate?: Date;
    warehouses: IAdminWarehouse[];
  };
}

export interface IStockUpdateResponse extends UnknownAction {
  type: PRODUCT_ACTION.STOCK_UPDATE_RESPONSE;
  payload: {
    updated: boolean;
  };
}

export interface IStockUpdateCallback {
  (arg0: IStockUpdateResponse): void;
}

export interface IAssignRelatedProducts extends UnknownAction {
  type: PRODUCT_ACTION.ASSIGN_RELATED_PRODUCTS;
  payload: {
    product: {
      uuid: string;
      relatedUUIDs: string[];
    };
  };
}

export interface IAssignRelatedProductsResponse extends UnknownAction {
  type: PRODUCT_ACTION.ASSIGN_RELATED_PRODUCTS_RESPONSE;
  payload: {
    saved: boolean;
  };
}

export interface IAssignRelatedProductsCallback {
  (arg0: IAssignRelatedProductsResponse): void;
}

export interface IPropertiesSave extends UnknownAction {
  type: PRODUCT_ACTION.PROPERTIES_SAVE;
  payload: {
    product: {
      uuid: string;
    };
    productProperties: TPropertiesProductSave;
  };
}

export interface IPropertiesSaveResponse extends UnknownAction {
  type: PRODUCT_ACTION.PROPERTIES_SAVE_RESPONSE;
  payload: {
    saved: boolean;
  };
}

export interface IPropertiesSaveCallback {
  (arg0: IPropertiesSaveResponse): void;
}

export interface INameLive extends UnknownAction {
  type: PRODUCT_ACTION.NAME_LIVE;
  payload: {
    name: string;
    languageType: {
      code: string;
    };
  };
}

export interface IDescriptionLive extends UnknownAction {
  type: PRODUCT_ACTION.DESCRIPTION_LIVE;
  payload: {
    description: string;
    languageType: {
      code: string;
    };
  };
}

export interface ISlugLive extends UnknownAction {
  type: PRODUCT_ACTION.SLUG_LIVE;
  payload: {
    slug: string;
    languageType: {
      code: string;
    };
  };
}

export interface IRecalculatedPrice extends UnknownAction {
  type: PRODUCT_ACTION.RECALCULATED_PRICE;
  payload: {
    currencyType: {
      isoCode: string;
    };
    price: number;
  };
}

export interface IProductsFindInElasticSearch extends UnknownAction {
  type: PRODUCT_ACTION.PRODUCTS_FIND_IN_ELASTIC_SEARCH;
  payload: {
    languageType: {
      code: string;
    };
    query: string;
  };
}

export interface IProductsFindInElasticSearchResponse extends UnknownAction {
  type: PRODUCT_ACTION.PRODUCTS_FIND_IN_ELASTIC_SEARCH_RESPONSE;
  payload: {
    productUUIDs: string[];
  };
}

export interface IProductsFindInElasticSearchCallback {
  (arg0: IProductsFindInElasticSearchResponse): void;
}

export interface IProductSetActive extends UnknownAction {
  type: PRODUCT_ACTION.SET_ACTIVE;
  payload: {
    product: {
      uuid: string;
    };
    isActive: boolean;
  };
}

export interface IProductState extends IAdminProductDetail {
  inProcess: boolean;
  relatedProductsFromElasticSearch: string[] | undefined;
  updatedAt?: number;
}
