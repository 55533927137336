import { type IBrand } from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum BRAND_ACTION {
  ADD = 'BRAND_ADD',
  ADD_RESPONSE = 'BRAND_ADD_RESPONSE',
  LOAD_ALL = 'BRAND_LOAD_ALL',
  LOAD_ALL_RESPONSE = 'BRAND_LOAD_ALL_RESPONSE',
}

export interface IAdd extends UnknownAction {
  type: BRAND_ACTION.ADD;
  payload: {
    name: string;
  };
}

export interface ILoadAll extends UnknownAction {
  type: BRAND_ACTION.LOAD_ALL;
}

export interface ILoadAllResponse extends UnknownAction {
  type: BRAND_ACTION.LOAD_ALL_RESPONSE;
  payload: {
    brands: IBrand[];
  };
}

export interface ILoadAllCallback {
  (arg0: ILoadAllResponse): void;
}

export interface IBrandState {
  inProcess: boolean;
  brands?: IBrand[];
  updatedAt?: number;
}
