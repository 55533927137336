import { ReloadOutlined } from '@ant-design/icons';
import { type IReportWarehouseProductToImport } from '@mahawi/eshop-common/dist/src/types';
import { Button, Divider, Input, Space, Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { Key, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import Link from '../components/link';
import Table from '../components/table';
import { type RootState } from '../reducers';
import { reportWarehouseProductToImportLoad } from '../reducers/report/actions';
import { type IReportState } from '../reducers/report/types';

const { Title } = Typography;
const { Text } = Typography;

interface DataType extends IReportWarehouseProductToImport {
  key: string;
}

function ReportWarehouseProductToImportContainer({
  dispatch,
  Report,
}: {
  dispatch: Dispatch;
  Report: IReportState;
}): JSX.Element {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);
  const [minimalWeight, setMinimalWeight] = useState<number>(0.001);
  const [maximalWeight, setMaximalWeight] = useState<number>(30);
  const [minimalPrice, setMinimalPrice] = useState<number>(0);
  const [maximalPrice, setMaximalPrice] = useState<number>(60000);

  useEffect((): void => {
    if (Report.warehouseProductToImport === null) {
      setDataSource([]);
      return;
    }

    const dataSourceUE: DataType[] = [];
    const productsName = new Set<string>([]);
    const warehousesName = new Set<string>([]);
    const brandsName = new Set<string>([]);

    Report.warehouseProductToImport?.forEach(
      (product: IReportWarehouseProductToImport): void => {
        warehousesName.add(product.warehouse.name);
        productsName.add(product.name);
        brandsName.add(product.brand);

        dataSourceUE.push({
          key: product.uuid,
          ...product,
        });
      },
    );

    setDataSource(dataSourceUE);

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Warehouse',
        dataIndex: 'warehouse',
        sorter: (a: DataType, b: DataType): number =>
          a.warehouse.name.localeCompare(b.warehouse.name),
        filterSearch: true,
        filters: [...warehousesName]
          .map((warehouse: string) => ({
            text: warehouse,
            value: warehouse,
          }))
          .sort((a, b): number => a.text.localeCompare(b.text)),
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.warehouse.name === value,
        render: (warehouse): string => warehouse.name,
        width: '10%',
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        sorter: (a: DataType, b: DataType): number =>
          a.brand.localeCompare(b.brand),
        filterSearch: true,
        filters: [...brandsName]
          .map((brand: string) => ({
            text: brand,
            value: brand,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.brand === value,
        width: '10%',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: DataType, b: DataType): number =>
          a.name.localeCompare(b.name),
        filterSearch: true,
        filters: [...productsName]
          .map((product: string) => ({
            text: product,
            value: product,
          }))
          .sort((a, b): number => a.text.localeCompare(b.text)),
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.name === value,
        render: (name, record: DataType): JSX.Element => (
          <Link
            to={`/downloader/warehouse-product/${record.uuid}`}
            label={name}
            openInNewTab
          />
        ),
        width: '40%',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        sorter: {
          compare: (a: DataType, b: DataType): number => a.price - b.price,
          multiple: 100,
        },
        width: '10%',
      },
      {
        title: 'Stock',
        dataIndex: 'count',
        sorter: {
          compare: (a: DataType, b: DataType): number => a.count - b.count,
          multiple: 3,
        },
        filterSearch: true,
        filters: [
          {
            text: 'In stock',
            value: 'inStock',
          },
          {
            text: 'Full stock',
            value: 'fullStock',
          },
          {
            text: 'Low stock',
            value: 'lowStock',
          },
        ],
        onFilter: (value: boolean | Key, record: DataType): boolean => {
          if (value === 'inStock') {
            return record.count > 0;
          }

          if (value === 'fullStock') {
            return record.count >= 5;
          }

          if (value === 'lowStock') {
            return record.count > 0 && record.count < 5;
          }

          return false;
        },
        render: (value: number) => {
          if (value >= 5) {
            return (
              <Text strong type="success">
                {value}
              </Text>
            );
          }
          if (value >= 2) {
            return (
              <Text strong type="warning">
                {value}
              </Text>
            );
          }

          return (
            <Text strong type="danger">
              {value}
            </Text>
          );
        },
        width: '10%',
      },
      {
        title: 'MPN',
        dataIndex: 'mpn',
        sorter: (a: DataType, b: DataType): number =>
          a.mpn.localeCompare(b.mpn),
        filterSearch: true,
        onFilter: (value: boolean | Key, record: DataType): boolean =>
          record.mpn === value,
        width: '10%',
      },
      {
        title: 'GTIN',
        dataIndex: 'gtin',
        sorter: (a: DataType, b: DataType): number =>
          a.gtin.localeCompare(b.gtin),
        filterSearch: true,
        onFilter: (value: boolean | Key, record: DataType) =>
          record.gtin === value,
        width: '10%',
      },
      {
        title: 'Weight',
        dataIndex: 'dimensions',
        sorter: {
          compare: (a: DataType, b: DataType): number =>
            a.dimensions.weight - b.dimensions.weight,
          multiple: 3,
        },
        render: (dimensions): string => `${dimensions.weight} kg`,
        width: '10%',
      },
    ];

    setColumns(columnsUE);
  }, [Report.warehouseProductToImport, Report.updatedAt]);

  return (
    <>
      <Typography>
        <Title level={2}>Report warehouse product to import</Title>
      </Typography>

      <Divider />

      <Space size={16}>
        <Space size={16} direction="vertical">
          <Input
            type="number"
            step="0.001"
            addonBefore="Minimal weight"
            value={minimalWeight}
            addonAfter="kg"
            onChange={(event) => {
              setMinimalWeight(Number(event.target.value));
            }}
          />

          <Input
            type="number"
            step="0.001"
            addonBefore="Maximal weight"
            value={maximalWeight}
            addonAfter="kg"
            onChange={(event) => {
              setMaximalWeight(Number(event.target.value));
            }}
          />
        </Space>

        <Space size={16} direction="vertical">
          <Input
            type="number"
            step="0.001"
            addonBefore="Minimal price"
            value={minimalPrice}
            addonAfter="CZK"
            onChange={(event) => {
              setMinimalPrice(Number(event.target.value));
            }}
          />

          <Input
            type="number"
            step="0.001"
            addonBefore="Maximal price"
            value={maximalPrice}
            addonAfter="CZK"
            onChange={(event) => {
              setMaximalPrice(Number(event.target.value));
            }}
          />
        </Space>

        <Space size={16} direction="vertical">
          <Button
            type="primary"
            onClick={() => {
              dispatch(
                reportWarehouseProductToImportLoad(
                  minimalWeight,
                  maximalWeight,
                  minimalPrice,
                  maximalPrice,
                ),
              );
            }}
          >
            <ReloadOutlined />
          </Button>
        </Space>
      </Space>

      <Divider />

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Report.inProcess}
      />
    </>
  );
}

const mapStateToProps = ({ Report }: RootState): { Report: IReportState } => ({
  Report,
});

export default connect(mapStateToProps)(
  ReportWarehouseProductToImportContainer,
);
