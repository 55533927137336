import { EBAY_ACTION, type IEbayState, type TEbayActionTypes } from './types';

export const preloadedState: IEbayState = {
  aspects: {},
  categories: {},
  categorySuggestions: {},
  fulfillmentPolicies: {},
  inProcess: false,
  inventoryItems: {},
  marketplace: undefined,
  marketplaces: [],
  marketplacesTimestamp: 0,
  offers: {},
  paymentPolicies: {},
  productEbay: {},
  returnPolicies: {},
  userUrl: undefined,
};

export default function EbayReducer(
  prevState: IEbayState | undefined,
  action: TEbayActionTypes,
): IEbayState {
  let state: IEbayState | undefined = prevState;

  if (!state) {
    state = preloadedState;
  }

  switch (action.type) {
    case EBAY_ACTION.CATEGORY_LOAD:
    case EBAY_ACTION.CATEGORY_SAVE_ASPECTS:
    case EBAY_ACTION.CATEGORY_SUGGESTIONS:
    case EBAY_ACTION.INVENTORY_ITEM_LOAD:
    case EBAY_ACTION.INVENTORY_ITEM_PUT:
    case EBAY_ACTION.MARKETPLACE_SET_CURRENCY:
    case EBAY_ACTION.MARKETPLACE_SET_IS_ACTIVE:
    case EBAY_ACTION.MARKETPLACE_SET_LANGUAGE:
    case EBAY_ACTION.OFFER_POST:
    case EBAY_ACTION.OFFER_PUBLISH:
    case EBAY_ACTION.OFFER_PUT:
    case EBAY_ACTION.OFFERS_LOAD:
    case EBAY_ACTION.PRODUCT_LOAD:
    case EBAY_ACTION.PRODUCT_SAVE:
    case EBAY_ACTION.USER_URL:
      return { ...state, inProcess: true };

    case EBAY_ACTION.CATEGORY_LOAD_ASPECTS:
      return { ...state, inProcess: true, aspects: {} };

    case EBAY_ACTION.CATEGORY_SAVE_ASPECTS_RESPONSE:
      return { ...state, inProcess: false };

    case EBAY_ACTION.MARKETPLACE_LOAD:
      return { ...state, inProcess: true, marketplace: undefined };

    case EBAY_ACTION.MARKETPLACE_LOAD_RESPONSE:
      return { ...state, inProcess: false, marketplace: action.marketplace };

    case EBAY_ACTION.MARKETPLACES_LOAD:
      return {
        ...state,
        inProcess: true,
        marketplaces: [],
        marketplacesTimestamp: Date.now(),
        offers: {},
        inventoryItems: {},
      };

    case EBAY_ACTION.MARKETPLACES_LOAD_RESPONSE:
      return {
        ...state,
        inProcess: false,
        marketplaces: action.marketplaces,
        marketplacesTimestamp: Date.now(),
        offers: {},
        inventoryItems: {},
      };

    case EBAY_ACTION.MARKETPLACE_SET_CURRENCY_RESPONSE:
    case EBAY_ACTION.MARKETPLACE_SET_IS_ACTIVE_RESPONSE:
    case EBAY_ACTION.MARKETPLACE_SET_LANGUAGE_RESPONSE:
      return { ...state, inProcess: false, marketplace: action.marketplace };

    case EBAY_ACTION.CATEGORY_SUGGESTIONS_RESPONSE:
      if (action.categorySuggestions) {
        state.categorySuggestions[action.ebayMarketplace.code] =
          action.categorySuggestions;
      }

      return {
        ...state,
        inProcess: false,
      };

    case EBAY_ACTION.CATEGORY_LOAD_RESPONSE:
      if (!state.categories[action.ebayMarketplace.code]) {
        state.categories[action.ebayMarketplace.code] = {};
      }

      if (action.categories) {
        state.categories[action.ebayMarketplace.code][action.category.uuid] =
          action.categories;
      }

      return { ...state, inProcess: false };

    case EBAY_ACTION.USER_URL_RESPONSE:
      return { ...state, inProcess: false, userUrl: action.url };

    case EBAY_ACTION.MARKETPLACE_LOAD_FULFILLMENT_POLICIES_RESPONSE:
      if (action.fulfillmentPolicies) {
        state.fulfillmentPolicies[action.ebayMarketplace.code] =
          action.fulfillmentPolicies;
      }

      return { ...state };

    case EBAY_ACTION.MARKETPLACE_LOAD_PAYMENT_POLICIES_RESPONSE:
      state.paymentPolicies[action.ebayMarketplace.code] =
        action.paymentPolicies;

      return { ...state };

    case EBAY_ACTION.MARKETPLACE_LOAD_RETURN_POLICIES_RESPONSE:
      state.returnPolicies[action.ebayMarketplace.code] = action.returnPolicies;

      return { ...state };

    case EBAY_ACTION.PRODUCT_SAVE_RESPONSE:
    case EBAY_ACTION.PRODUCT_LOAD_RESPONSE:
      state.productEbay[action.ebayMarketplace.code] = action.productEbay;

      return { ...state, inProcess: false };

    case EBAY_ACTION.INVENTORY_ITEM_LOAD_RESPONSE:
    case EBAY_ACTION.INVENTORY_ITEM_PUT_RESPONSE:
      if (action.response) {
        state.inventoryItems[action.ebayMarketplace.code] = action.response;
      }

      return { ...state, inProcess: false };

    case EBAY_ACTION.OFFERS_LOAD_RESPONSE:
    case EBAY_ACTION.OFFER_POST_RESPONSE:
    case EBAY_ACTION.OFFER_PUBLISH_RESPONSE:
    case EBAY_ACTION.OFFER_PUT_RESPONSE:
      if (action.response) {
        state.offers[action.ebayMarketplace.code] = action.response;
      }

      return { ...state, inProcess: false };

    case EBAY_ACTION.CATEGORY_LOAD_ASPECTS_RESPONSE:
      state.aspects[action.ebayMarketplace.code] = action.ebayAspects;
      return { ...state, inProcess: false };

    case EBAY_ACTION.OFFER_CLEAR_ALL_RESPONSES:
      return { ...state, offers: {} };

    case EBAY_ACTION.INVENTORY_ITEMS_CLEAR_ALL_RESPONSES:
      return { ...state, inventoryItems: {} };

    default:
      return { ...state };
  }
}
