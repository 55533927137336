import { type IMessage } from '@mahawi/eshop-common/dist/src/types';
import { createReducer } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import {
  customerSendMessage,
  customerTick,
  leadBasketProducts,
  leadSessions,
  loadLeads,
  loadLeadsResponse,
  sendMessage,
  sendMessageResponse,
  setActiveLead,
  setActiveLeadResponse,
} from './actions';
import { type IMessengerState, type LeadMessenger } from './types';

export const preloadedState: IMessengerState = {
  inProcess: false,
  leads: [],
  lead: {
    uuid: undefined,
  },
  messages: [],
  sessions: undefined,
  basketProducts: undefined,
  message: '',
  lastMessage: {
    uuid: '',
  },
  updatedAt: undefined,
};

const sortLeads = (leads: LeadMessenger[]): LeadMessenger[] => {
  return leads.sort((a: LeadMessenger, b: LeadMessenger): number => {
    if (a.isActive && !b.isActive) {
      return -1;
    }
    if (!a.isActive && b.isActive) {
      return 1;
    }
    if (a.isActive && b.isActive) {
      return b.createdAt - a.createdAt;
    }
    if (a.unread && !b.unread) {
      return -1;
    }
    if (!a.unread && b.unread) {
      return 1;
    }

    return b.lastActivity - a.lastActivity;
  });
};

const sortMessages = (messages: IMessage[]): IMessage[] =>
  messages.sort(
    (a: IMessage, b: IMessage): number => b.timestamp - a.timestamp,
  );

const addLead = (state: IMessengerState, leadUUID: string): void => {
  if (!state.leads) {
    return;
  }

  const lead: LeadMessenger | undefined = state.leads.find(
    (l: LeadMessenger): boolean => l.uuid === leadUUID,
  );

  if (lead) {
    return;
  }

  state.leads.push({
    uuid: leadUUID,
    unread: 0,
    lastActivity: dayjs().unix(),
    tick: dayjs().unix(),
    createdAt: dayjs().unix(),
  });

  state.leads = sortLeads(state.leads);
};

const setLeadUnread = (
  state: IMessengerState,
  leadUUID: string,
  unread: number,
): void => {
  if (!state.leads) {
    return;
  }

  const lead: LeadMessenger | undefined = state.leads.find(
    (l: LeadMessenger): boolean => l.uuid === leadUUID,
  );

  if (lead) {
    if (lead.unread && lead.unread >= 0) {
      if (unread === 0) {
        lead.unread = 0;
      } else {
        lead.unread += unread;
      }
    } else {
      lead.unread = unread;
    }
  } else {
    addLead(state, leadUUID);
    setLeadUnread(state, leadUUID, unread);
  }
};

const setLeadLastActivity = (
  state: IMessengerState,
  leadUUID: string,
  timestamp: number,
  isActive = false,
): void => {
  if (!state.leads) {
    return;
  }

  const lead: LeadMessenger | undefined = state.leads.find(
    (l: LeadMessenger): boolean => l.uuid === leadUUID,
  );

  if (lead) {
    lead.tick = timestamp;
    lead.lastActivity = lead.tick;
    lead.isActive = isActive;
  }
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(loadLeads, (state) => {
    state.inProcess = true;
    state.leads = undefined;
  });

  builder.addCase(loadLeadsResponse, (state, action) => {
    state.inProcess = false;
    state.leads = sortLeads(action.payload.leads);
    state.updatedAt = Date.now();
  });

  builder.addCase(setActiveLead, (state, action) => {
    setLeadUnread(state, action.payload.lead.uuid, 0);

    state.inProcess = true;
    state.messages = [];
    state.sessions = undefined;
    state.basketProducts = undefined;
    state.lead = { uuid: action.payload.lead.uuid };
    state.message = '';
  });

  builder.addCase(setActiveLeadResponse, (state, action) => {
    state.inProcess = false;
    state.messages = sortMessages(action.payload.messages);
    state.updatedAt = Date.now();
  });

  builder.addCase(sendMessage, (state, action) => {
    state.inProcess = true;
    state.message = action.payload.message;
  });

  builder.addCase(sendMessageResponse, (state, action) => {
    if (action.payload.message) {
      state.messages.push(action.payload.message);
    }

    state.inProcess = false;
    state.message = '';
    state.messages = sortMessages(state.messages);
    state.updatedAt = Date.now();
  });

  builder.addCase(customerSendMessage, (state, action) => {
    state.updatedAt = Date.now();

    addLead(state, action.payload.lead.uuid);
    setLeadLastActivity(
      state,
      action.payload.lead.uuid,
      action.payload.message.timestamp,
      true,
    );

    if (action.payload.message.message === '*** OPENED NEW SESSION ***') {
      return;
    }

    if (state.lead.uuid === action.payload.lead.uuid) {
      state.messages.push(action.payload.message);
      sortMessages(state.messages);
    }

    setLeadUnread(state, action.payload.lead.uuid, 1);

    state.lastMessage = {
      uuid: action.payload.message.uuid,
    };
  });

  builder.addCase(leadSessions, (state, action) => {
    if (state.lead.uuid === action.payload.lead.uuid) {
      state.sessions = action.payload.sessions;
    }

    state.updatedAt = Date.now();
  });

  builder.addCase(leadBasketProducts, (state, action) => {
    if (state.lead.uuid === action.payload.lead.uuid) {
      state.basketProducts = action.payload.basketProducts;
    }
  });

  builder.addCase(customerTick, (state, action) => {
    setLeadLastActivity(
      state,
      action.payload.lead.uuid,
      action.payload.timestamp,
      action.payload.event.type === 'UPDATE',
    );

    state.updatedAt = Date.now();

    if (state.leads) {
      state.leads = sortLeads(state.leads);
    }
  });
});
