import { type IProduct, IProductNames } from '@mahawi/eshop-common';
import { createAction } from '@reduxjs/toolkit';

import { PRODUCTS_ACTION } from './types';

export const productAdd = createAction(
  PRODUCTS_ACTION.ADD,
  (name: string, code: string, productDownloadedUUID?: string) => ({
    payload: {
      name,
      productDownloaded: {
        uuid: productDownloadedUUID,
      },
      languageType: {
        code,
      },
    },
  }),
);

export const productAddResponse = createAction(
  PRODUCTS_ACTION.ADD_RESPONSE,
  (product: IProduct | null) => ({
    payload: {
      product,
    },
  }),
);

export const productsLoadAll = createAction(
  PRODUCTS_ACTION.LOAD_ALL,
  (isActiveProducts: boolean) => ({
    payload: {
      isActiveProducts,
    },
  }),
);

export const productsLoadAllResponse = createAction(
  PRODUCTS_ACTION.LOAD_ALL_RESPONSE,
  (products: IProduct[]) => ({
    payload: {
      products,
    },
  }),
);

export const productsLoadAllShort = createAction(
  PRODUCTS_ACTION.LOAD_ALL_SHORT,
);

export const productsLoadAllShortResponse = createAction(
  PRODUCTS_ACTION.LOAD_ALL_SHORT_RESPONSE,
  (products: IProductNames[]) => ({
    payload: {
      products,
    },
  }),
);

export const productsLoadByCategory = createAction(
  PRODUCTS_ACTION.LOAD_BY_CATEGORY,
  (category: string) => ({
    payload: {
      category,
    },
  }),
);

export const productsLoadByCategoryResponse = createAction(
  PRODUCTS_ACTION.LOAD_BY_CATEGORY_RESPONSE,
  (products: IProduct[]) => ({
    payload: {
      products,
    },
  }),
);

export const productsGetByMPN = createAction(
  PRODUCTS_ACTION.GET_BY_MPN,
  (mpn: string) => ({
    payload: {
      mpn,
    },
  }),
);

export const productsGetByMPNResponse = createAction(
  PRODUCTS_ACTION.GET_BY_MPN_RESPONSE,
  (products: IProduct[]) => ({
    payload: {
      products,
    },
  }),
);

export const productsGetByGTIN = createAction(
  PRODUCTS_ACTION.GET_BY_GTIN,
  (gtin: string) => ({
    payload: {
      gtin,
    },
  }),
);

export const productsGetByGTINResponse = createAction(
  PRODUCTS_ACTION.GET_BY_GTIN_RESPONSE,
  (products: IProduct[]) => ({
    payload: {
      products,
    },
  }),
);
