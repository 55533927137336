import {
  EOrderStatus,
  type IOrderByOrderStatusTyperCode,
} from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum ORDERS_ACTION {
  LOAD = 'ORDERS_ACTION_LOAD_ORDERS',
  LOAD_RESPONSE = 'ORDERS_ACTION_LOAD_ORDERS_RESPONSE',
  LOAD_ALL_ORDER_STATUS_TYPE_CODES = 'ORDERS_ACTION_LOAD_ALL_ORDER_STATUS_TYPE_CODES',
  LOAD_ALL_ORDER_STATUS_TYPE_CODES_RESPONSE = 'ORDERS_ACTION_LOAD_ALL_ORDER_STATUS_TYPE_CODES_RESPONSE',
  LOAD_ALL_REVIEW_STATUS_TYPE_CODES = 'ORDERS_ACTION_LOAD_ALL_REVIEW_STATUS_TYPE_CODES',
  LOAD_ALL_REVIEW_STATUS_TYPE_CODES_RESPONSE = 'ORDERS_ACTION_LOAD_ALL_REVIEW_STATUS_TYPE_CODES_RESPONSE',
}

export interface ILoad extends UnknownAction {
  type: ORDERS_ACTION.LOAD;
  payload: {
    orderStatusType: {
      codes: EOrderStatus[];
    };
  };
}

export interface ILoadResponse extends UnknownAction {
  type: ORDERS_ACTION.LOAD_RESPONSE;
  payload: {
    orders: IOrderByOrderStatusTyperCode[];
  };
}

export interface ILoadCallback {
  (arg0: ILoadResponse): void;
}

export interface ILoadAllOrderStatusTypeCodes extends UnknownAction {
  type: ORDERS_ACTION.LOAD_ALL_ORDER_STATUS_TYPE_CODES;
}

export interface ILoadAllOrderStatusTypeCodesResponse extends UnknownAction {
  type: ORDERS_ACTION.LOAD_ALL_ORDER_STATUS_TYPE_CODES_RESPONSE;
  payload: {
    statusTypeCodes: string[];
  };
}

export interface ILoadAllOrderStatusTypeCodesResponseCallback {
  (arg0: ILoadAllOrderStatusTypeCodesResponse): void;
}

export interface ILoadAllReviewStatusTypeCodes extends UnknownAction {
  type: ORDERS_ACTION.LOAD_ALL_REVIEW_STATUS_TYPE_CODES;
}

export interface ILoadAllReviewStatusTypeCodesResponse extends UnknownAction {
  type: ORDERS_ACTION.LOAD_ALL_REVIEW_STATUS_TYPE_CODES_RESPONSE;
  payload: {
    statusTypeCodes: string[];
  };
}

export interface ILoadAllReviewStatusTypeCodesResponseCallback
  extends UnknownAction {
  (arg0: ILoadAllReviewStatusTypeCodesResponse): void;
}

export interface IOrdersState {
  inProcess: boolean;
  orders?: IOrderByOrderStatusTyperCode[];
  statusTypeCodes?: string[];
  reviewStatusTypeCodes?: string[];
  updatedAt?: number;
}
