import { createReducer } from '@reduxjs/toolkit';

import {
  discountDelete,
  discountsLoad,
  discountsLoadResponse,
  discountTypesLoad,
  discountTypesLoadResponse,
} from './actions';
import { type IDiscountState } from './types';

export const preloadedState: IDiscountState = {
  inProcess: false,
  discountTypes: undefined,
  discounts: undefined,
  updatedAt: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(discountTypesLoad, (state) => {
    state.inProcess = true;
  });

  builder.addCase(discountTypesLoadResponse, (state, action) => {
    state.discountTypes = action.payload.discountTypes;
    state.updatedAt = Date.now();
    state.inProcess = false;
  });

  builder.addCase(discountsLoad, (state) => {
    state.inProcess = true;
  });

  builder.addCase(discountsLoadResponse, (state, action) => {
    state.discounts = action.payload.discounts;
    state.updatedAt = Date.now();
    state.inProcess = false;
  });

  builder.addCase(discountDelete, (state) => {
    state.inProcess = true;
  });
});
