import { type IAdminCategory } from '@mahawi/eshop-common';
import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import { type RootState } from 'admin/react/reducers';
import { FormInstance, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { type ICategoriesState } from '../../reducers/categories/types';
import { type ILanguageState } from '../../reducers/language/types';

function SelectCategoryOptionsComponent({
  Categories,
  fieldName,
  form,
  isDisabled = false,
  Language,
  mode = undefined,
}: {
  Categories: ICategoriesState;
  fieldName: string;
  form: FormInstance;
  isDisabled?: boolean;
  Language: ILanguageState;
  mode?: 'multiple' | 'tags';
}): JSX.Element {
  const [categories, setCategories] = useState<JSX.Element[]>([]);

  useEffect((): void => {
    if (!Categories.categories) {
      return;
    }

    const categoriesUE: JSX.Element[] = Categories.categories.map(
      ({ uuid, names }: IAdminCategory): JSX.Element => {
        const name: string = getTranslation(names, Language.languageType);

        return (
          <Select.Option value={uuid} key={uuid}>
            {name ?? uuid}
          </Select.Option>
        );
      },
    );

    setCategories(categoriesUE);
  }, [
    Categories.categories,
    Language.languageType?.code,
    Categories.updatedAt,
  ]);

  return (
    <Select
      value={form.getFieldValue(fieldName)}
      onChange={(value) => form.setFieldValue(fieldName, value)}
      mode={mode}
      allowClear
      showSearch
      placeholder="Select categories"
      disabled={isDisabled}
      filterOption={(input: string, option): boolean => {
        const childrenText: string = String(
          option ? option.children : '',
        ).toLowerCase();

        return childrenText.includes(input.toLowerCase()) ?? false;
      }}
      filterSort={(optionA, optionB): number => {
        const childrenAText: string = String(
          optionA ? optionA.children : '',
        ).toLowerCase();

        const childrenBText: string = String(
          optionB ? optionB.children : '',
        ).toLowerCase();

        return childrenAText.localeCompare(childrenBText);
      }}
      autoClearSearchValue={false}
    >
      {categories.map((categoryEl: JSX.Element): JSX.Element => categoryEl)}
    </Select>
  );
}

const mapStateToProps = ({ Categories, Language, Product }: RootState) => ({
  Categories,
  Language,
  Product,
});

export default connect(mapStateToProps)(SelectCategoryOptionsComponent);
