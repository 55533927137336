import { call, CallEffect, put, PutEffect } from 'redux-saga/effects';
import { Socket } from 'socket.io-client';

export interface IAction {
  type: string;
}

const sendRaw = <ACTION extends IAction, RESPONSE>(
  socket: Socket,
  action: ACTION,
): Promise<void | RESPONSE> =>
  new Promise((resolve) => {
    socket.emit(action.type, action, resolve);
  });

function* send<ACTION extends IAction>(
  socket: Socket,
  action: ACTION,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Generator<CallEffect | PutEffect, void, any> {
  const response = yield call(sendRaw, socket, action);

  yield put(response);
}

export { send, sendRaw };
