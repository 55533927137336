import {
  type IAdminWarehouseProductDetail,
  type IAdminWarehouseProductList,
  type IProduct,
  type IProductDownloaded,
  type IProductDownloadedList,
  type IWarehouseCategory,
  type IWarehouseProperty,
} from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum DOWNLOADER_ACTION {
  ADD_WAREHOUSE_PRODUCT = 'DOWNLOADER_ACTION_ADD_WAREHOUSE_PRODUCT',
  ADD_WAREHOUSE_PRODUCT_RESPONSE = 'DOWNLOADER_ACTION_ADD_WAREHOUSE_PRODUCT_RESPONSE',
  DOWNLOAD_ALL_PHOTOS_WAREHOUSE_PRODUCT = 'DOWNLOADER_ACTION_DOWNLOAD_ALL_PHOTOS_WAREHOUSE_PRODUCT',
  DOWNLOAD_ALL_PHOTOS_WAREHOUSE_PRODUCT_RESPONSE = 'DOWNLOADER_ACTION_DOWNLOAD_ALL_PHOTOS_WAREHOUSE_PRODUCT_RESPONSE',
  LOAD_PRODUCT = 'DOWNLOADER_ACTION_LOAD_PRODUCT',
  LOAD_PRODUCT_RESPONSE = 'DOWNLOADER_ACTION_LOAD_PRODUCT_RESPONSE',
  LOAD_PRODUCTS = 'DOWNLOADER_ACTION_LOAD_PRODUCTS',
  LOAD_PRODUCTS_RESPONSE = 'DOWNLOADER_ACTION_LOAD_PRODUCTS_RESPONSE',
  LOAD_WAREHOUSE_CATEGORIES = 'DOWNLOADER_ACTION_LOAD_WAREHOUSE_CATEGORIES',
  LOAD_WAREHOUSE_CATEGORIES_RESPONSE = 'DOWNLOADER_ACTION_LOAD_WAREHOUSE_CATEGORIES_RESPONSE',
  LOAD_WAREHOUSE_CATEGORY = 'DOWNLOADER_ACTION_LOAD_WAREHOUSE_CATEGORY',
  LOAD_WAREHOUSE_CATEGORY_RESPONSE = 'DOWNLOADER_ACTION_LOAD_WAREHOUSE_CATEGORY_RESPONSE',
  LOAD_WAREHOUSE_PRODUCT = 'DOWNLOADER_ACTION_LOAD_WAREHOUSE_PRODUCT',
  LOAD_WAREHOUSE_PRODUCT_RESPONSE = 'DOWNLOADER_ACTION_LOAD_WAREHOUSE_PRODUCT_RESPONSE',
  LOAD_WAREHOUSE_PRODUCTS = 'DOWNLOADER_ACTION_LOAD_WAREHOUSE_PRODUCTS',
  LOAD_WAREHOUSE_PRODUCTS_BY_WAREHOUSE_CATEGORY = 'DOWNLOADER_ACTION_LOAD_WAREHOUSE_PRODUCTS_BY_WAREHOUSE_CATEGORY',
  LOAD_WAREHOUSE_PRODUCTS_BY_WAREHOUSE_CATEGORY_RESPONSE = 'DOWNLOADER_ACTION_LOAD_WAREHOUSE_PRODUCTS_BY_WAREHOUSE_CATEGORY_RESPONSE',
  LOAD_WAREHOUSE_PRODUCTS_RESPONSE = 'DOWNLOADER_ACTION_LOAD_WAREHOUSE_PRODUCTS_RESPONSE',
  LOAD_WAREHOUSE_PROPERTIES = 'DOWNLOADER_ACTION_LOAD_WAREHOUSE_PROPERTIES',
  LOAD_WAREHOUSE_PROPERTIES_RESPONSE = 'DOWNLOADER_ACTION_LOAD_WAREHOUSE_PROPERTIES_RESPONSE',
  LOAD_WAREHOUSE_PROPERTY = 'DOWNLOADER_ACTION_LOAD_WAREHOUSE_PROPERTY',
  LOAD_WAREHOUSE_PROPERTY_RESPONSE = 'DOWNLOADER_ACTION_LOAD_WAREHOUSE_PROPERTY_RESPONSE',
  PAIR_WAREHOUSE_CATEGORY = 'DOWNLOADER_ACTION_PAIR_WAREHOUSE_CATEGORY',
  PAIR_WAREHOUSE_CATEGORY_RESPONSE = 'DOWNLOADER_ACTION_PAIR_WAREHOUSE_CATEGORY_RESPONSE',
  SYNC_WAREHOUSE_PRODUCT = 'DOWNLOADER_ACTION_SYNC_WAREHOUSE_PRODUCT',
  SYNC_WAREHOUSE_PRODUCT_RESPONSE = 'DOWNLOADER_ACTION_SYNC_WAREHOUSE_PRODUCT_RESPONSE',
  UPDATE_PRODUCT = 'DOWNLOADER_ACTION_UPDATE_PRODUCT',
  WAREHOUSE_PRODUCT_PAIR_WITH_EXISTING_PRODUCT = 'DOWNLOADER_ACTION_WAREHOUSE_PRODUCT_PAIR_WITH_EXISTING_PRODUCT',
  WAREHOUSE_PRODUCT_PAIR_WITH_EXISTING_PRODUCT_RESPONSE = 'DOWNLOADER_ACTION_WAREHOUSE_PRODUCT_PAIR_WITH_EXISTING_PRODUCT_RESPONSE',
}

export interface LoadProducts extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_PRODUCTS;
  payload: {
    lastDaysUpdatedAt: number;
  };
}

export interface LoadProductsResponse extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_PRODUCTS_RESPONSE;
  payload: {
    products: IProductDownloadedList[];
  };
}

export interface LoadProductsCallback {
  (arg0: LoadProductsResponse): void;
}

export interface LoadProduct extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_PRODUCT;
  payload: {
    productDownloaded: {
      uuid: string;
    };
  };
}

export interface LoadProductResponse extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_PRODUCT_RESPONSE;
  payload: {
    product: IProductDownloaded | null;
  };
}

export interface LoadProductCallback {
  (arg0: LoadProductResponse): void;
}

export interface UpdateProductResponse extends UnknownAction {
  type: DOWNLOADER_ACTION.UPDATE_PRODUCT;
  payload: {
    product: IProductDownloaded;
  };
}

export interface LoadWarehouseCategories extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_WAREHOUSE_CATEGORIES;
}

export interface LoadWarehouseCategoriesResponse extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_WAREHOUSE_CATEGORIES_RESPONSE;
  payload: {
    categories: IWarehouseCategory[];
  };
}

export interface LoadWarehouseCategoriesCallback {
  (arg0: LoadWarehouseCategoriesResponse): void;
}

export interface LoadWarehouseCategory extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_WAREHOUSE_CATEGORY;
  payload: {
    warehouseCategory: {
      uuid: string;
    };
  };
}

export interface LoadWarehouseCategoryResponse extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_WAREHOUSE_CATEGORY_RESPONSE;
  payload: {
    category: IWarehouseCategory | null;
  };
}

export interface LoadWarehouseCategoryCallback {
  (arg0: LoadWarehouseCategoryResponse): void;
}

export interface PairWarehouseCategory extends UnknownAction {
  type: DOWNLOADER_ACTION.PAIR_WAREHOUSE_CATEGORY;
  payload: {
    warehouseCategory: {
      uuid: string;
    };
    category: {
      uuid: string;
    };
  };
}

export interface PairWarehouseCategoryResponse extends UnknownAction {
  type: DOWNLOADER_ACTION.PAIR_WAREHOUSE_CATEGORY_RESPONSE;
  payload: {
    category: IWarehouseCategory | null;
  };
}

export interface PairWarehouseCategoryCallback {
  (arg0: PairWarehouseCategoryResponse): void;
}

export interface LoadWarehouseProducts extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCTS;
}

export interface LoadWarehouseProductsResponse extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCTS_RESPONSE;
  payload: {
    products: IAdminWarehouseProductList[];
  };
}

export interface LoadWarehouseProductsCallback {
  (arg0: LoadWarehouseProductsResponse): void;
}

export interface LoadWarehouseProperties extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_WAREHOUSE_PROPERTIES;
  payload: {
    assignedToProperty?: boolean;
  };
}

export interface LoadWarehousePropertiesResponse extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_WAREHOUSE_PROPERTIES_RESPONSE;
  payload: {
    properties: IWarehouseProperty[] | null;
  };
}

export interface LoadWarehousePropertiesCallback {
  (arg0: LoadWarehousePropertiesResponse): void;
}

export interface LoadWarehouseProperty extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_WAREHOUSE_PROPERTY;
  payload: {
    warehouseProperty: {
      rawName: string;
    };
  };
}

export interface LoadWarehousePropertyResponse extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_WAREHOUSE_PROPERTY_RESPONSE;
  payload: {
    property: IWarehouseProperty | null;
  };
}

export interface LoadWarehousePropertyCallback {
  (arg0: LoadWarehousePropertyResponse): void;
}

export interface LoadWarehouseProduct extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCT;
  payload: {
    warehouseProduct: {
      uuid: string;
    };
  };
}

export interface LoadWarehouseProductResponse extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCT_RESPONSE;
  payload: {
    product: IAdminWarehouseProductDetail | null;
  };
}

export interface LoadWarehouseProductCallback {
  (arg0: LoadWarehouseProductResponse): void;
}

export interface LoadWarehouseProductsByWarehouseCategory
  extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCTS_BY_WAREHOUSE_CATEGORY;
  payload: {
    warehouseCategory: {
      uuid: string;
    };
  };
}

export interface LoadWarehouseProductsByWarehouseCategoryResponse
  extends UnknownAction {
  type: DOWNLOADER_ACTION.LOAD_WAREHOUSE_PRODUCTS_BY_WAREHOUSE_CATEGORY_RESPONSE;
  payload: {
    products: IAdminWarehouseProductList[] | null;
  };
}

export interface LoadWarehouseProductsByWarehouseCategoryCallback {
  (arg0: LoadWarehouseProductsByWarehouseCategoryResponse): void;
}

export type TDownloaderWarehouseProduct =
  | IAdminWarehouseProductList
  | IAdminWarehouseProductDetail
  | null;

export interface AddWarehouseProduct extends UnknownAction {
  type: DOWNLOADER_ACTION.ADD_WAREHOUSE_PRODUCT;
  payload: {
    warehouseProduct: {
      uuid: string;
    };
    name: string;
  };
}

export interface AddWarehouseProductResponse extends UnknownAction {
  type: DOWNLOADER_ACTION.ADD_WAREHOUSE_PRODUCT_RESPONSE;
  payload: {
    product: IProduct | null;
  };
}

export interface AddWarehouseProductCallback {
  (arg0: AddWarehouseProductResponse): void;
}

export interface SyncWarehouseProduct extends UnknownAction {
  type: DOWNLOADER_ACTION.SYNC_WAREHOUSE_PRODUCT;
  payload: {
    product: {
      uuid?: string;
    };
    warehouseProduct: {
      uuid: string;
    };
  };
}

export interface SyncWarehouseProductResponse extends UnknownAction {
  type: DOWNLOADER_ACTION.SYNC_WAREHOUSE_PRODUCT_RESPONSE;
  payload: {
    synced: boolean;
  };
}

export interface SyncWarehouseProductCallback {
  (arg0: SyncWarehouseProductResponse): void;
}

export interface DownloadAllPhotosWarehouseProduct extends UnknownAction {
  type: DOWNLOADER_ACTION.DOWNLOAD_ALL_PHOTOS_WAREHOUSE_PRODUCT;
  payload: {
    warehouseProduct: {
      uuid: string;
    };
  };
}

export interface DownloadAllPhotosWarehouseProductResponse
  extends UnknownAction {
  type: DOWNLOADER_ACTION.DOWNLOAD_ALL_PHOTOS_WAREHOUSE_PRODUCT_RESPONSE;
  payload: {
    downloaded: boolean;
  };
}

export interface DownloadAllPhotosWarehouseProductCallback {
  (arg0: DownloadAllPhotosWarehouseProductResponse): void;
}

export interface DownloaderWarehouseProductPairWithExistingProduct
  extends UnknownAction {
  type: DOWNLOADER_ACTION.WAREHOUSE_PRODUCT_PAIR_WITH_EXISTING_PRODUCT;
  payload: {
    product: {
      uuid: string;
    };
    warehouseProduct: {
      uuid: string;
    };
  };
}

export interface DownloaderWarehouseProductPairWithExistingProductResponse
  extends UnknownAction {
  type: DOWNLOADER_ACTION.WAREHOUSE_PRODUCT_PAIR_WITH_EXISTING_PRODUCT_RESPONSE;
  payload: {
    paired: boolean;
  };
}

export interface DownloaderWarehouseProductPairWithExistingProductCallback {
  (arg0: DownloaderWarehouseProductPairWithExistingProductResponse): void;
}

export interface IDownloaderState {
  inProcess: boolean;
  products?: IProductDownloadedList[];
  product?: IProductDownloaded | null;
  property?: IWarehouseProperty | null;
  warehouse: {
    categories?: IWarehouseCategory[];
    category?: IWarehouseCategory | null;
    products?: IAdminWarehouseProductList[] | null;
    product?: TDownloaderWarehouseProduct;
    properties?: IWarehouseProperty[] | null;
  };
  newProduct?: IProduct | null;
  updatedAt?: number;
}
