import {
  EPropertyType,
  type IProperty,
  type ITranslation,
} from '@mahawi/eshop-common';
import { createAction } from '@reduxjs/toolkit';

import { PROPERTY_ACTION } from './types';

export const propertyLoad = createAction(
  PROPERTY_ACTION.LOAD,
  (code: string) => ({
    payload: { property: { code } },
  }),
);

export const propertyLoadResponse = createAction(
  PROPERTY_ACTION.LOAD_RESPONSE,
  (property: IProperty | null) => ({
    payload: { property },
  }),
);

export const nameUpdate = createAction(
  PROPERTY_ACTION.UPDATE_NAME,
  (propertyCode: string, name: string, languageTypeCode: string) => ({
    payload: {
      property: { code: propertyCode, name },
      languageType: { code: languageTypeCode },
    },
  }),
);

export const nameUpdateResponse = createAction(
  PROPERTY_ACTION.UPDATE_NAME_RESPONSE,
  (propertyCode: string, name: string) => ({
    payload: { property: { code: propertyCode, name } },
  }),
);

export const namesUpdate = createAction(
  PROPERTY_ACTION.UPDATE_NAMES,
  (propertyCode: string, names: ITranslation[]) => ({
    payload: { property: { code: propertyCode }, names },
  }),
);

export const namesUpdateResponse = createAction(
  PROPERTY_ACTION.UPDATE_NAMES_RESPONSE,
  (updated: boolean) => ({
    payload: { updated },
  }),
);

export const nameValueUpdate = createAction(
  PROPERTY_ACTION.UPDATE_VALUE_NAME,
  (propertyValueCode: string, name: string, languageTypeCode: string) => ({
    payload: {
      propertyValue: { code: propertyValueCode, name },
      languageType: { code: languageTypeCode },
    },
  }),
);

export const nameValueUpdateResponse = createAction(
  PROPERTY_ACTION.UPDATE_VALUE_NAME_RESPONSE,
  (saved: boolean) => ({
    payload: { saved },
  }),
);

export const nameLive = createAction(
  PROPERTY_ACTION.NAME_LIVE,
  (propertyCode: string, name: string, languageTypeCode: string) => ({
    payload: {
      property: { code: propertyCode, name },
      languageType: { code: languageTypeCode },
    },
  }),
);

export const propertyValueNameLive = createAction(
  PROPERTY_ACTION.PROPERTY_VALUE_NAME_LIVE,
  (propertyValueCode: string, name: string, languageTypeCode: string) => ({
    payload: {
      propertyValue: { code: propertyValueCode, name },
      languageType: { code: languageTypeCode },
    },
  }),
);

export const updateUnit = createAction(
  PROPERTY_ACTION.UPDATE_UNIT,
  (propertyCode: string, unit: string) => ({
    payload: { property: { code: propertyCode, unit } },
  }),
);

export const updateValueNames = createAction(
  PROPERTY_ACTION.UPDATE_VALUE_NAMES,
  (propertyValueCode: string, names: ITranslation[]) => ({
    payload: { propertyValue: { code: propertyValueCode }, names },
  }),
);

export const updateValueNamesResponse = createAction(
  PROPERTY_ACTION.UPDATE_VALUE_NAMES_RESPONSE,
  (updated: boolean) => ({
    payload: { updated },
  }),
);

export const updateType = createAction(
  PROPERTY_ACTION.UPDATE_TYPE,
  (propertyCode: string, type: EPropertyType) => ({
    payload: { property: { code: propertyCode, type } },
  }),
);

export const propertySaveWarehouseProperties = createAction(
  PROPERTY_ACTION.SAVE_WAREHOUSE_PROPERTIES,
  (propertyCode: string, warehousePropertiesRawNames: string[]) => ({
    payload: {
      property: { code: propertyCode },
      warehouse: { properties: warehousePropertiesRawNames },
    },
  }),
);

export const saveCategoriesUUIDs = createAction(
  PROPERTY_ACTION.SAVE_CATEGORIES_UUIDS,
  (propertyCode: string, categoriesUUIDs: string[]) => ({
    payload: { property: { code: propertyCode }, categoriesUUIDs },
  }),
);

export const saveCategoriesUUIDsResponse = createAction(
  PROPERTY_ACTION.SAVE_CATEGORIES_UUIDS_RESPONSE,
  (property: IProperty | null) => ({
    payload: { property },
  }),
);
