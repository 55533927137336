import { type IBrand } from '@mahawi/eshop-common';
import { createAction } from '@reduxjs/toolkit';

import { BRAND_ACTION } from './types';

export const brandAdd = createAction(BRAND_ACTION.ADD, (name: string) => ({
  payload: { name },
}));

export const brandsLoadAll = createAction(BRAND_ACTION.LOAD_ALL);

export const brandsLoadAllResponse = createAction(
  BRAND_ACTION.LOAD_ALL_RESPONSE,
  (brands: IBrand[]) => ({
    payload: { brands },
  }),
);
