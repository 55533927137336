import { type IBrand } from '@mahawi/eshop-common';
import { createReducer } from '@reduxjs/toolkit';

import { brandAdd, brandsLoadAll, brandsLoadAllResponse } from './actions';
import { type IBrandState } from './types';

export const preloadedState: IBrandState = {
  inProcess: false,
  brands: undefined,
  updatedAt: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(brandAdd, (state) => {
    state.inProcess = true;
  });

  builder.addCase(brandsLoadAll, (state) => {
    state.inProcess = true;
  });

  builder.addCase(brandsLoadAllResponse, (state, action) => {
    state.brands = action.payload.brands.sort((a: IBrand, b: IBrand): number =>
      a.name.localeCompare(b.name),
    );
    state.updatedAt = Date.now();
    state.inProcess = false;
  });
});
