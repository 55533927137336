import { type IAdminCategory } from '@mahawi/eshop-common';
import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import {
  Button,
  Col,
  Divider,
  Form,
  List,
  Row,
  Select,
  Space,
  Spin,
} from 'antd';
import { type DefaultOptionType } from 'antd/es/select';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { type RootState } from '../../reducers';
import { type ICategoriesState } from '../../reducers/categories/types';
import { type ILanguageState } from '../../reducers/language/types';
import { saveCategoriesUUIDs } from '../../reducers/property/actions';
import { type IPropertyState } from '../../reducers/property/types';
import BubbleComponent from '../bubble';
import Link from '../link';

const { Option } = Select;

interface IForm {
  categoryUUIDs: string[];
}

function PropertyCategories({
  Property,
  Categories,
  dispatch,
  Language,
}: {
  Property: IPropertyState;
  Categories: ICategoriesState;
  dispatch: Dispatch;
  Language: ILanguageState;
}): JSX.Element {
  const [form] = Form.useForm<IForm>();

  const [assignedCategoriesNames, setassignedCategoriesNames] = useState<
    Map<string, string>
  >(new Map<string, string>());
  const [categoriesOptions, setCategoriesOptions] = useState<JSX.Element[]>([]);

  const onFinish = useCallback(
    ({ categoryUUIDs }: IForm): void => {
      if (Property.property?.code) {
        dispatch(saveCategoriesUUIDs(Property.property.code, categoryUUIDs));
      }
    },
    [Property.property?.code, dispatch],
  );

  useEffect((): void => {
    const assignedCategoriesNamesUE = new Map<string, string>();

    Property.property?.categoriesUUIDs.forEach((uuid: string): void => {
      const category: IAdminCategory | undefined = Categories.categories?.find(
        (c: IAdminCategory): boolean => c.uuid === uuid,
      );

      const categoryName: string = getTranslation(
        category?.names,
        Language.languageType,
      );

      assignedCategoriesNamesUE.set(uuid, categoryName || uuid);
    });

    setassignedCategoriesNames(assignedCategoriesNamesUE);
  }, [
    Categories.categories,
    Property.property?.categoriesUUIDs,
    Language.languageType?.code,
  ]);

  useEffect((): void => {
    const categoriesOptionsUE: JSX.Element[] | undefined =
      Categories.categories?.map((category: IAdminCategory): JSX.Element => {
        const categoryName: string = getTranslation(
          category.names,
          Language.languageType,
        );

        return (
          <Option value={category.uuid} key={category.uuid}>
            {categoryName || category.uuid}
          </Option>
        );
      }) || [];

    setCategoriesOptions(categoriesOptionsUE);
  }, [Categories.categories, Language.languageType?.code]);

  return (
    <BubbleComponent span={24}>
      <Space size={16} align="start" direction="horizontal">
        <strong>Categories</strong>
      </Space>

      <Row gutter={16}>
        <Col span={12}>
          <Form
            form={form}
            onFinish={(values: IForm): void => onFinish(values)}
          >
            <Row>
              <Col span={8}>
                <Space size={16} align="start">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={Property.inProcess}
                  >
                    Submit
                  </Button>
                  <Spin size="small" spinning={Property.inProcess} />
                </Space>
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col span={24}>
                <Form.Item
                  name="categoryUUIDs"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label="Type"
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Select categories"
                    disabled={Property.inProcess}
                    autoClearSearchValue={false}
                    filterOption={(
                      input: string,
                      option: DefaultOptionType | undefined,
                    ): boolean => {
                      const childrenText: string = String(
                        option ? option.children : '',
                      ).toLowerCase();
                      return (
                        childrenText.includes(input.toLowerCase()) ?? false
                      );
                    }}
                    filterSort={(
                      optionA: DefaultOptionType,
                      optionB: DefaultOptionType,
                    ) => {
                      const childrenAText: string = String(
                        optionA ? optionA.children : '',
                      ).toLowerCase();

                      const childrenBText: string = String(
                        optionB ? optionB.children : '',
                      ).toLowerCase();

                      return childrenAText.localeCompare(childrenBText);
                    }}
                  >
                    {categoriesOptions}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col span={12}>
          <List
            size="small"
            header={<strong>Assigned categories</strong>}
            dataSource={[...assignedCategoriesNames]}
            renderItem={([uuid, name]: [string, string]): JSX.Element => (
              <List.Item key={uuid}>
                <Link to={`/category/${uuid}`} openInNewTab label={name} />
              </List.Item>
            )}
          />
        </Col>
      </Row>
    </BubbleComponent>
  );
}

const mapStateToProps = ({ Property, Categories, Language }: RootState) => ({
  Property,
  Categories,
  Language,
});

export default connect(mapStateToProps)(PropertyCategories);
