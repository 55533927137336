import { notification, NotificationArgsProps } from 'antd';
import { NotificationPlacements } from 'antd/es/notification/interface';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';

import {
  NOTIFICATION_SEVERITY,
  type ShowNotificationMessage,
} from '../admin-types';
import Socket from './socket';

export type NotificationPlacement = NotificationArgsProps['placement'];

export type OpenNotificationType = (
  placement: NotificationPlacement,
  message: ShowNotificationMessage,
) => void;

const notificationContext = createContext({
  openNotification: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _placement: NotificationPlacement,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _message: ShowNotificationMessage,
  ): void => {},
});

function Notification({
  children,
  socket = undefined,
}: {
  children: JSX.Element;
  socket?: Socket;
}): JSX.Element {
  const [api, contextHolder] = notification.useNotification();

  const openNotification: OpenNotificationType = useCallback(
    (
      placement: NotificationPlacement,
      message: ShowNotificationMessage,
    ): void => {
      let fn = api.info;

      switch (message.severity) {
        case NOTIFICATION_SEVERITY.ERROR:
          fn = api.error;
          break;
        case NOTIFICATION_SEVERITY.WARNING:
          fn = api.warning;
          break;
        case NOTIFICATION_SEVERITY.SUCCESS:
          fn = api.success;
          break;
        default:
          fn = api.info;
          break;
      }

      fn({
        message: message.title,
        description: message.description,
        placement,
      });
    },
    [api.error, api.info, api.success, api.warning],
  );

  useEffect((): (() => void) => {
    if (socket) {
      socket.subscribeEventShowNotification(
        (message: ShowNotificationMessage): void => {
          openNotification(NotificationPlacements[2], message);
        },
      );
    }

    return (): void => {
      if (socket) {
        socket.unsubscribeEventShowNotification();
      }
    };
  }, [socket, openNotification]);

  return (
    <notificationContext.Provider value={{ openNotification }}>
      {contextHolder}
      {children}
    </notificationContext.Provider>
  );
}

export default Notification;

export const withNotification =
  (Component: React.ComponentType) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, react/display-name
  (props: any): JSX.Element => {
    const { openNotification } = useContext(notificationContext);
    return <Component {...props} openNotification={openNotification} />;
  };
