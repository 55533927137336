import { type ILanguageType } from '@mahawi/eshop-common/dist/src/types';
import { createReducer } from '@reduxjs/toolkit';

import { setLanguage } from './actions';
import { type ILanguageState, LANGUAGE_ACTION } from './types';

export const preloadedState: ILanguageState = {
  languages: undefined,
  languageType: undefined,
};

const move = (
  type: string,
  languages: ILanguageType[],
  languageCode?: string,
): ILanguageType => {
  const index: number = languages.findIndex(
    (language: ILanguageType): boolean => language.code === languageCode,
  );
  const size: number = languages.length;

  let nextIndex: number = 0;

  if (type === 'next') {
    nextIndex = index + 1;

    if (nextIndex === size) {
      nextIndex = 0;
    }
  }

  if (type === 'previous') {
    nextIndex = index - 1;

    if (nextIndex < 0) {
      nextIndex = size - 1;
    }
  }

  return languages[nextIndex];
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(setLanguage, (state, action) => {
    state.languageType = action.payload.languageType;
  });

  builder.addCase(LANGUAGE_ACTION.NEXT, (state) => {
    if (state.languages) {
      state.languageType = move(
        'next',
        state.languages,
        state.languageType?.code,
      );
    }
  });

  builder.addCase(LANGUAGE_ACTION.PREVIOUS, (state) => {
    if (state.languages) {
      state.languageType = move(
        'previous',
        state.languages,
        state.languageType?.code,
      );
    }
  });
});
