import {
  type IProduct,
  type IProductNames,
} from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum PRODUCTS_ACTION {
  ADD = 'PRODUCTS_ADD',
  ADD_RESPONSE = 'PRODUCTS_ADD_RESPONSE',

  LOAD_ALL = 'PRODUCTS_LOAD_ALL',
  LOAD_ALL_RESPONSE = 'PRODUCTS_LOAD_ALL_RESPONSE',

  LOAD_ALL_SHORT = 'PRODUCTS_LOAD_ALL_SHORT',
  LOAD_ALL_SHORT_RESPONSE = 'PRODUCTS_LOAD_ALL_SHORT_RESPONSE',

  LOAD_BY_CATEGORY = 'PRODUCTS_LOAD_BY_CATEGORY',
  LOAD_BY_CATEGORY_RESPONSE = 'PRODUCTS_LOAD_BY_CATEGORY_RESPONSE',

  GET_BY_MPN = 'PRODUCTS_GET_BY_MPN',
  GET_BY_MPN_RESPONSE = 'PRODUCTS_GET_BY_MPN_RESPONSE',

  GET_BY_GTIN = 'PRODUCTS_GET_BY_GTIN',
  GET_BY_GTIN_RESPONSE = 'PRODUCTS_GET_BY_GTIN_RESPONSE',
}

export interface IAdd extends UnknownAction {
  type: PRODUCTS_ACTION.ADD;
  payload: {
    name: string;
    productDownloaded: {
      uuid: string | undefined;
    };
    languageType: {
      code: string;
    };
  };
}

export interface IAddResponse extends UnknownAction {
  type: PRODUCTS_ACTION.ADD_RESPONSE;
  payload: {
    product: IProduct | null;
  };
}

export interface AddCallback {
  (arg0: IAddResponse): void;
}

export interface ILoadAll extends UnknownAction {
  type: PRODUCTS_ACTION.LOAD_ALL;
  payload: {
    isActiveProducts: boolean;
  };
}

export interface ILoadAllResponse extends UnknownAction {
  type: PRODUCTS_ACTION.LOAD_ALL_RESPONSE;
  payload: {
    products: IProduct[];
  };
}

export interface ILoadAllCallback {
  (arg0: ILoadAllResponse): void;
}

export interface ILoadByCategory extends UnknownAction {
  type: PRODUCTS_ACTION.LOAD_BY_CATEGORY;
  payload: {
    category: {
      uuid: string;
    };
  };
}

export interface ILoadByCategoryResponse extends UnknownAction {
  type: PRODUCTS_ACTION.LOAD_BY_CATEGORY_RESPONSE;
  payload: {
    products: IProduct[];
  };
}

export interface ILoadByCategoryCallback {
  (arg0: ILoadByCategoryResponse): void;
}

export interface ILoadAllShort extends UnknownAction {
  type: PRODUCTS_ACTION.LOAD_ALL_SHORT;
}

export interface ILoadAllShortResponse extends UnknownAction {
  type: PRODUCTS_ACTION.LOAD_ALL_SHORT_RESPONSE;
  payload: {
    products: IProductNames[];
  };
}

export interface ILoadAllShortCallback {
  (arg0: ILoadAllShortResponse): void;
}

export interface IGetByMPN extends UnknownAction {
  type: PRODUCTS_ACTION.GET_BY_MPN;
  payload: {
    mpn: string;
  };
}

export interface IGetByMPNResponse extends UnknownAction {
  type: PRODUCTS_ACTION.GET_BY_MPN_RESPONSE;
  payload: {
    products: IProduct[];
  };
}

export interface IGetByMPNCallback {
  (arg0: IGetByMPNResponse): void;
}

export interface IGetByGTIN extends UnknownAction {
  type: PRODUCTS_ACTION.GET_BY_GTIN;
  payload: {
    gtin: string;
  };
}

export interface IGetByGTINResponse extends UnknownAction {
  type: PRODUCTS_ACTION.GET_BY_GTIN_RESPONSE;
  payload: {
    products: IProduct[];
  };
}

export interface IGetByGTINCallback {
  (arg0: IGetByGTINResponse): void;
}

export interface IProductsState {
  inProcess: boolean;
  products?: IProduct[] | null;
  productsShort?: IProductNames[] | null;
  newProduct?: IProduct | null;
  updatedAt?: number | null;
}
