import { ReloadOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import Link from '../components/link';
import Table from '../components/table';
import { type RootState } from '../reducers';
import { ebayMarketplacesLoad } from '../reducers/ebay/actions';
import { type IEbayState } from '../reducers/ebay/types';

interface DataType {
  key: string;
  name: string;
  isActive: boolean;
  defaultCategoryId?: string;
  languageTypeCode?: string;
  ebayLanguage?: string;
}

const { Text } = Typography;

function EbayMarketplacesContainer({
  dispatch,
  Ebay,
}: {
  dispatch: Dispatch;
  Ebay: IEbayState;
}): JSX.Element {
  const ebayMarketplacesJson = JSON.stringify(Ebay.marketplaces);

  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);

  useEffect(() => {
    dispatch(ebayMarketplacesLoad());
  }, [dispatch]);

  useEffect(() => {
    const dataSourceUE = Ebay.marketplaces
      .map(
        (marketplace): DataType => ({
          key: marketplace.code,
          name: marketplace.name,
          isActive: marketplace.isActive,
          defaultCategoryId: marketplace.defaultCategoryId,
          languageTypeCode: marketplace.languageType.code,
          ebayLanguage: marketplace.ebayLanguage,
        }),
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: {
          compare: (a: DataType, b: DataType) => a.name.localeCompare(b.name),
        },
        filterSearch: true,
        filters: [...new Set(dataSourceUE.map(({ name }) => name))]
          .map((name) => ({
            text: name,
            value: name,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
        onFilter: (value, record: DataType) => record.name === value,
        render: (text: string, record: DataType) => (
          <Link
            to={`/ebay/marketplace/${record.key}`}
            label={text}
            openInNewTab
          />
        ),
      },
      {
        title: 'Is active',
        dataIndex: 'isActive',
        sorter: {
          compare: (a: DataType, b: DataType): number =>
            a.isActive === b.isActive ? 0 : a.isActive ? -1 : 1,
        },
        render: (isActive: boolean) =>
          isActive ? (
            <Text strong type="success">
              Active
            </Text>
          ) : (
            <Text strong type="warning">
              Deactivated
            </Text>
          ),
      },
      {
        title: 'Default category ID',
        dataIndex: 'defaultCategoryId',
        render: (defaultCategoryId: string | null) =>
          defaultCategoryId ? (
            <Text strong>{defaultCategoryId}</Text>
          ) : (
            <Text strong type="danger">
              not set
            </Text>
          ),
      },
      {
        title: 'Language',
        dataIndex: 'ebayLanguage',
        render: (ebayLanguage: string | null) => (
          <Text strong>{ebayLanguage}</Text>
        ),
      },
      {
        title: 'Language type code',
        dataIndex: 'languageTypeCode',
        render: (languageTypeCode: string | undefined) =>
          languageTypeCode ? (
            <Text strong>{languageTypeCode}</Text>
          ) : (
            <Text strong type="danger">
              not set
            </Text>
          ),
      },
    ];

    setDataSource(dataSourceUE);
    setColumns(columnsUE);
  }, [Ebay.marketplaces, ebayMarketplacesJson]);

  return (
    <>
      <Button
        color="primary"
        onClick={() => {
          dispatch(ebayMarketplacesLoad());
        }}
        disabled={Ebay.inProcess}
      >
        <ReloadOutlined />
      </Button>

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Ebay.inProcess}
      />
    </>
  );
}

const mapStateToProps = ({ Ebay }: RootState): { Ebay: IEbayState } => ({
  Ebay,
});

export default connect(mapStateToProps)(EbayMarketplacesContainer);
