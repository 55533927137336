import { ReloadOutlined } from '@ant-design/icons';
import {
  Button,
  Descriptions,
  Form,
  Select,
  Skeleton,
  Space,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { type Dispatch } from 'redux';

import { type RootState } from '../reducers';
import { type ICurrencyState } from '../reducers/currency/types';
import {
  ebayMarketplaceLoad,
  ebayMarketplaceLoadFulfillmentPolicies,
  ebayMarketplaceLoadPaymentPolicies,
  ebayMarketplaceLoadReturnPolicies,
  ebayMarketplaceSetCurrency,
  ebayMarketplaceSetIsActive,
  ebayMarketplaceSetLanguage,
  ebayMarketplacReloadCategories,
  ebayMarketplacReloadDefaultCategoryId,
  ebayMarketplacReloadFulfillmentPolicies,
  ebayMarketplacReloadPaymentPolicies,
  ebayMarketplacReloadReturnPolicies,
} from '../reducers/ebay/actions';
import { type IEbayState } from '../reducers/ebay/types';
import { type ILanguageState } from '../reducers/language/types';

function EbayMarketplaceContainer({
  dispatch,
  Ebay,
  Language,
  Currency,
}: {
  dispatch: Dispatch;
  Ebay: IEbayState;
  Language: ILanguageState;
  Currency: ICurrencyState;
}): JSX.Element {
  const { code } = useParams();

  const { marketplace } = Ebay;

  useEffect(() => {
    if (code) {
      dispatch(ebayMarketplaceLoad(code));
      dispatch(ebayMarketplaceLoadFulfillmentPolicies(code));
      dispatch(ebayMarketplaceLoadPaymentPolicies(code));
      dispatch(ebayMarketplaceLoadReturnPolicies(code));
    }
  }, [dispatch, code]);

  if (!marketplace) {
    return <Skeleton active />;
  }

  const pageHeader: string = `Ebay - ${marketplace.name}`;

  const onChange = (isActive: boolean): void => {
    dispatch(ebayMarketplaceSetIsActive(marketplace.code, isActive));
  };

  const onFinishLanguage = (values: { languageTypeCode: string }) =>
    dispatch(
      ebayMarketplaceSetLanguage(marketplace.code, values.languageTypeCode),
    );

  const onFinishCurrency = (values: { currencyIsoCode: string }) =>
    dispatch(
      ebayMarketplaceSetCurrency(marketplace.code, values.currencyIsoCode),
    );

  let fulfillmentsNames: string = '';
  let paymentsNames: string = '';
  let returnsNames: string = '';

  let fulfillmentsPoliciesLoading: boolean = true;
  let paymentsPoliciesLoading: boolean = true;
  let returnsPoliciesLoading: boolean = true;

  if (Ebay.fulfillmentPolicies[marketplace.code]) {
    fulfillmentsNames =
      Ebay.fulfillmentPolicies[marketplace.code]
        .map(({ name }) => name)
        .join(', ') || '---';

    fulfillmentsPoliciesLoading = false;
  }

  if (Ebay.paymentPolicies[marketplace.code]) {
    paymentsNames =
      Ebay.paymentPolicies[marketplace.code]
        .map(({ name }) => name)
        .join(', ') || '---';

    paymentsPoliciesLoading = false;
  }

  if (Ebay.returnPolicies[marketplace.code]) {
    returnsNames =
      Ebay.returnPolicies[marketplace.code]
        .map(({ name }) => name)
        .join(', ') || '---';

    returnsPoliciesLoading = false;
  }

  return (
    <>
      <Typography.Title level={2}>{pageHeader}</Typography.Title>

      <Space size={16} align="start">
        <Tooltip title="Reload Ebay marketplace">
          <Button
            size="large"
            color="primary"
            onClick={() => {
              dispatch(ebayMarketplaceLoad(marketplace.code));
              dispatch(
                ebayMarketplaceLoadFulfillmentPolicies(marketplace.code),
              );
              dispatch(ebayMarketplaceLoadPaymentPolicies(marketplace.code));
              dispatch(ebayMarketplaceLoadReturnPolicies(marketplace.code));
            }}
            disabled={Ebay.inProcess}
          >
            <ReloadOutlined />
          </Button>
        </Tooltip>
      </Space>

      <Descriptions
        title="Ebay marketplace"
        bordered
        column={{ xs: 1, sm: 1, md: 1 }}
      >
        <Descriptions.Item label="Name" span={1}>
          <strong>{marketplace.name}</strong>
        </Descriptions.Item>

        <Descriptions.Item label="Code" span={1}>
          <strong>{marketplace.code}</strong>
        </Descriptions.Item>

        <Descriptions.Item label="Language" span={1}>
          <strong>{marketplace.ebayLanguage}</strong>
        </Descriptions.Item>

        <Descriptions.Item label="Is active" span={1}>
          <Switch
            disabled={Ebay.inProcess}
            defaultChecked={marketplace.isActive}
            onChange={onChange}
          />
        </Descriptions.Item>

        <Descriptions.Item label="Default category ID" span={1}>
          <Space size={16} align="start">
            <strong>{marketplace.defaultCategoryId || '---'}</strong>
            <Button
              color="primary"
              onClick={() => {
                dispatch(
                  ebayMarketplacReloadDefaultCategoryId(marketplace.code),
                );
              }}
              disabled={Ebay.inProcess}
            >
              <ReloadOutlined />
            </Button>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Categories count" span={1}>
          <Space size={16} align="start">
            <strong>{marketplace.categoriesCount}</strong>
            <Button
              color="primary"
              onClick={() => {
                if (marketplace.defaultCategoryId) {
                  dispatch(
                    ebayMarketplacReloadCategories(
                      marketplace.code,
                      marketplace.defaultCategoryId,
                    ),
                  );
                }
              }}
              disabled={Ebay.inProcess || !marketplace.defaultCategoryId}
            >
              <ReloadOutlined />
            </Button>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Language" span={1}>
          <Form
            onFinish={onFinishLanguage}
            initialValues={{
              languageTypeCode: marketplace.languageType.code,
            }}
          >
            <Space size={16} align="start">
              <Form.Item name="languageTypeCode">
                <Select style={{ minWidth: '10rem' }}>
                  {Language.languages?.map((language) => {
                    const optionText = `${language.name} (${language.code})`;

                    return (
                      <Select.Option key={language.code} value={language.code}>
                        {optionText}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Button
                size="small"
                color="primary"
                type="primary"
                htmlType="submit"
                disabled={Ebay.inProcess}
              >
                Save
              </Button>
            </Space>
          </Form>
        </Descriptions.Item>

        <Descriptions.Item label="Currency" span={1}>
          <Form
            onFinish={onFinishCurrency}
            initialValues={{
              currencyIsoCode: marketplace.currencyType.isoCode,
            }}
          >
            <Space size={16} align="start">
              <Form.Item name="currencyIsoCode">
                <Select style={{ minWidth: '10rem' }}>
                  {Currency.currencies.map((currency) => {
                    const optionText = `${currency.name} (${currency.isoCode})`;

                    return (
                      <Select.Option
                        key={currency.isoCode}
                        value={currency.isoCode}
                      >
                        {optionText}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Button
                size="small"
                color="primary"
                type="primary"
                htmlType="submit"
                disabled={Ebay.inProcess}
              >
                Save
              </Button>
            </Space>
          </Form>
        </Descriptions.Item>

        <Descriptions.Item label="Fulfillment" span={1}>
          <Space size={16} align="start">
            {fulfillmentsPoliciesLoading ? (
              <Spin size="small" />
            ) : (
              <strong>{fulfillmentsNames}</strong>
            )}
            <Button
              color="primary"
              onClick={() => {
                dispatch(
                  ebayMarketplacReloadFulfillmentPolicies(marketplace.code),
                );
              }}
              disabled={Ebay.inProcess}
            >
              <ReloadOutlined />
            </Button>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Payment" span={1}>
          <Space size={16} align="start">
            {paymentsPoliciesLoading ? (
              <Spin size="small" />
            ) : (
              <strong>{paymentsNames}</strong>
            )}
            <Button
              color="primary"
              onClick={() => {
                dispatch(ebayMarketplacReloadPaymentPolicies(marketplace.code));
              }}
              disabled={Ebay.inProcess}
            >
              <ReloadOutlined />
            </Button>
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Return" span={1}>
          <Space size={16} align="start">
            {returnsPoliciesLoading ? (
              <Spin size="small" />
            ) : (
              <strong>{returnsNames}</strong>
            )}
            <Button
              color="primary"
              onClick={() => {
                dispatch(ebayMarketplacReloadReturnPolicies(marketplace.code));
              }}
              disabled={Ebay.inProcess}
            >
              <ReloadOutlined />
            </Button>
          </Space>
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}

const mapStateToProps = ({ Ebay, Language, Currency }: RootState) => ({
  Ebay,
  Language,
  Currency,
});

export default connect(mapStateToProps)(EbayMarketplaceContainer);
