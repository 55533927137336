import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import { type IEbayMarketplace } from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import { Button, Col, Divider, Form, Input, Row, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { ebayCategorySaveAspects } from '../../../reducers/ebay/actions';
import {
  type IEbayState,
  type TEbayAspectsValues,
} from '../../../reducers/ebay/types';
import { type IProductState } from '../../../reducers/product/types';
import Link from '../../link';

const inputNameSeparator = '###';

const onFinishAspects = (
  { code },
  values,
  productUUID: string,
  dispatch: Dispatch,
): void => {
  const vals: TEbayAspectsValues = {};

  Object.keys(values).forEach((key) => {
    const v: string | undefined = values[key];
    const [ebayCategoryId, ebayAspectName] = key.split(inputNameSeparator);

    if (!vals[ebayCategoryId]) {
      vals[ebayCategoryId] = {};
    }

    vals[ebayCategoryId][ebayAspectName] = v;
  });

  dispatch(ebayCategorySaveAspects(productUUID, code, vals));
};

function EbayAspectContainer({
  Ebay,
  Marketplace,
  Product,
  dispatch,
}: {
  Ebay: IEbayState;
  Marketplace: IEbayMarketplace;
  Product: IProductState;
  dispatch: Dispatch;
}): JSX.Element {
  const [form] = Form.useForm();

  const [initialValues, setInitialValues] = useState({});
  const [aspectElements, setAspectElements] = useState<JSX.Element[]>([]);

  const productEbay = Ebay.productEbay[Marketplace.code];
  const ebayAspectsJson = JSON.stringify(Ebay.aspects);

  useEffect(() => {
    if (productEbay) {
      productEbay.aspects.forEach((ebayAspect) => {
        const key = `${ebayAspect.ebay.categoryId}${inputNameSeparator}${ebayAspect.name}`;

        form.setFieldValue(key, ebayAspect.value);
      });
    }
  }, [productEbay, form]);

  useEffect(() => {
    if (!Ebay.aspects[Marketplace.code]) {
      return;
    }

    const aspects = Ebay.aspects[Marketplace.code]
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => Number(b.required) - Number(a.required));

    const aspectElementsUE = aspects
      .map((aspect) => {
        let inputItem: JSX.Element;
        let inputItemOptional: JSX.Element | undefined;

        const inputName = `${aspect.ebay.categoryId}${inputNameSeparator}${aspect.name}`;

        setInitialValues((prev) => {
          return {
            ...prev,
            [inputName]: '',
          };
        });

        if (aspect.ebayAspectItems.length) {
          const options = aspect.ebayAspectItems.map((ebayAspectItem) => ({
            value: ebayAspectItem.name,
            label: ebayAspectItem.name,
          }));

          inputItem = (
            <Form.Item
              label={aspect.name}
              name={inputName}
              rules={[{ required: aspect.required }]}
              required={aspect.required}
            >
              <Select
                options={options}
                showSearch
                filterOption={(input, option) => {
                  const childrenText = String(
                    option ? option.value : '',
                  ).toLowerCase();
                  return childrenText.includes(input.toLowerCase()) ?? false;
                }}
                filterSort={(optionA, optionB) => {
                  const childrenAText = String(
                    optionA ? optionA.value : '',
                  ).toLowerCase();

                  const childrenBText = String(
                    optionB ? optionB.value : '',
                  ).toLowerCase();

                  return childrenAText.localeCompare(childrenBText);
                }}
              />
            </Form.Item>
          );

          if (aspect.body.aspectMode === 'FREE_TEXT') {
            inputItemOptional = (
              <Form.Item label={`${aspect.name} free text`} name={inputName}>
                <Input />
              </Form.Item>
            );
          }
        } else {
          inputItem = (
            <Form.Item
              label={aspect.name}
              name={inputName}
              rules={[{ required: aspect.required }]}
              required={aspect.required}
            >
              <Input />
            </Form.Item>
          );
        }

        if (inputItemOptional) {
          return (
            <Row key={aspect.name}>
              <Col span={12}>{inputItem}</Col>
              <Col span={12}>{inputItemOptional}</Col>
            </Row>
          );
        }

        return (
          <Row key={aspect.name}>
            <Col span={12}>{inputItem}</Col>
          </Row>
        );
      })
      .filter(notEmpty);

    setAspectElements(aspectElementsUE);
  }, [Ebay.aspects, Marketplace.code, ebayAspectsJson]);

  return (
    <Form
      key={Marketplace.code}
      onFinish={(values) =>
        onFinishAspects(Marketplace, values, Product.uuid, dispatch)
      }
      disabled={Ebay.inProcess}
      initialValues={initialValues}
      layout="horizontal"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      form={form}
    >
      <Row key={Marketplace.code}>
        <Col span={24}>
          <Divider orientation="left">
            <Space size={16} align="start">
              {Marketplace.name}
              <Link to={`/ebay/marketplace/${Marketplace.code}`} openInNewTab />
            </Space>
          </Divider>

          {aspectElements.map((aspectElement) => aspectElement)}
        </Col>
      </Row>
      <Row>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Row>
    </Form>
  );
}

const mapStateToProps = ({ Ebay, Product }: RootState) => ({ Ebay, Product });

export default connect(mapStateToProps)(EbayAspectContainer);
