import {
  type ILead,
  type ILeadBasketProducts,
  type ILeadSessions,
  type IMessage,
  type IMessengerLead,
} from '@mahawi/eshop-common';
import { type IEventAdmin } from '@mahawi/eshop-rabbit';
import { createAction } from '@reduxjs/toolkit';

import { MESSENGER_ACTION } from './types';

export const loadLeads = createAction(
  MESSENGER_ACTION.LOAD_LEADS,
  (hours: number) => ({
    payload: {
      hours,
    },
  }),
);

export const loadLeadsResponse = createAction(
  MESSENGER_ACTION.LOAD_LEADS_RESPONSE,
  (leads: IMessengerLead[]) => ({
    payload: {
      leads,
    },
  }),
);

export const setActiveLead = createAction(
  MESSENGER_ACTION.SET_ACTIVE_LEAD,
  (uuid: string) => ({
    payload: {
      lead: {
        uuid,
      },
    },
  }),
);

export const setActiveLeadResponse = createAction(
  MESSENGER_ACTION.SET_ACTIVE_LEAD_RESPONSE,
  (lead: ILead, messages: IMessage[]) => ({
    payload: {
      lead,
      messages,
    },
  }),
);

export const sendMessage = createAction(
  MESSENGER_ACTION.SEND_MESSAGE,
  (uuid: string, message: string) => ({
    payload: {
      message,
      lead: {
        uuid,
      },
    },
  }),
);

export const sendMessageResponse = createAction(
  MESSENGER_ACTION.SEND_MESSAGE_RESPONSE,
  (message: IMessage, saved: boolean) => ({
    payload: {
      message,
      saved,
    },
  }),
);

export const adminConnected = createAction(
  MESSENGER_ACTION.ADMIN_CONNECTED,
  (uuid: string) => ({
    payload: {
      admin: {
        uuid,
      },
    },
  }),
);

export const adminConnectedResponse = createAction(
  MESSENGER_ACTION.ADMIN_CONNECTED_RESPONSE,
  (set: boolean) => ({
    payload: {
      set,
    },
  }),
);

export const adminDisconnected = createAction(
  MESSENGER_ACTION.ADMIN_DISCONNECTED,
  (uuid: string) => ({
    payload: {
      admin: {
        uuid,
      },
    },
  }),
);

export const adminDisconnectedResponse = createAction(
  MESSENGER_ACTION.ADMIN_DISCONNECTED_RESPONSE,
  (set: boolean) => ({
    payload: {
      set,
    },
  }),
);

export const customerSendMessage = createAction(
  MESSENGER_ACTION.CUSTOMER_SEND_MESSAGE,
  (uuid: string, message: IMessage) => ({
    payload: {
      message,
      lead: {
        uuid,
      },
    },
  }),
);

export const leadSessions = createAction(
  MESSENGER_ACTION.LEAD_SESSIONS,
  (sessions: ILeadSessions) => ({
    payload: {
      ...sessions,
    },
  }),
);

export const leadBasketProducts = createAction(
  MESSENGER_ACTION.LEAD_BASKET_PRODUCTS,
  (basketProducts: ILeadBasketProducts) => ({
    payload: {
      ...basketProducts,
    },
  }),
);

export const customerTick = createAction(
  MESSENGER_ACTION.CUSTOMER_TICK,
  (event: IEventAdmin) => ({
    payload: {
      ...event,
    },
  }),
);
