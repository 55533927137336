import { Menu } from 'antd';
import { type MenuItemType } from 'antd/es/menu/interface';
import React from 'react';

import Link from './link';

function SidebarOffer(): JSX.Element {
  const menuItems: MenuItemType[] = [
    {
      key: 'brand',
      label: <Link to="/brand" label="Brand" />,
    },
    {
      key: 'categories',
      label: <Link to="/categories" label="Categories" />,
    },
    {
      key: 'properties',
      label: <Link to="/properties" label="Properties" />,
    },
    {
      key: 'products',
      label: <Link to="/products" label="Products" />,
    },
  ];

  return <Menu items={menuItems} />;
}

export default SidebarOffer;
