import { type ILanguageType } from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum LANGUAGE_ACTION {
  SET = 'LANGUAGE_SET',
  NEXT = 'LANGUAGE_NEXT',
  PREVIOUS = 'LANGUAGE_PREVIOUS',
}

export interface ISet extends UnknownAction {
  type: LANGUAGE_ACTION.SET;
  payload: {
    languageType: ILanguageType;
  };
}

export interface INext extends UnknownAction {
  type: LANGUAGE_ACTION.NEXT;
}

export interface IPrevious extends UnknownAction {
  type: LANGUAGE_ACTION.PREVIOUS;
}

export interface ILanguageState {
  languages?: ILanguageType[];
  languageType?: ILanguageType;
}
