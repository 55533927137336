import { green, red } from '@ant-design/colors';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusCircleFilled,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import { type RootState } from 'admin/react/reducers';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  List,
  Row,
  Select,
  Skeleton,
  Space,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { type ILanguageState } from '../../reducers/language/types';
import {
  productAssignRelatedProducts,
  productsFindInElasticSearch,
} from '../../reducers/product/actions';
import { type IProductState } from '../../reducers/product/types';
import { productsLoadAllShort } from '../../reducers/products/actions';
import { type IProductsState } from '../../reducers/products/types';

interface IFormMutiselect {
  productsUUIDs: string[];
}

interface IFormElasticSearch {
  query: string;
}

const { Panel } = Collapse;
const { Option } = Select;

function Related({
  dispatch,
  Product,
  Products,
  Language,
}: {
  dispatch: Dispatch;
  Product: IProductState;
  Products: IProductsState;
  Language: ILanguageState;
}): JSX.Element {
  const [formMultiselect] = Form.useForm<IFormMutiselect>();
  const [formElasticSearch] = Form.useForm<IFormElasticSearch>();

  const [contentElement, setContentElement] = useState(<Skeleton active />);
  const [statusIcon, setStatusIcon] = useState(<QuestionCircleOutlined />);

  const onFinishMultiselect = useCallback(
    ({ productsUUIDs }) => {
      dispatch(productAssignRelatedProducts(Product.uuid, productsUUIDs));
    },
    [dispatch, Product.uuid],
  );

  const onFinishElasticSearch = useCallback(
    ({ query }) => {
      if (!Language.languageType) {
        return;
      }

      dispatch(productsFindInElasticSearch(Language.languageType.code, query));
    },
    [dispatch, Language.languageType?.code],
  );

  useEffect(() => {
    const productName: string = getTranslation(
      Product.names,
      Language.languageType,
    );

    const queryUE: string = productName || '';

    const actualQuery = formElasticSearch.getFieldValue('query');

    if (!actualQuery) {
      formElasticSearch.setFieldsValue({
        query: queryUE,
      });
    }
  }, [Product.names, Language.languageType?.code, formElasticSearch]);

  useEffect(() => {
    if (!Products.productsShort) {
      setContentElement(<Skeleton active />);
      return;
    }

    const products =
      Products.productsShort.filter(({ uuid }) => uuid !== Product.uuid) || [];

    let relatedProductsFromElasticSearchEl: JSX.Element | undefined;

    if (Product.relatedProductsFromElasticSearch) {
      const actualProductUUIDs = [
        ...[Product.uuid],
        ...Product.relatedUUIDs,
        ...(formMultiselect.getFieldValue('productsUUIDs') || []),
      ];

      const relatedProductsFromElasticFiltered =
        Product.relatedProductsFromElasticSearch?.filter(
          (uuid) => !actualProductUUIDs.includes(uuid),
        ) || [];

      relatedProductsFromElasticSearchEl = (
        <List>
          {relatedProductsFromElasticFiltered.map(
            (productUUID): JSX.Element | undefined => {
              const product = products.find((p) => p.uuid === productUUID);

              if (!product) {
                return undefined;
              }
              const productName: string = getTranslation(
                product.names,
                Language.languageType,
              );

              const linkName = `${productName || '****'}`;

              return (
                <List.Item key={productUUID}>
                  <Space size={16} align="center">
                    <Button
                      type="primary"
                      shape="circle"
                      icon={<PlusCircleFilled />}
                      onClick={() => {
                        const productsUUIDs = new Set<string>(
                          formMultiselect.getFieldValue('productsUUIDs'),
                        );

                        productsUUIDs.add(productUUID);

                        formMultiselect.setFieldsValue({
                          productsUUIDs: [...productsUUIDs],
                        });
                      }}
                      disabled={Product.inProcess}
                    />

                    <a
                      href={`../product/${productUUID}`}
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      {linkName}
                    </a>
                  </Space>
                </List.Item>
              );
            },
          )}
        </List>
      );
    } else {
      relatedProductsFromElasticSearchEl = <Skeleton active />;
    }

    const contentUE = (
      <Row gutter={16}>
        <Col span={12}>
          <Form
            form={formMultiselect}
            onFinish={onFinishMultiselect}
            layout="vertical"
            initialValues={{
              productsUUIDs: Product.relatedUUIDs,
            }}
          >
            <Row gutter={16}>
              <Col span={18}>
                <Form.Item name="productsUUIDs">
                  <Select
                    mode="multiple"
                    allowClear
                    size="large"
                    placeholder="Select related products"
                    disabled={Product.inProcess}
                    autoClearSearchValue={false}
                    filterOption={(input, option) => {
                      const childrenText = String(
                        option ? option.children : '',
                      ).toLowerCase();
                      return (
                        childrenText.includes(input.toLowerCase()) ?? false
                      );
                    }}
                    filterSort={(optionA, optionB) => {
                      const childrenAText = String(
                        optionA ? optionA.children : '',
                      ).toLowerCase();

                      const childrenBText = String(
                        optionB ? optionB.children : '',
                      ).toLowerCase();

                      return childrenAText.localeCompare(childrenBText);
                    }}
                  >
                    {products.map((product) => {
                      const productName: string = getTranslation(
                        product.names,
                        Language.languageType,
                      );

                      const optionName = `${productName || '****'} → ${
                        product.uuid
                      }`;

                      return (
                        <Option value={product.uuid} key={product.uuid}>
                          {optionName}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={Product.inProcess}
                >
                  Add related products
                </Button>
              </Col>
            </Row>
          </Form>

          <List>
            {Product.relatedUUIDs.map(
              (relatedProductUUID: string): JSX.Element | undefined => {
                const product = products.find(
                  (p) => p.uuid === relatedProductUUID,
                );

                if (!product) {
                  return undefined;
                }

                const productName: string = getTranslation(
                  product.names,
                  Language.languageType,
                );

                const linkName = `${productName || '****'}`;

                return (
                  <List.Item key={relatedProductUUID}>
                    <a
                      href={`../product/${relatedProductUUID}`}
                      target="_blank"
                      rel="noreferrer noopener nofollow"
                    >
                      {linkName}
                    </a>
                  </List.Item>
                );
              },
            )}
          </List>
        </Col>

        <Col span={12}>
          <Form form={formElasticSearch} onFinish={onFinishElasticSearch}>
            <Row gutter={16}>
              <Col span={20}>
                <Form.Item
                  name="query"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Start writing to search products by name or description"
                  />
                </Form.Item>
              </Col>

              <Col span={4}>
                <Button
                  size="large"
                  color="primary"
                  htmlType="submit"
                  type="primary"
                  disabled={Product.inProcess}
                >
                  <SearchOutlined />
                </Button>
              </Col>
            </Row>

            {relatedProductsFromElasticSearchEl}
          </Form>
        </Col>
      </Row>
    );

    setContentElement(contentUE);
  }, [
    formElasticSearch,
    formMultiselect,
    Language.languageType?.code,
    onFinishElasticSearch,
    onFinishMultiselect,
    Product.relatedProductsFromElasticSearch,
    Product.relatedUUIDs,
    Product.inProcess,
    Product.uuid,
    Products.productsShort,
  ]);

  useEffect(() => {
    const statusIconUE = Product.relatedUUIDs.length ? (
      <CheckCircleOutlined style={{ color: green.primary }} />
    ) : (
      <CloseCircleOutlined style={{ color: red.primary }} />
    );

    setStatusIcon(statusIconUE);
  }, [Product.relatedUUIDs]);

  return (
    <Collapse
      onChange={(keys) => {
        if (keys.includes('related')) {
          dispatch(productsLoadAllShort());
        }
      }}
    >
      <Panel
        header={
          <Space size={8} align="start" direction="horizontal">
            <strong>Related ({Product.relatedUUIDs.length})</strong>
            {statusIcon}
          </Space>
        }
        key="related"
      >
        {contentElement}
      </Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ Product, Products, Language }: RootState) => ({
  Product,
  Products,
  Language,
});

export default connect(mapStateToProps)(Related);
