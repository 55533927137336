import {
  type ICustomerProductDetail,
  type ILanguageType,
} from '@mahawi/eshop-common';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum SEARCH_ACTION {
  SEARCH_QUERY = 'SEARCH_SEARCH_QUERY',
  SEARCH_QUERY_RESPONSE = 'SEARCH_SEARCH_QUERY_RESPONSE',
}

export interface ISearchQuery extends UnknownAction {
  type: SEARCH_ACTION.SEARCH_QUERY;
  payload: {
    query: string;
    languageType: ILanguageType;
  };
}

export interface ISearchQueryResponse extends UnknownAction {
  type: SEARCH_ACTION.SEARCH_QUERY_RESPONSE;
  payload: {
    query: string;
    products: ICustomerProductDetail[];
  };
}

export interface ISearchQueryCallback {
  (arg0: ISearchQueryResponse): void;
}

export interface ISearchState {
  inProcess: boolean;
  query?: string;
  products?: ICustomerProductDetail[];
  updatedAt?: number;
}
