import { ReloadOutlined } from '@ant-design/icons';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import {
  Button,
  Col,
  Divider,
  Popover,
  Row,
  Space,
  Tag,
  Typography,
} from 'antd';
import { type ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import Link from '../components/link';
import Table from '../components/table';
import { type RootState } from '../reducers';
import { reportWarehouseProductTrendLoad } from '../reducers/report/actions';
import { type IReportState } from '../reducers/report/types';

const { Title } = Typography;

interface CountPrice {
  count: number;
  price: number;
  deltaCount: number;
  deltaPrice: number;
}

interface DataType {
  key: string;
  warehouseProductUuid: string;
  [key: string]: CountPrice | string;
}

const getColorByCount = (count: number | undefined): string => {
  if (!count || count === 0) {
    return 'warning';
  }

  if (count < 0) {
    return 'success';
  }

  return 'error';
};

function ReportWarehouseProductTrendContainer({
  dispatch,
  Report,
}: {
  dispatch: Dispatch;
  Report: IReportState;
}): JSX.Element {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);
  const [dayInterval, setDayInterval] = useState<number>(3);
  const [warehouseProductsCount, setwarehouseProductsCount]: [
    number,
    React.Dispatch<React.SetStateAction<number>>,
  ] = useState<number>(100);
  const [excludeOfferedProducts, setExcludeOfferedProducts]: [
    boolean,
    React.Dispatch<React.SetStateAction<boolean>>,
  ] = useState<boolean>(true);

  useEffect(() => {
    dispatch(
      reportWarehouseProductTrendLoad(
        dayInterval,
        warehouseProductsCount,
        excludeOfferedProducts,
      ),
    );
  }, [dayInterval, warehouseProductsCount, excludeOfferedProducts, dispatch]);

  useEffect(() => {
    if (!Report.warehouseProductTrend) {
      return;
    }

    const { dateFrom, dateTo, report } = Report.warehouseProductTrend;
    const dateColumnsCount = dayjs(dateTo).diff(dayjs(dateFrom), 'day');

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Warehuse product UUID',
        dataIndex: 'warehouseProductUuid',
        render: (_text, record: DataType) => (
          <Link
            to={`/downloader/warehouse-product/${record.warehouseProductUuid}`}
            openInNewTab
            label={record.warehouseProductUuid}
          />
        ),
      },
    ];

    Array.from({ length: dateColumnsCount }, (_, i) =>
      columnsUE.push({
        title: dayjs(dateFrom).add(i, 'day').format('YYYY-MM-DD'),
        dataIndex: dayjs(dateFrom).add(i, 'day').format('YYYY-MM-DD'),
        render: (text: CountPrice | undefined): JSX.Element => {
          const { count, price, deltaCount, deltaPrice } = text || {};

          return (
            <>
              <Row>
                <Col span={12}>
                  <Tag color={getColorByCount(deltaCount)}>
                    <Popover
                      trigger="hover"
                      placement="top"
                      content="Delta stock count by previous day."
                    >
                      {deltaCount || 0}
                    </Popover>
                  </Tag>
                </Col>
                <Col span={12}>
                  <Tag color={getColorByCount(deltaPrice)}>
                    <Popover
                      trigger="hover"
                      placement="top"
                      content="Delta price by previous day."
                    >
                      {deltaPrice || 0}
                    </Popover>
                  </Tag>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Popover
                    trigger="hover"
                    placement="bottom"
                    content="Actual stock count for day."
                  >
                    {count || 0}
                  </Popover>
                </Col>
                <Col span={12}>
                  <Popover
                    trigger="hover"
                    placement="bottom"
                    content="Actual price for day."
                  >
                    {price || 0}
                  </Popover>
                </Col>
              </Row>
            </>
          );
        },
      }),
    );

    setColumns(columnsUE);

    const dataSourceUE: DataType[] = Object.keys(report)
      .map((uuid) => {
        if (!Report.warehouseProductTrend) {
          return null;
        }

        const item = report[uuid];

        const row: DataType = {
          key: uuid,
          warehouseProductUuid: uuid,
        };

        item.forEach((reportItem) => {
          row[reportItem.date] = {
            count: reportItem.count,
            price: reportItem.price,
            deltaCount: reportItem.delta.count,
            deltaPrice: reportItem.delta.price,
          };
        });

        return row;
      })
      .filter(notEmpty);

    setDataSource(dataSourceUE);
  }, [Report.warehouseProductTrend, dayInterval, warehouseProductsCount]);

  return (
    <>
      <Typography>
        <Title level={2}>Report warehouse product trend</Title>
      </Typography>

      <Divider />

      <Space size={16} direction="vertical">
        <Space size={16} align="start">
          {[3, 7, 14, 30].map((days) => (
            <Popover
              trigger="hover"
              key={days}
              title={`Reload warehouse product ${days} before.`}
              placement="top"
            >
              <Button
                type={dayInterval === days ? 'primary' : 'default'}
                onClick={() => {
                  setDayInterval(days);
                }}
                disabled={!Report.warehouseProductTrend}
              >
                {days} days <ReloadOutlined />
              </Button>
            </Popover>
          ))}
        </Space>

        <Space size={16} align="start">
          {[100, 200, 500, 1000, 2000].map((count) => (
            <Popover
              trigger="hover"
              key={count}
              title={`Reload warehouse product ${count} count.`}
              placement="bottom"
            >
              <Button
                type={warehouseProductsCount === count ? 'primary' : 'default'}
                onClick={() => {
                  setwarehouseProductsCount(count);
                }}
                disabled={!Report.warehouseProductTrend}
              >
                {count} warehouse products <ReloadOutlined />
              </Button>
            </Popover>
          ))}
        </Space>

        <Space size={16} align="start">
          {[true, false].map((offeredProducts) => (
            <Button
              key={String(offeredProducts)}
              type={
                excludeOfferedProducts === offeredProducts
                  ? 'primary'
                  : 'default'
              }
              onClick={() => {
                setExcludeOfferedProducts(offeredProducts);
              }}
              disabled={!Report.warehouseProductTrend}
            >
              {offeredProducts ? 'exclude' : 'include'} offered products{' '}
              <ReloadOutlined />
            </Button>
          ))}
        </Space>
      </Space>

      <Divider />

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={!Report.warehouseProductTrend}
      />
    </>
  );
}

const mapStateToProps = ({ Report }: RootState) => ({ Report });

export default connect(mapStateToProps)(ReportWarehouseProductTrendContainer);
