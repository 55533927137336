import { type IAdminWarehouseProductList } from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import { Alert, Space, Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { type IDownloaderState } from '../../reducers/downloader/types';
import Link from '../link';
import Table from '../table';

interface DataType extends IAdminWarehouseProductList {
  key: string;
}

const { Text } = Typography;

function WarehouseProductsTable({
  Downloader,
}: {
  Downloader: IDownloaderState;
}): JSX.Element {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);

  useEffect(() => {
    const dataSourceUE: DataType[] = [];
    const productsName: Set<string> = new Set<string>([]);
    const warehousesName: Set<string> = new Set<string>([]);
    const brandsName: Set<string> = new Set<string>([]);

    Downloader.warehouse.products?.forEach(
      (product: IAdminWarehouseProductList) => {
        warehousesName.add(product.warehouse.name);
        productsName.add(product.name);
        brandsName.add(product.brand);

        dataSourceUE.push({
          key: product.uuid,
          ...product,
        });
      },
    );

    setDataSource(dataSourceUE);

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Warehouse',
        dataIndex: 'warehouse',
        sorter: (a: DataType, b: DataType) =>
          a.warehouse.name.localeCompare(b.warehouse.name),
        filterSearch: true,
        filters: [...warehousesName]
          .map((warehouse) => ({
            text: warehouse,
            value: warehouse,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
        onFilter: (value: string, record: DataType) =>
          record.warehouse.name === value,
        render: (warehouse: IAdminWarehouseProductList) => warehouse.name,
        width: '10%',
      },
      {
        title: 'Brand',
        dataIndex: 'brand',
        sorter: (a: DataType, b: DataType) => a.brand.localeCompare(b.brand),
        filterSearch: true,
        filters: [...brandsName]
          .map((brand) => ({
            text: brand,
            value: brand,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
        onFilter: (value: string, record: DataType) => record.brand === value,
        width: '10%',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: DataType, b: DataType) => a.name.localeCompare(b.name),
        filterSearch: true,
        filters: [...productsName]
          .map((product) => ({
            text: product,
            value: product,
          }))
          .sort((a, b) => a.text.localeCompare(b.text)),
        onFilter: (value: string, record: DataType) => record.name === value,
        render: (name: string, record: DataType) => (
          <Link
            to={`/downloader/warehouse-product/${record.uuid}`}
            label={name}
          />
        ),
        width: '50%',
      },
      {
        title: 'Price',
        dataIndex: 'price',
        sorter: {
          compare: (a: DataType, b: DataType) => a.price - b.price,
          multiple: 100,
        },
        width: '10%',
      },
      {
        title: 'Stock',
        dataIndex: 'count',
        sorter: {
          compare: (a: DataType, b: DataType) => a.count - b.count,
          multiple: 3,
        },
        filterSearch: true,
        filters: [
          {
            text: 'In stock',
            value: 'inStock',
          },
          {
            text: 'Full stock',
            value: 'fullStock',
          },
          {
            text: 'Low stock',
            value: 'lowStock',
          },
          {
            text: 'Out of stock',
            value: 'outOfStock',
          },
        ],
        onFilter: (value: string, record: DataType) => {
          if (value === 'inStock') {
            return record.count > 0;
          }

          if (value === 'fullStock') {
            return record.count >= 5;
          }

          if (value === 'lowStock') {
            return record.count > 0 && record.count < 5;
          }

          if (value === 'outOfStock') {
            return record.count === 0;
          }

          return false;
        },
        render: (value: number) => {
          if (value >= 5) {
            return (
              <Text strong type="success">
                {value}
              </Text>
            );
          }
          if (value >= 2) {
            return (
              <Text strong type="warning">
                {value}
              </Text>
            );
          }

          return (
            <Text strong type="danger">
              {value}
            </Text>
          );
        },
        width: '10%',
      },
      {
        title: 'MPN',
        dataIndex: 'mpn',
        sorter: (a: DataType, b: DataType) => a.mpn.localeCompare(b.mpn),
        filterSearch: true,
        onFilter: (value: string, record: DataType) => record.mpn === value,
        width: '10%',
      },
      {
        title: 'GTIN',
        dataIndex: 'gtin',
        sorter: (a: DataType, b: DataType) => a.gtin.localeCompare(b.gtin),
        filterSearch: true,
        onFilter: (value: string, record: DataType) => record.gtin === value,
        width: '10%',
      },
      {
        title: 'Actions',
        sorter: {
          compare: (a: DataType, b: DataType) =>
            a.product.uuid?.localeCompare(b.product.uuid || '') || 0,
        },
        render: (_text: string, record: DataType) => (
          <Space size={16} align="start">
            {record.product.uuid && (
              <Link to={`/product/${record.product.uuid}`} openInNewTab />
            )}
          </Space>
        ),
        width: '10%',
      },
    ];

    setColumns(columnsUE);
  }, [Downloader.warehouse.products, Downloader.updatedAt]);

  if (Downloader.warehouse.products === null) {
    return (
      <Alert
        message="Error"
        description="Warehouse products not found"
        type="error"
        showIcon
      />
    );
  }

  return (
    <Table
      columns={columns}
      dataSource={dataSource}
      isLoading={Downloader.inProcess}
    />
  );
}

const mapStateToProps = ({
  Downloader,
}: RootState): { Downloader: IDownloaderState } => ({ Downloader });

export default connect(mapStateToProps)(WarehouseProductsTable);
