import { SearchOutlined } from '@ant-design/icons';
import { type ICustomerProductDetail } from '@mahawi/eshop-common';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import { type RootState } from 'admin/react/reducers';
import { Col, Form, Input, Row } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { type Key, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import Link from '../components/link';
import Table from '../components/table';
import { type ILanguageState } from '../reducers/language/types';
import { search } from '../reducers/search/actions';
import { type ISearchState } from '../reducers/search/types';

interface IForm {
  query: string;
}

interface IProduct extends ICustomerProductDetail {
  key: string;
}

function SearchContainer({
  Search,
  dispatch,
  Language,
}: {
  Search: ISearchState;
  dispatch: Dispatch;
  Language: ILanguageState;
}): JSX.Element {
  const [form] = Form.useForm<IForm>();
  const [dataSource, setDataSource] = useState<IProduct[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);

  useEffect((): void => {
    const dataSourceUE =
      Search.products?.map((product: ICustomerProductDetail) => {
        return {
          key: product.uuid,
          ...product,
        };
      }) || [];

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Brand',
        dataIndex: ['brand', 'name'],
        sorter: (a: IProduct, b: IProduct): number => {
          if (a.brand?.name && b.brand?.name) {
            return a.brand.name.localeCompare(b.brand.name);
          }

          return 0;
        },
        filterSearch: true,
        filters: [...new Set(dataSourceUE.map(({ brand }) => brand?.name))]
          .filter(notEmpty)
          .map((name) => ({
            text: name,
            value: name,
          }))
          .sort((a, b) => a.value.localeCompare(b.value)),
        onFilter: (value: boolean | Key, record: IProduct): boolean =>
          record.brand?.name === value,
        width: '10%',
      },
      {
        title: 'Name',
        dataIndex: 'names',
        render: (_text, record: IProduct): JSX.Element => {
          const name: string = getTranslation(
            record.names,
            Language.languageType,
          );

          return (
            <Link to={`/product/${record.uuid}`} label={name} openInNewTab />
          );
        },
        width: '35%',
      },
      {
        title: 'MPN',
        dataIndex: 'mpn',
        sorter: (a: IProduct, b: IProduct) =>
          `${a.mpn}`.localeCompare(`${b.mpn}`),
        filterSearch: true,
        filters: [...new Set(dataSourceUE.map(({ mpn }) => mpn))]
          .filter((mpn): mpn is string => !!mpn)
          .map((name) => ({
            text: name,
            value: name,
          }))
          .sort((a, b) => a.value.localeCompare(b.value)),
        onFilter: (value, record: IProduct) => record.mpn === value,
        width: '10%',
      },
      {
        title: 'GTIN',
        dataIndex: 'gtin',
        sorter: (a: IProduct, b: IProduct) =>
          (a.gtin || '').localeCompare(b.gtin || ''),
        filterSearch: true,
        filters: [...new Set(dataSourceUE.map(({ gtin }) => gtin))]
          .filter(notEmpty)
          .map((name) => ({
            text: name,
            value: name,
          }))
          .sort((a, b) => a.value.localeCompare(b.value)),
        onFilter: (value, record: IProduct) => record.gtin === value,
        width: '10%',
      },
    ];

    setDataSource(dataSourceUE);
    setColumns(columnsUE);
  }, [Search.products, Search.updatedAt, Language.languageType?.code]);

  return (
    <Form
      form={form}
      onFinish={(values) => {
        if (values.query && Language.languageType) {
          dispatch(search(values.query, Language.languageType));
        }
      }}
    >
      <Row gutter={[0, 24]}>
        <Col span={8} offset={8} className="gutter-row">
          <Form.Item
            name="query"
            rules={[
              {
                required: true,
              },
              {
                type: 'string',
                min: 3,
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Search query - product (uuid, gtin, name, description, etc.)"
              prefix={<SearchOutlined />}
              type="string"
              disabled={Search.inProcess}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={[0, 24]}>
        <Col span={24} className="gutter-row">
          <Table
            columns={columns}
            dataSource={dataSource}
            isLoading={Search.inProcess && !Search.products}
          />
        </Col>
      </Row>
    </Form>
  );
}

const mapStateToProps = (
  state: RootState,
): {
  Search: ISearchState;
  Language: ILanguageState;
} => ({
  Search: state.Search,
  Language: state.Language,
});

export default connect(mapStateToProps)(SearchContainer);
