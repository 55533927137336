import { EnvironmentOutlined } from '@ant-design/icons';
import { type IAddress } from '@mahawi/eshop-common';
import React from 'react';

export default function GoogleMapsButton({
  address,
}: {
  address: IAddress;
}): JSX.Element {
  return (
    <EnvironmentOutlined
      // twoToneColor="#61B1FF"
      style={{ color: '#61B1FF' }}
      onClick={(): void => {
        window.open(
          `https://www.google.com/maps/search/${address.street}, ${address.city}, ${address.zip}, ${address.countryTypeIsoCode}`,
        );
      }}
    />
  );
}
