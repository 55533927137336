import { type IWarehouseConfiguration } from '@mahawi/eshop-common';
import { type Account, type Transaction } from '@mahawi/eshop-database';

export enum SOCKET_ACTIONS {
  SHOW_NOTIFICATION = 'showNotification',
}

export enum NOTIFICATION_SEVERITY {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export interface ICrypt {
  cipher: string;
  secret: {
    admin: string;
    customer: string;
  };
}

export interface IWarehouses {
  [key: string]: IWarehouseConfiguration;
}

export interface ShowNotificationMessage {
  severity: NOTIFICATION_SEVERITY;
  title: string;
  description: string;
}

export type ShowNotificationCallback = (
  message: ShowNotificationMessage,
) => void;

export type SocketWrappedArguments<MESSAGE, CALLBACK> = {
  account: Account;
  message: MESSAGE;
  cb: CALLBACK;
  transaction: Transaction;
  showNotificationCallback: ShowNotificationCallback;
};

export type SocketWrappedFunction<MESSAGE, CALLBACK> = ({
  account,
  message,
  cb,
  transaction,
  showNotificationCallback,
}: SocketWrappedArguments<MESSAGE, CALLBACK>) => Promise<void>;

export interface SocketListeners {
  [key: string]: SocketWrappedFunction<unknown, unknown>;
}
