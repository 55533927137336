import { type IEbayMarketplace } from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import { type IEbayState } from 'admin/react/reducers/ebay/types';
import { type IProductState } from 'admin/react/reducers/product/types';
import { Button, Col, Divider, Popconfirm, Row, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import {
  ebayInventoryItemDelete,
  ebayInventoryItemLoad,
  ebayInventoryItemPut,
  ebayInventoryItemsClearAllResponses,
} from '../../../reducers/ebay/actions';
import Link from '../../link';

function EBayInventoriesContainer({
  dispatch,
  Product,
  Ebay,
  Marketplaces,
}: {
  dispatch: Dispatch;
  Product: IProductState;
  Ebay: IEbayState;
  Marketplaces: IEbayMarketplace[];
}): JSX.Element {
  const [inventoryResponse, setInventoryResponse] = React.useState({});

  const ebayInventoryItemsJson = JSON.stringify(Ebay.inventoryItems);

  useEffect(() => {
    Marketplaces.forEach((marketplace) => {
      let element = <>---</>;

      if (Ebay.inventoryItems[marketplace.code]) {
        element = (
          <pre>
            <small>
              {JSON.stringify(
                Ebay.inventoryItems[marketplace.code].response,
                null,
                2,
              )}
            </small>
          </pre>
        );
      }

      setInventoryResponse((prevState) => {
        const newState = { ...prevState, [marketplace.code]: element };

        return newState;
      });
    });
  }, [Marketplaces, Ebay.inventoryItems, ebayInventoryItemsJson]);

  return (
    <>
      <Space size={16} align="start">
        <Button
          size="large"
          onClick={() => {
            dispatch(ebayInventoryItemsClearAllResponses());
          }}
        >
          Clear all responses
        </Button>

        <Button
          size="large"
          type="primary"
          onClick={() => {
            Marketplaces.map(
              (marketplace) =>
                Product.mpn &&
                dispatch(ebayInventoryItemLoad(Product.mpn, marketplace.code)),
            );
          }}
          disabled={Ebay.inProcess}
        >
          Load All
        </Button>

        <Button
          size="large"
          danger
          onClick={() => {
            Marketplaces.map((marketplace) =>
              dispatch(ebayInventoryItemPut(Product.uuid, marketplace.code)),
            );
          }}
          disabled={Ebay.inProcess}
        >
          Put All
        </Button>
      </Space>

      {Marketplaces.map((marketplace) => {
        return (
          <Row key={marketplace.code}>
            <Col span={24}>
              <Divider orientation="left">
                <Space size={16} align="start">
                  {marketplace.name}
                  <Link
                    to={`/ebay/marketplace/${marketplace.code}`}
                    openInNewTab
                  />
                </Space>
              </Divider>

              <Space direction="vertical" size={16} align="start">
                <Space size={16} align="start">
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => {
                      if (Product.mpn) {
                        dispatch(
                          ebayInventoryItemLoad(Product.mpn, marketplace.code),
                        );
                      }
                    }}
                    disabled={Ebay.inProcess}
                  >
                    Load
                  </Button>

                  <Button
                    size="large"
                    danger
                    onClick={() => {
                      dispatch(
                        ebayInventoryItemPut(Product.uuid, marketplace.code),
                      );
                    }}
                    disabled={Ebay.inProcess}
                  >
                    Put
                  </Button>

                  <Popconfirm
                    title="Are you sure to delete this item?"
                    description="Product will be deleted from eBay marketplace."
                    onConfirm={() => {
                      if (Product.mpn) {
                        dispatch(
                          ebayInventoryItemDelete(
                            Product.mpn,
                            marketplace.code,
                          ),
                        );
                      }
                    }}
                    okText="Delete"
                    cancelText="Cancel"
                    placement="topLeft"
                  >
                    <Button danger size="large" disabled={Ebay.inProcess}>
                      Delete
                    </Button>
                  </Popconfirm>
                </Space>

                <Typography.Text>
                  {inventoryResponse[marketplace.code]}
                </Typography.Text>
              </Space>
            </Col>
          </Row>
        );
      })}

      <br />
      <Space size={16} align="start">
        <Button
          size="large"
          onClick={() => {
            dispatch(ebayInventoryItemsClearAllResponses());
          }}
        >
          Clear all responses
        </Button>
      </Space>
    </>
  );
}

const mapStateToProps = ({ Product, Ebay }: RootState) => ({ Product, Ebay });

export default connect(mapStateToProps)(EBayInventoriesContainer);
