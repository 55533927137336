import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import { Button, Col, Collapse, Form, List, Row, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { type RootState } from '../../reducers';
import { savePropertyCodes } from '../../reducers/category/actions';
import { type ICategoryState } from '../../reducers/category/types';
import { propertiesLoad } from '../../reducers/properties/actions';
import { type IPropertiesState } from '../../reducers/properties/types';
import Link from '../link';

const { Panel } = Collapse;
const { Option } = Select;

interface IForm {
  propertyCodes: string[];
}

function PropertiesComponent({
  dispatch,
  Category,
  Properties,
}: {
  Properties: IPropertiesState;
  Category: ICategoryState;
  dispatch: Dispatch;
}): JSX.Element {
  const [form] = Form.useForm<IForm>();

  const [propertiesElements, setPropertiesElements] = useState<JSX.Element[]>(
    [],
  );

  const onFinish = useCallback(
    ({ propertyCodes }: IForm): void => {
      if (Category.category?.uuid) {
        dispatch(savePropertyCodes(Category.category.uuid, propertyCodes));
      }
    },
    [Category.category?.uuid, dispatch],
  );

  useEffect((): void => {
    if (Category.category) {
      form.setFieldsValue({
        propertyCodes: Category.category.property.codes,
      });
    }
  }, [form, Category.category]);

  useEffect((): void => {
    const propertiesElementsUE: JSX.Element[] =
      Category.category?.property.codes
        .map((code: string): JSX.Element => {
          return (
            <List.Item key={code}>
              <Link to={`/property/${code}`} label={code} openInNewTab />
            </List.Item>
          );
        })
        .filter(notEmpty) || [];

    setPropertiesElements(propertiesElementsUE);
  }, [Category.category?.property.codes]);

  return (
    <Collapse
      onChange={(keys: string | string[]): void => {
        if (keys.includes('properties')) {
          dispatch(propertiesLoad());
        }
      }}
    >
      <Panel header="Property" key="properties">
        <Form
          form={form}
          onFinish={(values: IForm): void => onFinish(values)}
          layout="vertical"
        >
          <Row>
            <Col span={8}>
              <Form.Item
                name="propertyCodes"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select properties"
                  disabled={Category.inProcess}
                  autoClearSearchValue={false}
                >
                  {Properties.codes.map(
                    (code: string): JSX.Element => (
                      <Option value={code} key={code}>
                        {code}
                      </Option>
                    ),
                  )}
                </Select>
              </Form.Item>
            </Col>

            <Col span={15} offset={1}>
              {propertiesElements.length > 0 && (
                <List>
                  {propertiesElements.map(
                    (propertyElement: JSX.Element): JSX.Element =>
                      propertyElement,
                  )}
                </List>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ Properties, Category }: RootState) => ({
  Properties,
  Category,
});

export default connect(mapStateToProps)(PropertiesComponent);
