import { Menu } from 'antd';
import { type MenuItemType } from 'antd/es/menu/interface';
import React from 'react';

import Link from './link';

function SidebarDownloaderComponent(): JSX.Element {
  const menuItems: MenuItemType[] = [
    {
      key: 'products',
      label: <Link to="/downloader/products" label="Products" />,
    },
    {
      key: 'warehouse-categories',
      label: (
        <Link
          to="/downloader/warehouse-categories"
          label="Warehouse categories"
        />
      ),
    },
    {
      key: 'warehouse-properties',
      label: (
        <Link
          to="/downloader/warehouse-properties"
          label="Warehouse properties"
        />
      ),
    },
    {
      key: 'warehouse-products',
      label: (
        <Link to="/downloader/warehouse-products" label="Warehouse products" />
      ),
    },
  ];

  return <Menu items={menuItems} />;
}

export default SidebarDownloaderComponent;
