import { SearchOutlined } from '@ant-design/icons';
import {
  type ICategoryEbay,
  type IEbayMarketplace,
  type ITranslation,
} from '@mahawi/eshop-common/dist/src/types';
import {
  type IEbayCategoryNode,
  type IEbayCategoryTreeNodeAncestor,
} from '@mahawi/eshop-rabbit';
import { type RootState } from 'admin/react/reducers';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  message,
  Row,
  Select,
  Skeleton,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { type ICategoryState } from '../../reducers/category/types';
import {
  ebayCategoryLoad,
  ebayCategorySave,
  ebayCategorySuggestions,
  ebayMarketplacesLoad,
} from '../../reducers/ebay/actions';
import {
  type CategoryLoad,
  type CategorySave,
  type CategorySuggestions,
  type IEbayState,
  type MarketplacesLoad,
} from '../../reducers/ebay/types';
import Link from '../link';

interface IFormSearch {
  categoryName: string;
  marketplace: IEbayMarketplace;
}

interface IFormAssign {
  ebayCategoryId: string;
}

const { Panel } = Collapse;

function EbayCategories({
  dispatch,
  Ebay,
  Category,
}: {
  dispatch: Dispatch<
    CategoryLoad | CategorySave | CategorySuggestions | MarketplacesLoad
  >;
  Ebay: IEbayState;
  Category: ICategoryState;
}): JSX.Element {
  const [formSearch] = Form.useForm<IFormSearch>();
  const [formAssign] = Form.useForm<IFormAssign>();

  const { category } = Category;
  const [marketplacesElements, setMarketplacesElements] =
    useState<JSX.Element[]>();

  const onFinish = useCallback(
    (marketplace: IEbayMarketplace, { ebayCategoryId }: IFormAssign): void => {
      if (!category) {
        return;
      }

      dispatch(
        ebayCategorySave(category.uuid, marketplace.code, ebayCategoryId),
      );
      message.success('Ebay category saved.');
    },
    [category, dispatch],
  );

  const onFinishSuggestion = useCallback(
    ({ marketplace, categoryName }: IFormSearch): void => {
      if (marketplace.defaultCategoryId) {
        message.success('Ebay category suggestions requested.');
        dispatch(ebayCategorySuggestions(marketplace.code, categoryName));
      } else {
        message.error(
          `Ebay category request failed - Marketplace hasn't set defaultCategoryId.`,
        );
      }
    },
    [dispatch],
  );

  useEffect(() => {
    Ebay.marketplaces.forEach(({ name, isActive }: IEbayMarketplace): void => {
      if (isActive && category) {
        dispatch(ebayCategoryLoad(category.uuid, name));
      }
    });
  }, [category, dispatch, Ebay.marketplaces]);

  useEffect((): void => {
    if (!category) {
      setMarketplacesElements([]);
      return;
    }

    const marketplaces: IEbayMarketplace[] = Ebay.marketplaces
      .filter(({ isActive }: IEbayMarketplace): boolean => isActive)
      .sort((a: IEbayMarketplace, b: IEbayMarketplace): number =>
        a.name.localeCompare(b.name),
      );

    const marketplaceElementsUE: JSX.Element[] = marketplaces.map(
      (marketplace: IEbayMarketplace): JSX.Element => {
        let ebayCategoryId: string | undefined;
        let ebayCategoryName: string | undefined;

        const marketplaceCategories: Record<string, ICategoryEbay[]> =
          Ebay.categories[marketplace.code];

        if (marketplaceCategories) {
          const categories: ICategoryEbay[] =
            marketplaceCategories[category.uuid];

          if (categories && categories.length) {
            const [ebayCategory] = categories;
            ebayCategoryId = ebayCategory.ebayId;
            ebayCategoryName = ebayCategory.name;
          }
        }

        const categoryNameObj: ITranslation | undefined = category.names.find(
          (name: ITranslation): boolean =>
            name.code === marketplace.languageType.code,
        );

        const categoryName: string | undefined = categoryNameObj?.value;

        const suggestions: IEbayCategoryNode[] =
          Ebay.categorySuggestions[marketplace.code];

        let selectChildren: JSX.Element[] = [];

        if (suggestions && Object.keys(suggestions).length > 0) {
          selectChildren = suggestions.map(
            (suggestion: IEbayCategoryNode): JSX.Element => {
              let prefixName: string[] = [];

              if (suggestion.categoryTreeNodeAncestors) {
                suggestion.categoryTreeNodeAncestors.sort(
                  (
                    a: IEbayCategoryTreeNodeAncestor,
                    b: IEbayCategoryTreeNodeAncestor,
                  ): number =>
                    a.categoryTreeNodeLevel - b.categoryTreeNodeLevel,
                );

                prefixName = suggestion.categoryTreeNodeAncestors.map(
                  ({
                    categoryName: cn,
                  }: IEbayCategoryTreeNodeAncestor): string => cn,
                );
              }

              const name = `${prefixName.join(' > ')} > ${
                suggestion.category.categoryName
              }`;

              return (
                <Select.Option
                  key={suggestion.category.categoryId}
                  value={suggestion.category.categoryId}
                >
                  {name}
                </Select.Option>
              );
            },
          );
        }

        if (!suggestions && ebayCategoryId && ebayCategoryName) {
          selectChildren.push(
            <Select.Option key={ebayCategoryId} value={ebayCategoryId} default>
              {ebayCategoryName}
            </Select.Option>,
          );
        }

        return (
          <Row gutter={16} key={marketplace.code}>
            <Col span={24}>
              <Link
                to={`/ebay/marketplace/${marketplace.code}`}
                openInNewTab
                label={marketplace.name}
              />
            </Col>

            <Col span={6}>
              <Form
                form={formSearch}
                disabled={Ebay.inProcess}
                initialValues={{
                  categoryName,
                  marketplace,
                }}
                onFinish={onFinishSuggestion}
              >
                <Form.Item name="marketplace" hidden>
                  <Input type="hidden" />
                </Form.Item>

                <Row gutter={16}>
                  <Col span={20}>
                    <Form.Item
                      name="categoryName"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Category localized name for search on Ebay"
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Button
                      size="large"
                      color="primary"
                      htmlType="submit"
                      disabled={
                        Ebay.inProcess || !marketplace.defaultCategoryId
                      }
                    >
                      <SearchOutlined />
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col span={18}>
              <Form
                form={formAssign}
                key={marketplace.code}
                onFinish={(values: IFormAssign): void =>
                  onFinish(marketplace, values)
                }
                initialValues={{
                  ebayCategoryId,
                }}
                disabled={Ebay.inProcess || !marketplace.defaultCategoryId}
              >
                <Row gutter={16}>
                  <Col span={20}>
                    <Form.Item name="ebayCategoryId">
                      <Select size="large">{selectChildren}</Select>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Button
                      type="primary"
                      size="large"
                      color="primary"
                      htmlType="submit"
                      disabled={
                        Ebay.inProcess || !marketplace.defaultCategoryId
                      }
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        );
      },
    );

    setMarketplacesElements(marketplaceElementsUE);
  }, [
    category,
    dispatch,
    Ebay.categories,
    Ebay.categorySuggestions,
    Ebay.inProcess,
    Ebay.marketplaces,
    formAssign,
    formSearch,
    onFinish,
    onFinishSuggestion,
  ]);

  return (
    <Collapse
      onChange={(keys: string | string[]): void => {
        if (keys.includes('ebay-categories')) {
          dispatch(ebayMarketplacesLoad());
        }
      }}
    >
      <Panel header="Ebay categories" key="ebay-categories">
        {Ebay.marketplaces.length ? marketplacesElements : <Skeleton active />}
      </Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ Ebay, Category }: RootState) => ({ Ebay, Category });

export default connect(mapStateToProps)(EbayCategories);
