import { createReducer } from '@reduxjs/toolkit';

import { loadSessions, loadSessionsResponse } from './actions';
import { type IDashboardState } from './types';

export const preloadedState: IDashboardState = {
  inProcess: false,
  sessions: undefined,
  updatedAt: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(loadSessions, (state) => {
    state.inProcess = true;
  });

  builder.addCase(loadSessionsResponse, (state, action) => {
    state.sessions = action.payload.sessions;
    state.inProcess = false;
    state.updatedAt = Date.now();
  });
});
