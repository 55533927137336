import { type ICountry } from '@mahawi/eshop-common';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum COUNTRIES_ACTION {
  LOAD_ALL = 'COUNTRIES_LOAD_ALL',
  LOAD_ALL_RESPONSE = 'COUNTRIES_LOAD_ALL_RESPONSE',
}

export interface ILoadAll extends UnknownAction {
  type: COUNTRIES_ACTION.LOAD_ALL;
}

export interface ILoadAllResponse extends UnknownAction {
  type: COUNTRIES_ACTION.LOAD_ALL_RESPONSE;
  payload: {
    countries: ICountry[];
  };
}

export interface ILoadAllCallback {
  (arg0: ILoadAllResponse): void;
}

export interface ICountriesState {
  inProcess: boolean;
  countries?: ICountry[];
  updatedAt?: number;
}
