import {
  type IReportProductLanguage,
  type IReportPropertyLanguage,
  type IReportPropertyValueLanguage,
  type IReportWarehouseProductToImport,
  type TReportWarehouseProductTrend,
} from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum REPORT_ACTION {
  LOAD_PRODUCT_LANGUAGES = 'REPORT_ACTION_LOAD_PRODUCT_LANGUAGES',
  LOAD_PRODUCT_LANGUAGES_RESPONSE = 'REPORT_ACTION_LOAD_PRODUCT_LANGUAGES_RESPONSE',
  LOAD_PROPERTY_LANGUAGES = 'REPORT_ACTION_LOAD_PROPERTY_LANGUAGES',
  LOAD_PROPERTY_LANGUAGES_RESPONSE = 'REPORT_ACTION_LOAD_PROPERTY_LANGUAGES_RESPONSE',
  LOAD_PROPERTY_VALUE_LANGUAGES = 'REPORT_ACTION_LOAD_PROPERTY_VALUE_LANGUAGES',
  LOAD_PROPERTY_VALUE_LANGUAGES_RESPONSE = 'REPORT_ACTION_LOAD_PROPERTY_VALUE_LANGUAGES_RESPONSE',
  LOAD_WAREHOUSE_PRODUCT_TO_IMPORT = 'REPORT_ACTION_LOAD_WAREHOUSE_PRODUCT_TO_IMPORT',
  LOAD_WAREHOUSE_PRODUCT_TO_IMPORT_RESPONSE = 'REPORT_ACTION_LOAD_WAREHOUSE_PRODUCT_TO_IMPORT_RESPONSE',
  LOAD_WAREHOUSE_PRODUCT_TREND = 'REPORT_ACTION_LOAD_WAREHOUSE_PRODUCT_TREND',
  LOAD_WAREHOUSE_PRODUCT_TREND_RESPONSE = 'REPORT_ACTION_LOAD_WAREHOUSE_PRODUCT_TREND_RESPONSE',
}

export interface ILoadProductLanguages extends UnknownAction {
  type: REPORT_ACTION.LOAD_PRODUCT_LANGUAGES;
}

export interface ILoadProductLanguagesResponse extends UnknownAction {
  type: REPORT_ACTION.LOAD_PRODUCT_LANGUAGES_RESPONSE;
  payload: {
    report: IReportProductLanguage[];
  };
}

export interface ILoadProductLanguagesCallback {
  (arg0: ILoadProductLanguagesResponse): void;
}

export interface ILoadPropertyLanguages extends UnknownAction {
  type: REPORT_ACTION.LOAD_PROPERTY_LANGUAGES;
}

export interface ILoadPropertyLanguagesResponse extends UnknownAction {
  type: REPORT_ACTION.LOAD_PROPERTY_LANGUAGES_RESPONSE;
  payload: {
    report: IReportPropertyLanguage[];
  };
}

export interface ILoadPropertyLanguagesCallback {
  (arg0: ILoadPropertyLanguagesResponse): void;
}

export interface ILoadPropertyValueLanguages extends UnknownAction {
  type: REPORT_ACTION.LOAD_PROPERTY_VALUE_LANGUAGES;
}

export interface ILoadPropertyValueLanguagesResponse extends UnknownAction {
  type: REPORT_ACTION.LOAD_PROPERTY_VALUE_LANGUAGES_RESPONSE;
  payload: {
    report: IReportPropertyValueLanguage[];
  };
}

export interface ILoadPropertyValueLanguagesCallback {
  (arg0: ILoadPropertyValueLanguagesResponse): void;
}

export interface ILoadWarehouseProductTrend extends UnknownAction {
  type: REPORT_ACTION.LOAD_WAREHOUSE_PRODUCT_TREND;
  payload: {
    dayInterval: number;
    warehouseProductsCount: number;
    excludeOfferedProducts: boolean;
  };
}

export interface ILoadWarehouseProductTrendResponse extends UnknownAction {
  type: REPORT_ACTION.LOAD_WAREHOUSE_PRODUCT_TREND_RESPONSE;
  payload: {
    report: TReportWarehouseProductTrend;
  };
}

export interface ILoadWarehouseProductTrendCallback {
  (arg0: ILoadWarehouseProductTrendResponse): void;
}

export interface ILoadWarehouseProductToImport extends UnknownAction {
  type: REPORT_ACTION.LOAD_WAREHOUSE_PRODUCT_TO_IMPORT;
  payload: {
    weight: {
      minimal: number;
      maximal: number;
    };
    price: {
      minimal: number;
      maximal: number;
    };
  };
}

export interface ILoadWarehouseProductToImportResponse extends UnknownAction {
  type: REPORT_ACTION.LOAD_WAREHOUSE_PRODUCT_TO_IMPORT_RESPONSE;
  payload: {
    report: IReportWarehouseProductToImport[];
  };
}

export interface ILoadWarehouseProductToImportCallback {
  (arg0: ILoadWarehouseProductToImportResponse): void;
}

export interface IReportState {
  inProcess: boolean;
  productLanguages?: IReportProductLanguage[];
  propertyLanguages?: IReportPropertyLanguage[];
  propertyValueLanguages?: IReportPropertyValueLanguage[];
  warehouseProductToImport?: IReportWarehouseProductToImport[];
  warehouseProductTrend?: TReportWarehouseProductTrend;
  updatedAt?: number;
}
