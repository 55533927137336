import {
  type IReviewDescription,
  type IReviewProduct,
} from '@mahawi/eshop-common';
import { Divider, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { type RootState } from '../../reducers';
import { type IOrderState } from '../../reducers/order/types';
import Link from '../link';

function ReviewPreview({ Order }: { Order: IOrderState }): JSX.Element {
  const [description, setDescription] = useState<string>('');
  const [pros, setPros] = useState<string[]>([]);
  const [cons, setCons] = useState<string[]>([]);
  const [prosCount, setProsCount] = useState<number>(0);
  const [consCount, setConsCount] = useState<number>(0);

  const [productDescriptions, setProductDescriptions] = useState<
    Map<string, string>
  >(new Map());
  const [productPros, setProductPros] = useState<Map<string, string[]>>(
    new Map(),
  );
  const [productCons, setProductCons] = useState<Map<string, string[]>>(
    new Map(),
  );
  const [productProsCount, setProductProsCount] = useState<Map<string, number>>(
    new Map(),
  );
  const [productConsCount, setProductConsCount] = useState<Map<string, number>>(
    new Map(),
  );

  useEffect((): void => {
    if (!Order.order?.review) {
      return;
    }

    const descriptionUE: IReviewDescription | undefined =
      Order.order.review.descriptions.find(
        (d: IReviewDescription): boolean =>
          d.languageType.code === Order.order?.review?.languageType.code,
      );

    setDescription(descriptionUE?.description || '');
    setPros(descriptionUE?.pros || []);
    setCons(descriptionUE?.cons || []);
    setProsCount(descriptionUE?.prosCount || 0);
    setConsCount(descriptionUE?.consCount || 0);
  }, [Order.order]);

  useEffect((): void => {
    if (!Order.order?.review) {
      return;
    }

    const productDescriptionsUE = new Map<string, string>();
    const productProsUE = new Map<string, string[]>();
    const productConsUE = new Map<string, string[]>();
    const productProsCountUE = new Map<string, number>();
    const productConsCountUE = new Map<string, number>();

    Order.order.review.products.forEach(
      (reviewProduct: IReviewProduct): void => {
        const descriptionUE: IReviewDescription | undefined =
          reviewProduct.descriptions.find(
            (d: IReviewDescription): boolean =>
              d.languageType.code === Order.order?.review?.languageType.code,
          );
        productDescriptionsUE.set(
          reviewProduct.product.uuid,
          descriptionUE?.description || '',
        );
        productProsUE.set(
          reviewProduct.product.uuid,
          descriptionUE?.pros || [],
        );
        productConsUE.set(
          reviewProduct.product.uuid,
          descriptionUE?.cons || [],
        );
        productProsCountUE.set(
          reviewProduct.product.uuid,
          descriptionUE?.prosCount || 0,
        );
        productConsCountUE.set(
          reviewProduct.product.uuid,
          descriptionUE?.consCount || 0,
        );
      },
    );

    setProductDescriptions(productDescriptionsUE);
    setProductPros(productProsUE);
    setProductCons(productConsUE);
    setProductProsCount(productProsCountUE);
    setProductConsCount(productConsCountUE);
  }, [Order.order?.review, Order.order?.review?.languageType]);

  if (!Order.order?.review) {
    return <span>No review found</span>;
  }

  return (
    <>
      <h3>General</h3>
      <p>{`Name: ${Order.order.review.name}`}</p>
      <p>{`Rating: ${Order.order.review.rating}`}</p>
      <p>{`Language: ${Order.order.review.languageType.name}`}</p>
      <p>{`Description: ${description}`}</p>
      <p>{`Pros: ${pros.join(', ')}`}</p>
      <p>{`Pros count: ${prosCount}`}</p>
      <p>{`Cons: ${cons.join(', ')}`}</p>
      <p>{`Cons count: ${consCount}`}</p>

      <Divider />

      <h3>Products</h3>
      {Order.order.review.products.map(
        (reviewProduct: IReviewProduct): JSX.Element => (
          <>
            <div key={reviewProduct.product.uuid}>
              <h4>
                <Space size={16} align="start">
                  {reviewProduct.product.uuid}

                  <Link
                    to={`/product/${reviewProduct.product.uuid}`}
                    openInNewTab
                  />
                </Space>
              </h4>
              <p>{`Rating: ${reviewProduct.rating}`}</p>
              <p>{`Description: ${productDescriptions.get(reviewProduct.product.uuid)}`}</p>
              <p>{`Pros: ${productPros.get(reviewProduct.product.uuid)?.join(', ')}`}</p>
              <p>{`Pros count: ${productProsCount.get(reviewProduct.product.uuid)}`}</p>
              <p>{`Cons: ${productCons.get(reviewProduct.product.uuid)?.join(', ')}`}</p>
              <p>{`Cons count: ${productConsCount.get(reviewProduct.product.uuid)}`}</p>
            </div>
            <Divider />
          </>
        ),
      )}
    </>
  );
}

const mapStateToProps = ({ Order }: RootState) => ({
  Order,
});

export default connect(mapStateToProps)(ReviewPreview);
