import { createReducer } from '@reduxjs/toolkit';

import {
  downloaderAddWarehouseProduct,
  downloaderAddWarehouseProductResponse,
  downloaderLoadProduct,
  downloaderLoadProductResponse,
  downloaderLoadProducts,
  downloaderLoadProductsRespose,
  downloaderLoadWarehouseCategories,
  downloaderLoadWarehouseCategoriesResponse,
  downloaderLoadWarehouseCategory,
  downloaderLoadWarehouseCategoryResponse,
  downloaderLoadWarehouseProduct,
  downloaderLoadWarehouseProductResponse,
  downloaderLoadWarehouseProducts,
  downloaderLoadWarehouseProductsByWarehouseCategory,
  downloaderLoadWarehouseProductsByWarehouseCategoryResponse,
  downloaderLoadWarehouseProductsResponse,
  downloaderLoadWarehouseProperties,
  downloaderLoadWarehousePropertiesResponse,
  downloaderLoadWarehouseProperty,
  downloaderLoadWarehousePropertyResponse,
  downloaderPairWarehouseCategory,
  downloaderPairWarehouseCategoryResponse,
} from './actions';
import { type IDownloaderState } from './types';

export const preloadedState: IDownloaderState = {
  inProcess: false,
  products: undefined,
  product: undefined,
  property: undefined,
  warehouse: {
    categories: undefined,
    category: undefined,
    products: undefined,
    product: undefined,
    properties: undefined,
  },
  newProduct: undefined,
  updatedAt: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(downloaderLoadProducts, (state) => {
    state.inProcess = true;
    state.products = undefined;
  });

  builder.addCase(downloaderLoadProductsRespose, (state, action) => {
    state.inProcess = false;
    state.products = action.payload.products;
    state.updatedAt = Date.now();
  });

  builder.addCase(downloaderLoadProduct, (state) => {
    state.inProcess = true;
    state.product = undefined;
  });

  builder.addCase(downloaderLoadProductResponse, (state, action) => {
    state.inProcess = false;
    state.product = action.payload.product;
    state.updatedAt = Date.now();
  });

  builder.addCase(downloaderLoadWarehouseCategories, (state) => {
    state.inProcess = true;
    state.warehouse.categories = undefined;
  });

  builder.addCase(
    downloaderLoadWarehouseCategoriesResponse,
    (state, action) => {
      state.inProcess = false;
      state.warehouse.categories = action.payload.categories;
      state.updatedAt = Date.now();
    },
  );

  builder.addCase(downloaderLoadWarehouseCategory, (state) => {
    state.inProcess = true;
    state.warehouse.category = undefined;
  });

  builder.addCase(downloaderLoadWarehouseCategoryResponse, (state, action) => {
    state.inProcess = false;
    state.warehouse.category = action.payload.category;
    state.updatedAt = Date.now();
  });

  builder.addCase(downloaderPairWarehouseCategory, (state) => {
    state.inProcess = true;
    state.warehouse.category = undefined;
  });

  builder.addCase(downloaderPairWarehouseCategoryResponse, (state, action) => {
    state.inProcess = false;
    state.warehouse.category = action.payload.category;
    state.updatedAt = Date.now();
  });

  builder.addCase(downloaderLoadWarehouseProducts, (state) => {
    state.inProcess = true;
    state.warehouse.products = undefined;
  });

  builder.addCase(downloaderLoadWarehouseProductsResponse, (state, action) => {
    state.inProcess = false;
    state.warehouse.products = action.payload.products;
    state.updatedAt = Date.now();
  });

  builder.addCase(downloaderLoadWarehouseProduct, (state) => {
    state.inProcess = true;
    state.warehouse.product = undefined;
  });

  builder.addCase(downloaderLoadWarehouseProductResponse, (state, action) => {
    state.inProcess = false;
    state.warehouse.product = action.payload.product;
    state.updatedAt = Date.now();
  });

  builder.addCase(
    downloaderLoadWarehouseProductsByWarehouseCategory,
    (state) => {
      state.inProcess = true;
      state.warehouse.products = undefined;
    },
  );

  builder.addCase(
    downloaderLoadWarehouseProductsByWarehouseCategoryResponse,
    (state, action) => {
      state.inProcess = false;
      state.warehouse.products = action.payload.products;
      state.updatedAt = Date.now();
    },
  );

  builder.addCase(downloaderAddWarehouseProduct, (state) => {
    state.inProcess = true;
    state.newProduct = undefined;
  });

  builder.addCase(downloaderAddWarehouseProductResponse, (state, action) => {
    state.inProcess = false;
    state.newProduct = action.payload.product;
    state.updatedAt = Date.now();
  });

  builder.addCase(downloaderLoadWarehouseProperties, (state) => {
    state.inProcess = true;
    state.warehouse.properties = undefined;
  });

  builder.addCase(
    downloaderLoadWarehousePropertiesResponse,
    (state, action) => {
      state.inProcess = false;
      state.warehouse.properties = action.payload.properties;
      state.updatedAt = Date.now();
    },
  );

  builder.addCase(downloaderLoadWarehouseProperty, (state) => {
    state.inProcess = true;
    state.property = undefined;
  });

  builder.addCase(downloaderLoadWarehousePropertyResponse, (state, action) => {
    state.inProcess = false;
    state.property = action.payload.property;
    state.updatedAt = Date.now();

    if (action.payload.property?.values && state.property?.values) {
      state.property.values = action.payload.property?.values?.sort(
        (a: string, b: string): number => a.localeCompare(b),
      );
    }
  });
});
