import { Space, Typography } from 'antd';
import React from 'react';

const { Title } = Typography;

function OfferContainer(): JSX.Element {
  return (
    <Space size={16} align="start" direction="vertical">
      <Typography>
        <Title level={2}>Offer</Title>
      </Typography>
    </Space>
  );
}

export default OfferContainer;
