import { geekblue } from '@ant-design/colors';
import { Layout } from 'antd';
import React, { type CSSProperties, type ReactNode } from 'react';

type SiderContentProps = {
  siderChildren?: ReactNode;
  contentChildren: ReactNode;
};

const { 0: geekblue0 } = geekblue;

const height = 'calc(100vh - 8.5rem)';

const styleLayoutContent: CSSProperties = {
  minHeight: height,
  flexDirection: 'row',
};

const styleSider: CSSProperties = {
  minHeight: height,
};

const styleContent: CSSProperties = {
  backgroundColor: geekblue0,
  padding: '.5rem',
  minHeight: height,
};

function SiderContentComponent({
  siderChildren = undefined,
  contentChildren,
}: SiderContentProps): JSX.Element {
  const width: string = siderChildren ? 'calc(100vw - 15rem)' : '100vw';

  const styleLayout: CSSProperties = {
    minHeight: height,
    maxWidth: width,
    width,
    minWidth: width,
  };

  return (
    <Layout style={styleLayoutContent}>
      {siderChildren && (
        <Layout.Sider style={styleSider} width="15rem">
          {siderChildren}
        </Layout.Sider>
      )}
      <Layout style={styleLayout}>
        <Layout.Content style={styleContent}>{contentChildren}</Layout.Content>
      </Layout>
    </Layout>
  );
}

export default SiderContentComponent;
