import { GlobalOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';

export default function ExternalLink({
  to,
  label,
  ariaLabel,
  onClick,
}: {
  to: string;
  label?: string | JSX.Element;
  ariaLabel: string;
  onClick?: () => void;
}): JSX.Element {
  let renderedLabel: JSX.Element = <GlobalOutlined />;

  if (label) {
    renderedLabel = (
      <Space size={4} align="start">
        {label}
        <GlobalOutlined />
      </Space>
    );
  }

  return (
    <a
      href={to}
      target="_blank"
      rel="noreferrer noopener nofollow"
      aria-label={ariaLabel}
      onClick={(): void => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {renderedLabel}
    </a>
  );
}
