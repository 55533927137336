import { EOrderStatus } from '@mahawi/eshop-common/dist/src/types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import OrdersTable from '../components/orders/orders-table';
import { type RootState } from '../reducers';
import { ordersLoadByOrderStatusCodes } from '../reducers/orders/actions';
import { type IOrdersState } from '../reducers/orders/types';

function OrdersDeliveredContainer({
  dispatch,
  Orders,
}: {
  dispatch: Dispatch;
  Orders: IOrdersState;
}): JSX.Element {
  useEffect((): void => {
    dispatch(ordersLoadByOrderStatusCodes([EOrderStatus.DELIVERED]));
  }, [dispatch]);

  return <OrdersTable Orders={Orders} />;
}

const mapStateToProps = ({ Orders }: RootState): { Orders: IOrdersState } => ({
  Orders,
});

export default connect(mapStateToProps)(OrdersDeliveredContainer);
