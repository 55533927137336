import {
  type CategoryAspectsLoad,
  type CategoryAspectsSave,
  type CategoryLoad,
  type CategorySave,
  type CategorySuggestions,
  type InventoryItemDelete,
  type InventoryItemLoad,
  type InventoryItemPut,
  type InventoryItemsClearAllResponses,
  type MarketplaceLoad,
  type MarketplaceLoadFulfillmentPolicies,
  type MarketplaceLoadPaymentPolicies,
  type MarketplaceLoadReturnPolicies,
  type MarketplaceReloadCategories,
  type MarketplaceReloadDefaultCategoryId,
  type MarketplaceReloadFulfillmentPolicies,
  type MarketplaceReloadPaymentPolicies,
  type MarketplaceReloadReturnPolicies,
  type MarketplaceSetCurrency,
  type MarketplaceSetIsActive,
  type MarketplaceSetLanguage,
  type MarketplacesLoad,
  type OfferClearAllResponses,
  type OfferDelete,
  type OfferPost,
  type OfferPublish,
  type OfferPut,
  type OffersLoad,
  type ProductLoad,
  type ProductSave,
  type TEbayAspectsValues,
  type UserCode,
  type UserUrl,
} from './types';
import { EBAY_ACTION } from './types';

export const ebayGetUserUrl = (): UserUrl => {
  return {
    type: EBAY_ACTION.USER_URL,
  };
};

export const ebayUserCode = (code: string): UserCode => {
  return {
    type: EBAY_ACTION.USER_CODE,
    code,
  };
};

export const ebayMarketplacesLoad = (): MarketplacesLoad => {
  return {
    type: EBAY_ACTION.MARKETPLACES_LOAD,
  };
};

export const ebayMarketplaceLoad = (code: string): MarketplaceLoad => {
  return {
    type: EBAY_ACTION.MARKETPLACE_LOAD,
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayMarketplaceSetIsActive = (
  code: string,
  isActive: boolean,
): MarketplaceSetIsActive => {
  return {
    type: EBAY_ACTION.MARKETPLACE_SET_IS_ACTIVE,
    ebayMarketplace: {
      code,
    },
    isActive,
  };
};

export const ebayMarketplacReloadDefaultCategoryId = (
  code: string,
): MarketplaceReloadDefaultCategoryId => {
  return {
    type: EBAY_ACTION.MARKETPLACE_RELOAD_DEFAULT_CATEGORY_ID,
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayMarketplaceLoadFulfillmentPolicies = (
  code: string,
): MarketplaceLoadFulfillmentPolicies => {
  return {
    type: EBAY_ACTION.MARKETPLACE_LOAD_FULFILLMENT_POLICIES,
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayMarketplaceLoadPaymentPolicies = (
  code: string,
): MarketplaceLoadPaymentPolicies => {
  return {
    type: EBAY_ACTION.MARKETPLACE_LOAD_PAYMENT_POLICIES,
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayMarketplaceLoadReturnPolicies = (
  code: string,
): MarketplaceLoadReturnPolicies => {
  return {
    type: EBAY_ACTION.MARKETPLACE_LOAD_RETURN_POLICIES,
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayMarketplacReloadFulfillmentPolicies = (
  code: string,
): MarketplaceReloadFulfillmentPolicies => {
  return {
    type: EBAY_ACTION.MARKETPLACE_RELOAD_FULFILLMENT_POLICIES,
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayMarketplacReloadPaymentPolicies = (
  code: string,
): MarketplaceReloadPaymentPolicies => {
  return {
    type: EBAY_ACTION.MARKETPLACE_RELOAD_PAYMENT_POLICIES,
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayMarketplacReloadReturnPolicies = (
  code: string,
): MarketplaceReloadReturnPolicies => {
  return {
    type: EBAY_ACTION.MARKETPLACE_RELOAD_RETURN_POLICIES,
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayMarketplacReloadCategories = (
  code: string,
  categoryId: string,
): MarketplaceReloadCategories => {
  return {
    type: EBAY_ACTION.MARKETPLACE_RELOAD_CATEGORIES,
    ebayMarketplace: {
      code,
    },
    categoryId,
  };
};

export const ebayMarketplaceSetLanguage = (
  code: string,
  languageCode: string,
): MarketplaceSetLanguage => {
  return {
    type: EBAY_ACTION.MARKETPLACE_SET_LANGUAGE,
    ebayMarketplace: {
      code,
    },
    languageType: {
      code: languageCode,
    },
  };
};

export const ebayMarketplaceSetCurrency = (
  code: string,
  currencyIsoCode: string,
): MarketplaceSetCurrency => {
  return {
    type: EBAY_ACTION.MARKETPLACE_SET_CURRENCY,
    ebayMarketplace: {
      code,
    },
    currencyType: {
      isoCode: currencyIsoCode,
    },
  };
};

export const ebayCategorySuggestions = (
  code: string,
  query: string,
): CategorySuggestions => {
  return {
    type: EBAY_ACTION.CATEGORY_SUGGESTIONS,
    ebayMarketplace: {
      code,
    },
    query,
  };
};

export const ebayCategoryLoad = (
  categoryUUID: string,
  code: string,
): CategoryLoad => {
  return {
    type: EBAY_ACTION.CATEGORY_LOAD,
    category: {
      uuid: categoryUUID,
    },
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayCategorySave = (
  categoryUUID: string,
  code: string,
  categoryId: string,
): CategorySave => {
  return {
    type: EBAY_ACTION.CATEGORY_SAVE,
    category: {
      uuid: categoryUUID,
    },
    ebayMarketplace: {
      code,
    },
    categoryId,
  };
};

export const ebayProductSave = (
  productUUID: string,
  code: string,
  paymentPolicyId: string,
  fulfillmentPolicyId: string,
  returnPolicyId: string,
  inStock: number,
  priceIncreaseRate: number,
): ProductSave => {
  return {
    type: EBAY_ACTION.PRODUCT_SAVE,
    product: {
      uuid: productUUID,
    },
    ebayMarketplace: {
      code,
    },
    paymentPolicy: {
      id: paymentPolicyId,
    },
    fulfillmentPolicy: {
      id: fulfillmentPolicyId,
    },
    returnPolicy: {
      id: returnPolicyId,
    },
    inStock,
    priceIncreaseRate,
  };
};

export const ebayOffersLoad = (
  productUUID: string,
  productMPN: string,
  code: string,
): OffersLoad => {
  return {
    type: EBAY_ACTION.OFFERS_LOAD,
    product: {
      mpn: productMPN,
      uuid: productUUID,
    },
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayOfferDelete = (
  ebayMarketplaceCode: string,
  offerId: string,
): OfferDelete => {
  return {
    type: EBAY_ACTION.OFFER_DELETE,
    ebayMarketplace: {
      code: ebayMarketplaceCode,
    },
    offerId,
  };
};

export const ebayOfferPost = (productUUID: string, code: string): OfferPost => {
  return {
    type: EBAY_ACTION.OFFER_POST,
    product: {
      uuid: productUUID,
    },
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayOfferPublish = (
  productMPN: string,
  code: string,
): OfferPublish => {
  return {
    type: EBAY_ACTION.OFFER_PUBLISH,
    product: {
      mpn: productMPN,
    },
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayOfferPut = (productMPN: string, code: string): OfferPut => {
  return {
    type: EBAY_ACTION.OFFER_PUT,
    product: {
      mpn: productMPN,
    },
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayProductLoad = (
  productUUID: string,
  code: string,
): ProductLoad => {
  return {
    type: EBAY_ACTION.PRODUCT_LOAD,
    product: {
      uuid: productUUID,
    },
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayInventoryItemDelete = (
  productMPN: string,
  code: string,
): InventoryItemDelete => {
  return {
    type: EBAY_ACTION.INVENTORY_ITEM_DELETE,
    product: {
      mpn: productMPN,
    },
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayInventoryItemLoad = (
  productMPN: string,
  code: string,
): InventoryItemLoad => {
  return {
    type: EBAY_ACTION.INVENTORY_ITEM_LOAD,
    product: {
      mpn: productMPN,
    },
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayInventoryItemPut = (
  productUUID: string,
  code: string,
): InventoryItemPut => {
  return {
    type: EBAY_ACTION.INVENTORY_ITEM_PUT,
    product: {
      uuid: productUUID,
    },
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayCaregoryLoadAspects = (
  productUUID: string,
  code: string,
): CategoryAspectsLoad => {
  return {
    type: EBAY_ACTION.CATEGORY_LOAD_ASPECTS,
    product: {
      uuid: productUUID,
    },
    ebayMarketplace: {
      code,
    },
  };
};

export const ebayCategorySaveAspects = (
  productUUID: string,
  code: string,
  values: TEbayAspectsValues,
): CategoryAspectsSave => {
  return {
    type: EBAY_ACTION.CATEGORY_SAVE_ASPECTS,
    product: {
      uuid: productUUID,
    },
    ebayMarketplace: {
      code,
    },
    values,
  };
};

export const ebayOfferClearAllResponses = (): OfferClearAllResponses => {
  return {
    type: EBAY_ACTION.OFFER_CLEAR_ALL_RESPONSES,
  };
};

export const ebayInventoryItemsClearAllResponses =
  (): InventoryItemsClearAllResponses => {
    return {
      type: EBAY_ACTION.INVENTORY_ITEMS_CLEAR_ALL_RESPONSES,
    };
  };
