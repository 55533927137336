import { type IAdminCategory } from '@mahawi/eshop-common/dist/src/types';
import { type UnknownAction } from '@reduxjs/toolkit';

export enum CATEGORY_ACTION {
  LOAD = 'CATEGORY_ACTION_LOAD',
  LOAD_RESPONSE = 'CATEGORY_ACTION_LOAD_RESPONSE',
  NAME_LIVE = 'CATEGORY_ACTION_NAME_LIVE',
  SAVE_POSITION = 'CATEGORY_SAVE_POSITION',
  SAVE_POSITION_RESPONSE = 'CATEGORY_SAVE_POSITION_RESPONSE',
  SAVE_PROPERTY_LIST_CODES = 'CATEGORY_ACTION_SAVE_PROPERTY_LIST_CODES',
  SAVE_PROPERTY_LIST_CODES_RESPONSE = 'CATEGORY_ACTION_SAVE_PROPERTY_LIST_CODES_RESPONSE',
  SAVE_SUB_CATEGORIES = 'CATEGORY_ACTION_SAVE_SUB_CATEGORIES',
  SAVE_SUB_CATEGORIES_RESPONSE = 'CATEGORY_ACTION_SAVE_SUB_CATEGORIES_RESPONSE',
  UPDATE_NAME = 'CATEGORY_ACTION_UPDATE_NAME',
  UPDATE_NAME_RESPONSE = 'CATEGORY_ACTION_UPDATE_NAME_RESPONSE',
}

export interface CategoryLoad extends UnknownAction {
  type: CATEGORY_ACTION.LOAD;
  payload: {
    category: {
      uuid: string;
    };
  };
}

export interface CategoryLoadResponse extends UnknownAction {
  type: CATEGORY_ACTION.LOAD_RESPONSE;
  payload: {
    category: IAdminCategory | undefined;
  };
}

export interface CategoryLoadCallback {
  (arg0: CategoryLoadResponse): void;
}

export interface CategoryNameLive extends UnknownAction {
  type: CATEGORY_ACTION.NAME_LIVE;
  payload: {
    languageType: {
      code: string;
    };
    name: string;
  };
}

export interface ICategorySavePosition extends UnknownAction {
  type: CATEGORY_ACTION.SAVE_POSITION;
  payload: {
    category: {
      uuid: string;
    };
    position: number;
  };
}

export interface ICategorySavePositionResponse extends UnknownAction {
  type: CATEGORY_ACTION.SAVE_POSITION_RESPONSE;
  payload: {
    saved: boolean;
  };
}

export interface ICategorySavePositionCallback {
  (arg0: ICategorySavePositionResponse): void;
}

export interface SavePropertyCodes extends UnknownAction {
  type: CATEGORY_ACTION.SAVE_PROPERTY_LIST_CODES;
  payload: {
    category: {
      uuid: string;
    };
    property: {
      codes: string[];
    };
  };
}

export interface SavePropertyCodesResponse extends UnknownAction {
  type: CATEGORY_ACTION.SAVE_PROPERTY_LIST_CODES_RESPONSE;
  payload: {
    saved: boolean;
  };
}

export interface ICategorySavechildCategories extends UnknownAction {
  type: CATEGORY_ACTION.SAVE_SUB_CATEGORIES;
  payload: {
    category: {
      uuid: string;
    };
    childCategories: {
      uuids: string[];
    };
  };
}

export interface ICategorySavechildCategoriesResponse extends UnknownAction {
  type: CATEGORY_ACTION.SAVE_SUB_CATEGORIES_RESPONSE;
  payload: {
    category: IAdminCategory | undefined;
  };
}

export interface ICategorySavechildCategoriesCallback {
  (arg0: ICategorySavechildCategoriesResponse): void;
}

export interface CategoryNameUpdate extends UnknownAction {
  type: CATEGORY_ACTION.UPDATE_NAME;
  payload: {
    category: {
      uuid: string;
    };
    languageType: {
      code: string;
    };
    name: string;
  };
}

export interface CategoryNameUpdateResponse extends UnknownAction {
  type: CATEGORY_ACTION.UPDATE_NAME_RESPONSE;
  payload: {
    category: IAdminCategory | undefined;
  };
}

export interface CategoryNameUpdateCallback {
  (arg0: CategoryNameUpdateResponse): void;
}

export interface ICategoryState {
  inProcess: boolean;
  category?: IAdminCategory | null;
  updatedAt?: number;
}
