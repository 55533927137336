import { ReloadOutlined } from '@ant-design/icons';
import { type IWarehouseCategory } from '@mahawi/eshop-common/dist/src/types';
import { Button, Divider, Space, Tooltip, Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Link from '../components/link';
import Table from '../components/table';
import { type RootState } from '../reducers';
import { downloaderLoadWarehouseCategories } from '../reducers/downloader/actions';
import { type IDownloaderState } from '../reducers/downloader/types';

interface DataType extends IWarehouseCategory {
  key: string;
}

function DownloaderWarehouseCategories({
  dispatch,
  Downloader,
}: {
  dispatch: Dispatch;
  Downloader: IDownloaderState;
}): JSX.Element {
  const [dataSource, setDataSource] = useState<DataType[]>([]);
  const [columns, setColumns] = useState<ColumnsType<object>>([]);

  useEffect((): void => {
    dispatch(downloaderLoadWarehouseCategories());
  }, [dispatch]);

  useEffect((): void => {
    const dataSourceUE: DataType[] = [];
    const categories = new Set<string>([]);

    Downloader.warehouse.categories?.forEach((category): void => {
      const categoryNameSplitted = category.name.split(' > ');
      categoryNameSplitted.forEach((categoryName): void => {
        categories.add(categoryName);
      });

      dataSourceUE.push({
        key: category.uuid,
        ...category,
      });
    });

    setDataSource(dataSourceUE);

    const columnsUE: ColumnsType<object> = [
      {
        title: 'Name',
        dataIndex: 'name',
        sorter: (a: DataType, b: DataType): number =>
          a.rawName.localeCompare(b.rawName),
        filterSearch: true,
        filters: [...categories]
          .map((category): { text: string; value: string } => ({
            text: category,
            value: category,
          }))
          .sort((a, b): number => a.text.localeCompare(b.text)),
        onFilter: (value: string, record: DataType): boolean =>
          record.name.toLowerCase().includes(value.toLowerCase()),
        render: (_text, record: DataType): JSX.Element => (
          <Link
            to={`/downloader/warehouse-category/${record.uuid}`}
            label={record.name}
          />
        ),
        width: '90%',
      },
      {
        title: 'Actions',
        sorter: (a: DataType, b: DataType): number =>
          (b.category?.uuid || '').localeCompare(a.category?.uuid || '') || 0,
        render: (_text, record: DataType): JSX.Element => (
          <Space size={16} align="start">
            {record.category?.uuid ? (
              <Link to={`/category/${record.category.uuid}`} openInNewTab />
            ) : null}
          </Space>
        ),
        width: '10%',
      },
    ];

    setColumns(columnsUE);
  }, [Downloader.warehouse.categories]);

  const pageHeaderText: string = `Downloader warehouse categories (${Downloader.warehouse.categories?.length || 0} items)`;

  return (
    <>
      <Typography.Title level={2}>{pageHeaderText}</Typography.Title>

      <Divider />

      <Space size={16} align="start">
        <Tooltip title="Reload all products">
          <Button
            color="primary"
            onClick={(): void => {
              dispatch(downloaderLoadWarehouseCategories());
            }}
            disabled={Downloader.inProcess}
          >
            All <ReloadOutlined />
          </Button>
        </Tooltip>
      </Space>

      <Divider />

      <Table
        columns={columns}
        dataSource={dataSource}
        isLoading={Downloader.inProcess}
      />
    </>
  );
}

const mapStateToProps = ({
  Downloader,
}: RootState): { Downloader: IDownloaderState } => ({ Downloader });

export default connect(mapStateToProps)(DownloaderWarehouseCategories);
