import { createReducer } from '@reduxjs/toolkit';

import {
  productAdd,
  productAddResponse,
  productsGetByGTIN,
  productsGetByGTINResponse,
  productsGetByMPN,
  productsGetByMPNResponse,
  productsLoadAll,
  productsLoadAllResponse,
  productsLoadAllShort,
  productsLoadAllShortResponse,
  productsLoadByCategory,
  productsLoadByCategoryResponse,
} from './actions';
import { type IProductsState } from './types';

export const preloadedState: IProductsState = {
  inProcess: false,
  products: undefined,
  productsShort: undefined,
  newProduct: undefined,
  updatedAt: undefined,
};

export default createReducer(preloadedState, (builder) => {
  builder.addCase(productAdd, (state) => {
    state.inProcess = true;
  });

  builder.addCase(productAddResponse, (state, action) => {
    state.inProcess = false;
    state.newProduct = action.payload.product;
    state.updatedAt = Date.now();

    if (Array.isArray(state.products) && action.payload.product) {
      state.products.unshift(action.payload.product);
    }
  });

  builder.addCase(productsGetByGTIN, (state) => {
    state.inProcess = true;
    state.products = undefined;
  });

  builder.addCase(productsGetByGTINResponse, (state, action) => {
    state.inProcess = false;
    state.products = action.payload.products;
    state.updatedAt = Date.now();
  });

  builder.addCase(productsGetByMPN, (state) => {
    state.inProcess = true;
    state.products = undefined;
  });

  builder.addCase(productsGetByMPNResponse, (state, action) => {
    state.inProcess = false;
    state.products = action.payload.products;
    state.updatedAt = Date.now();
  });

  builder.addCase(productsLoadAll, (state) => {
    state.inProcess = true;
    state.products = undefined;
  });

  builder.addCase(productsLoadAllResponse, (state, action) => {
    state.inProcess = false;
    state.products = action.payload.products;
    state.updatedAt = Date.now();
  });

  builder.addCase(productsLoadByCategory, (state) => {
    state.inProcess = true;
    state.products = undefined;
  });

  builder.addCase(productsLoadByCategoryResponse, (state, action) => {
    state.inProcess = false;
    state.products = action.payload.products;
    state.updatedAt = Date.now();
  });

  builder.addCase(productsLoadAllShort, (state) => {
    state.inProcess = true;
    state.productsShort = undefined;
  });

  builder.addCase(productsLoadAllShortResponse, (state, action) => {
    state.inProcess = false;
    state.productsShort = action.payload.products;
    state.updatedAt = Date.now();
  });
});
