import { ReloadOutlined } from '@ant-design/icons';
import { Button, Divider, Space, Tooltip, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import WarehouseProductsTable from '../components/downloader/warehouse-products-table';
import { type RootState } from '../reducers';
import { downloaderLoadWarehouseProducts } from '../reducers/downloader/actions';
import { type IDownloaderState } from '../reducers/downloader/types';

function DownloaderWarehouseProducts({
  dispatch,
  Downloader,
}: {
  dispatch: Dispatch;
  Downloader: IDownloaderState;
}): JSX.Element {
  let pageHeaderText: string = 'Downloader warehouse products';

  if (Downloader.warehouse.products?.length === 0) {
    pageHeaderText = `Downloader warehouse products (${Downloader.warehouse.products.length} items)`;
  }

  return (
    <>
      <Typography.Title level={2}>{pageHeaderText}</Typography.Title>

      <Divider />

      <Space size={16} align="start">
        <Tooltip title="Reload all products">
          <Button
            color="primary"
            onClick={() => {
              dispatch(downloaderLoadWarehouseProducts());
            }}
            disabled={Downloader.inProcess}
          >
            All <ReloadOutlined />
          </Button>
        </Tooltip>
      </Space>

      <Divider />

      <WarehouseProductsTable />
    </>
  );
}

const mapStateToProps = ({
  Downloader,
}: RootState): { Downloader: IDownloaderState } => ({ Downloader });

export default connect(mapStateToProps)(DownloaderWarehouseProducts);
