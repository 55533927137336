import { Typography } from 'antd';
import React from 'react';

export const coloredRenderOfNumberInTable = (value: number): JSX.Element => {
  if (value >= 5) {
    return (
      <Typography.Text strong type="success">
        {value}
      </Typography.Text>
    );
  }
  if (value >= 2) {
    return (
      <Typography.Text strong type="warning">
        {value}
      </Typography.Text>
    );
  }

  return (
    <Typography.Text strong type="danger">
      {value}
    </Typography.Text>
  );
};
